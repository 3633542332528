import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';

const CRM_URL = environment.CRM;

@Injectable({
  providedIn: 'root'
})
export class FormRequestService {

  constructor(private http: HttpClient) { }

  getForm(keyform:string,form){
    return this.http.get<any>(`${CRM_URL}survey-form/${keyform}`,form);
  }

  postForm(form){
    return this.http.post<any>(`${CRM_URL}formanswer/saveinfo`, form);
  }
}
