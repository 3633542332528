<div class="head">
    <h1>Encuestas GroupCOS</h1>
    <h4>Encuesta liderazgo</h4>
    <hr>
    <form (ngSubmit)="onSubmit()" [formGroup]="form">
        <div class="mb-3" *ngFor="let pregunta of  cuestions;let indice = index ">
            <div [ngSwitch]="pregunta.type">
                <div *ngSwitchCase="'selectBoss'">
                    <div class="p-3">
                        <mat-form-field class="w-100 border rounded p-2" appearance="fill">
                            <mat-label>{{indice+1}}. {{pregunta.pregunta}}</mat-label>
                            <input type="text" placeholder="Tu respuesta" name="" matInput
                                [formControlName]="pregunta.control" [matAutocomplete]="auto">
                            <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete"
                                [displayWith]="displayFnb.bind(this)">
                                <mat-option *ngFor="let item of filteredOptionsBoss  | async" [value]="item.id">
                                    {{item.nombre}}
                                </mat-option>
                            </mat-autocomplete>
                        </mat-form-field>
                    </div>

                </div>
                <div *ngSwitchCase="'selectArea'">
                    <div class="p-3">
                        <mat-form-field class="w-100 border rounded p-2" appearance="fill">
                            <mat-label>{{indice+1}}. {{pregunta.pregunta}}</mat-label>
                            <input type="text" placeholder="Tu respuesta" name="" matInput
                                [formControlName]="pregunta.control" [matAutocomplete]="auto">
                            <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete"
                                [displayWith]="displayFnA.bind(this)">
                                <mat-option *ngFor="let item of filteredOptionsArea | async" [value]="item.id">
                                    {{item.nombre}}
                                </mat-option>
                            </mat-autocomplete>
                        </mat-form-field>
                    </div>
                </div>

                <div *ngSwitchCase="'basic'" class="m2-5 border rounded p-2">
                    <div class="p-3">
                        <p>{{indice+1}}. {{pregunta.pregunta}} <span class="text-danger">*</span> </p>
                        <mat-radio-group aria-label="Select an option" [formControlName]="pregunta.control">
                            <mat-radio-button value="Si">Si</mat-radio-button>
                            <mat-radio-button value="No">No</mat-radio-button>
                        </mat-radio-group>
                        <mat-error
                            *ngIf="form.get(pregunta.control).hasError('required') && form.get(pregunta.control).touched">Esta
                            respuesta es obligatoria.</mat-error>
                    </div>

                </div>
                <div *ngSwitchCase="'escala'" class="m2-5 border rounded p-2">
                    <div class="p-3">
                        <p>{{indice+1}}. {{pregunta.pregunta}} <span class="text-danger">*</span> </p>
                        <mat-radio-group class="vertical-radio-group" aria-label="Select an option"
                            [formControlName]="pregunta.control">
                            <mat-radio-button *ngFor="let num of numSequence(pregunta.escala); let i = index"
                                [value]="i +1">{{i +1}}</mat-radio-button>

                        </mat-radio-group>
                        <mat-error
                            *ngIf="form.get(pregunta.control).hasError('required') && form.get(pregunta.control).touched">Esta
                            respuesta es obligatoria.</mat-error>
                    </div>

                </div>
                <div *ngSwitchCase="'checkbox'" class="m2-5 border rounded p-2">
                    <div class="p-3">
                        <p>{{indice+1}}. {{pregunta.pregunta}} <span class="text-danger">*</span> </p>
                        <small>(Puede Selecioanar mas de un opcion)</small>
                        <div *ngFor="let opcion of pregunta.respuestas; let i = index">
                            <mat-checkbox [checked]="multipleCheck.includes(opcion)"
                                (change)="onServicePresed($event.checked, opcion )">{{ opcion }} </mat-checkbox>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="col-12 text-center mb-4 mt-4">

            <button type="submit" class="col-3" mat-raised-button color="primary" [disabled]="validateEncueta">
                Enviar respuesta
            </button>
            <div *ngIf="validateEncueta">
                <span class="text-danger">Ya respondiste esta esta Encuenta</span>
            </div>
        </div>
    </form>

</div>