import { Component, OnInit, ViewChild, AfterViewInit, ElementRef} from '@angular/core';
import { FormBuilder, AbstractControl, ValidatorFn, Validators, FormArray, ValidationErrors } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material/dialog';
import { FormsSteperService } from '../../services/rest/forms-steper.service';
import { HttpParams} from '@angular/common/http';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AuthServicesService } from 'src/app/auth/services/auth.service';
import Swal from 'sweetalert2';
import { MatStepper } from '@angular/material/stepper';
import { NotificationService } from 'src/app/core/services/notification.service';
import { Router } from '@angular/router';
import { KindAccountService } from '../../services/rest/kindAccount.service';



@Component({
  selector: 'app-personal-information',
  templateUrl: './personal-information.component.html',
  styleUrls: ['./personal-information.component.sass']
})
export class PersonalInformationComponent implements OnInit, AfterViewInit {
  @ViewChild('stepper') stepper: MatStepper;
  @ViewChild('basic', {static: false}) basic: ElementRef;
  @ViewChild('personal_d', {static: false}) personalD: ElementRef;




  isEditable = true;
  isLinear = true;
  documentsToUploadToOperation:any = [];
  documentGeneratedByOperation:any = [];
  displayedColumns: string[] = ['actions', 'file', 'name', 'state'];
  attachments: any = [];

  attachmentId;
  constructor(private _fb: FormBuilder, public _SteperService:FormsSteperService, private snack: MatSnackBar, private _auth:AuthServicesService,
    private notificationService : NotificationService, private router:Router, private kindAccountService :KindAccountService) { }
  basicFormGroup= this._fb.group({
    id_type_id: ['', Validators.required],
    id_number: ['', [Validators.required,Validators.minLength(4),Validators.maxLength(20),Validators.pattern(/^-?(0|[1-9]\d*)?$/)]],
    id_number_verify: ['', [Validators.required,Validators.minLength(4),Validators.maxLength(20),Validators.pattern(/^-?(0|[1-9]\d*)?$/)]],
    number_PA: ['', [Validators.minLength(4),Validators.maxLength(20)]],
    number_PA_verify: ['', [Validators.minLength(4),Validators.maxLength(20)]],
    first_name: ['', [Validators.maxLength(100), Validators.required, Validators.pattern(/^[a-zA-Z\s\u00F1\u00D1]*$/),this.trimValidator()]],
    middle_name: ['', [Validators.maxLength(100),Validators.pattern(/^[a-zA-Z\s\u00F1\u00D1]*$/),this.trimValidator()]],
    last_name: ['', [Validators.maxLength(100),Validators.required,Validators.pattern(/^[a-zA-Z\s\u00F1\u00D1]*$/),this.trimValidator()]],
    second_last_name: ['', [Validators.maxLength(100),Validators.pattern(/^[a-zA-Z\s\u00F1\u00D1]*$/),this.trimValidator()]],
    birth_date: [null, [Validators.required, this.validateDate()]],
    civil_state_id: [''],
    email: ['', [Validators.required, Validators.pattern('^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$'), Validators.maxLength(100)]],
    email2: ['', [Validators.pattern('^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$'), Validators.maxLength(100)]],
    mobile_phone: ['', [Validators.min(1), Validators.max(999999999999), Validators.required, Validators.pattern(/^-?(0|[1-9]\d*)?$/),this.trimValidator()]],
    phone: ['', [Validators.min(1), Validators.max(999999999999), Validators.pattern(/^-?(0|[1-9]\d*)?$/),this.trimValidator()]],
  })
  firstFormGroup= this._fb.group({
    age:['', [Validators.required, Validators.pattern(/^-?(0|[1-9]\d*)?$/), Validators.maxLength(2)]],
    city_document_id: ['', [Validators.required, Validators.maxLength(20), Validators.pattern(/^[a-zA-Z\s\u00E0-\u00FC\u00f1]*$/i),this.trimValidator()]],
    expedition_date_id: ['', [Validators.required]],
    nationality: ['', [Validators.required, Validators.maxLength(20),Validators.pattern(/^[a-zA-Z\s\u00E0-\u00FC\u00f1]*$/i),this.trimValidator()]],
    alias: ['', [Validators.required, Validators.maxLength(20),Validators.pattern(/^[a-zA-Z0-9\s\u00E0-\u00FC\u00f1]*$/i),this.trimValidator()]],
    place_of_birth: ['', [Validators.required, Validators.maxLength(20),Validators.pattern(/^[a-zA-Z\s\u00E0-\u00FC\u00f1]*$/i),this.trimValidator()]],
    social_group:[''],
    city_of_residence: ['', [Validators.required, Validators.maxLength(20),Validators.pattern(/^[a-zA-Z\s\u00E0-\u00FC\u00f1]*$/i),this.trimValidator()]],
    deparment_of_residence: ['', [Validators.required, Validators.maxLength(20),Validators.pattern(/^[a-zA-Z\s\u00E0-\u00FC\u00f1]*$/i),this.trimValidator()]],
    adress: ['', Validators.required],
    location:['', [Validators.required,Validators.pattern(/^[a-zA-Z\s\u00E0-\u00FC\u00f1]*$/i),this.trimValidator()]],
    neighborhood: ['', [Validators.required, Validators.maxLength(20),Validators.pattern(/^[a-zA-Z\s\u00E0-\u00FC\u00f1]*$/i),this.trimValidator()]],
    stratum:['', Validators.required],
    housing_type: ['', Validators.required],
    gender: ['', Validators.required],
    other_gender: ['', []],
    identity_name: ['', []],
    blood_type: ['', Validators.required],
    rh: ['', Validators.required],
    emergency_contact: ['', [Validators.required, Validators.maxLength(30),Validators.pattern(/^[a-zA-Z\s\u00E0-\u00FC\u00f1]*$/i),this.trimValidator()]],
    emergency_number: ['', [Validators.required, Validators.min(0), Validators.max(999999999999),Validators.pattern(/^-?(0|[1-9]\d*)?$/)]],
    number_people_charge:['', [Validators.required,Validators.pattern(/^-?(0|[1-9]\d*)?$/)]],
    number_people_at_home:['', [Validators.required,Validators.pattern(/^-?(0|[1-9]\d*)?$/)]],
    children_number: ['', [Validators.required, Validators.min(0),Validators.pattern(/^-?(0|[1-9]\d*)?$/)]],
    children:this._fb.array([]),
    military_situation: ['', Validators.required],
    //notebook_number: ['', [Validators.maxLength(20)]],
    //notebook_class: ['', [Validators.maxLength(20)]],
    //military_district_number: ['', [Validators.maxLength(20)]],
    travel_time:['', Validators.required],
    vehicle: ['', ],
    vehicle_type: ['', ],
    licence_number: ['', [Validators.maxLength(20),Validators.pattern(/^-?(0|[1-9]\d*)?$/),this.trimValidator()]],
    licence_type: ['', ],
    wage_aspiration: ['', Validators.required],
    hobbie: ['', [Validators.required, Validators.maxLength(30)]],
    shoe_size: ['', [Validators.required, Validators.maxLength(10)]],
    shirt_size: ['', [Validators.required, Validators.maxLength(10)]],
    pants_size: ['', [Validators.required, Validators.maxLength(10)]],
    caja_de_pensiones: ['', [Validators.required]],
    eps: ['', [Validators.required]],
    banco: [''],
    kind_account: [''],
    bank_account: ['', [Validators.maxLength(44),Validators.pattern(/[0-9]/)]],
    bank_account_comfirm: ['', [this.matchValues('bank_account')]],
  })
  secondFormGroup= this._fb.group({
    id: [],
    company_name: ['', [Validators.required, Validators.maxLength(20)]],
    company_phone: ['', [Validators.min(1), Validators.max(999999999999)]],
    position_hired: ['', [Validators.required, Validators.maxLength(20)]],
    company_city: ['', [Validators.maxLength(20)]],
    boss_name: ['', [Validators.maxLength(40)]],
    boss_phone: ['', [Validators.min(1), Validators.max(999999999999)]],
    boss_position: ['', Validators.maxLength(40)],
    boss_mobile_phone: [''],
    current_job: ['', [Validators.required, Validators.maxLength(20)]],
    start_date: ['', ],
    end_date: ['', ],
    earned_salary: ['', ],
    retirement_reason: ['', ],
    functions_achievements: ['', [Validators.maxLength(500)]],
  })
  thirdFormGroup=this._fb.group({
    id: [],
    currently_studying: ['', ],
    education_level: ['', ],
    institution: ['', [Validators.maxLength(100), Validators.required]],
    obtained_title: ['', [Validators.maxLength(100)]],
    study_city: ['', [Validators.maxLength(100)]],
    finish_date: ['', ],
    periods_completed: ['', [Validators.min(0), Validators.max(999)]],
  })
  fourthFormGroup = this._fb.group({
    id: [],
    id_document_type: ['', ],
    id_document_number: ['', [Validators.maxLength(15), Validators.required]],
    city_document_id: ['', [Validators.maxLength(20),Validators.pattern(/^[a-zA-Z\s\u00E0-\u00FC\u00f1]*$/i),this.trimValidator()]],
    expedition_date_id: ['', ],
    first_name: ['', [Validators.maxLength(20), Validators.required,Validators.pattern(/^[a-zA-Z\s\u00E0-\u00FC\u00f1]*$/i),this.trimValidator()]],
    middle_name: ['', [Validators.maxLength(20),Validators.pattern(/^[a-zA-Z\s\u00E0-\u00FC\u00f1]*$/i),this.trimValidator()]],
    last_name: ['', [Validators.maxLength(20), Validators.required,Validators.pattern(/^[a-zA-Z\s\u00E0-\u00FC\u00f1]*$/i),this.trimValidator()]],
    second_last_name: ['', [Validators.maxLength(20),Validators.pattern(/^[a-zA-Z\s\u00E0-\u00FC\u00f1]*$/i),this.trimValidator()]],
    education_level: ['', ],
    relationship: ['', Validators.required ],
    gender: ['', ],
    birthdate: ['', ],
    profession: ['', [Validators.maxLength(30),Validators.pattern(/^[a-zA-Z\s\u00E0-\u00FC\u00f1]*$/i),this.trimValidator()]],
  })
  fifthFormGroup= this._fb.group({
    id: [],
    first_name: ['', [Validators.required, Validators.maxLength(20),Validators.pattern(/^[a-zA-Z\s\u00E0-\u00FC\u00f1]*$/i),this.trimValidator()]],
    middle_name: ['', [Validators.pattern(/^[a-zA-Z\s\u00E0-\u00FC\u00f1]*$/i),this.trimValidator()]],
    last_name: ['', [Validators.required, Validators.maxLength(20),Validators.pattern(/^[a-zA-Z\s\u00E0-\u00FC\u00f1]*$/i),this.trimValidator()]],
    second_last_name: ['', [Validators.pattern(/^[a-zA-Z\s\u00E0-\u00FC\u00f1]*$/i),this.trimValidator()]],
    relationship: ['', Validators.required],
    mobile_phone: ['', [Validators.required, Validators.min(1), Validators.max(999999999999)]],
    phone: ['', Validators.pattern(/^-?(0|[1-9]\d*)?$/)],
    city: ['', [Validators.required, Validators.maxLength(20),Validators.pattern(/^[a-zA-Z\s\u00E0-\u00FC\u00f1]*$/i),this.trimValidator()]],
    occupation: ['', [Validators.required]],
  })
  sixthFormGroup = this._fb.group({
    computer: ['', ],
    computer_type: ['', ],
    ram: ['', ],
    processor: ['', [Validators.maxLength(100)]],
    internet: ['', ],
    internet_capacity: ['', [Validators.maxLength(100)]],
  });
  seventhFormGroup = this._fb.group({
    identity_document: this._fb.array([]),
    personal_reference: this._fb.array([]),
    academic_reference :this._fb.array([]),
    job_reference: this._fb.array([]),
    adres_certified: this._fb.array([]),
    ruaf_certified: this._fb.array([]),
    bank_certified: this._fb.array([]),
    photo:  this._fb.array([]),
  });

 // array auxiliar
  jobs_references: any = [];
  education: any = [];
  group_family: any = [];
  personal_references: any =[]
  typeData = ['basic_data', 'personal_data', 'jobs_references', 'education', 'group_family', 'personal_references', 'tech_tools', 'documents'];
  stepRequest = 0
  rrhhId= this._auth.systemId('RRHH')
  candidateId:any
  age:number;
  bank_account:number;
  currentEps;
  currentPens;
  currentKindAccount;
  currentBank;
  flag= this._auth.systemKey();
  bankData: any = [];
  stateKey: any = null;
  infoEps: any = [];
  infoCajaPensiones: any = [];
  kindAccountList: any = [];

  ngAfterViewInit(): void{
    setTimeout(() => {
      this.basic.nativeElement.click();
    }, 1000);
  }
  ngOnInit(): void {
    if ( this.rrhhId == null ) {
      this.rrhhId = this._auth.systemId('NOMINA');
    }

    this.firstFormGroup.controls.bank_account.valueChanges.subscribe((val) => {
      this.firstFormGroup.controls.bank_account_comfirm.setValue('')
      if(val == ''){
        this.firstFormGroup.controls.bank_account_comfirm.clearValidators();
        this.firstFormGroup.controls.bank_account_comfirm.updateValueAndValidity();
      }else{
        this.firstFormGroup.controls.bank_account_comfirm.setValidators(this.matchValues('bank_account'));
      }
    });

    this._SteperService.getInfo(`candidate/data/autogestion/${this.rrhhId}/${this.flag}`).subscribe( res => {

      if(res.birth_date){
        res.birth_date = new Date (res.birth_date);
      }

      /* if (res.id_number) { res.id_number_verify = res.id_number; } */

      this.stateKey = res?.state_key;
      this.basicFormGroup.patchValue(res);
      this.changeAge(this.basicFormGroup.get('birth_date').value)
      this.candidateId = res.id
      this._SteperService.getAttachments(this.candidateId).subscribe((res:any)=> {
        let filter = [];
        let files = [];
        filter = res;
        filter.map(item =>{
          if(item.fileKey == 'firma_acuerdo_confidencialidad' || item.fileKey == 'firma_acuerdo_imagen' || item.fileKey == 'firma_consentimiento'){
            files.push(item)
          }
        })
        this.documentsToUploadToOperation = files
      });

      if(res.agreementRules){
        if(res.id_type_id) this.basicFormGroup.controls.id_type_id.disable()
        if(res.id_number_verify)this.basicFormGroup.controls.id_number_verify.disable()
        if(res.id_number)this.basicFormGroup.controls.id_number.disable()
        if(res.number_PA)this.basicFormGroup.controls.number_PA.disable()
        if(res.number_PA_verify)this.basicFormGroup.controls.number_PA_verify.disable()
      }
      this.isLinear = this.basicFormGroup.valid;

       this.basicFormGroup.valueChanges.subscribe(
         res=>{
           this.isLinear = this.basicFormGroup.valid;
         }
       );
    } )
    this.getOptions('banco');
    this.getOptions('eps');
    this.getOptions('caja_de_pensiones');
    this.getKindAccounts();


  }
  tabSelected;
  onStepChange(event){
    this.stepRequest = event.selectedIndex;
    if(event.selectedIndex<event.previouslySelectedIndex){
      return
    }
    switch (event.selectedIndex) {
      case 1:
        if(!this.firstFormGroup.valid)
        {
          setTimeout(() => {
            this.personalD.nativeElement.click();
          }, 1000);
        }
        this.isLinear = this.firstFormGroup.valid;
        this.firstFormGroup.valueChanges.subscribe(
          res=>{
            this.isLinear = this.firstFormGroup.valid;
          }
        );
        if(this.basicFormGroup.valid){
          this.basicFormGroup.value.email.trim();
          this.basicFormGroup.value.email2 != null ? this.basicFormGroup.value.email2.trim(): '';
          this.saveStepData(this.typeData[this.stepRequest-1], this.basicFormGroup.getRawValue(), this.firstFormGroup)
        }
        if(this.firstFormGroup.valid){
          this.basicFormGroup.value.email.trim();
          this.basicFormGroup.value.email2 != null ? this.basicFormGroup.value.email2.trim(): '';
          this.saveStepData(this.typeData[this.stepRequest-1], this.basicFormGroup.getRawValue(), this.firstFormGroup)
        }else{
          this.notificationService.alertWarning('Cuidado','Valida los datos personales del formulario')
        }
        break;
      case 2:
        //capturamos los valores de banco, eps y caja compensacion para el envio del json
        let nameBanco = this.firstFormGroup.get('banco').value;
        this.currentBank = this.bankData.find(element => element.name == nameBanco);

        let nameEps = this.firstFormGroup.get('eps').value;
        this.currentEps = this.infoEps.find(element => element.name == nameEps);

        let namePens = this.firstFormGroup.get('caja_de_pensiones').value;
        this.currentPens = this.infoCajaPensiones.find(element => element.name == namePens);

        let nameTypeCuenta = this.firstFormGroup.get('kind_account').value;
        this.currentKindAccount = this.kindAccountList.find(element => element.name == nameTypeCuenta);

        this.firstFormGroup.get('eps').setValue(this.currentEps);
        this.firstFormGroup.get('caja_de_pensiones').setValue(this.currentPens);
        this.firstFormGroup.get('banco').setValue(this.currentBank);
        this.firstFormGroup.get('kind_account').setValue(this.currentKindAccount);

        this.saveStepData(this.typeData[this.stepRequest-1],this.firstFormGroup.getRawValue())

        if(this.stateKey != 'contratado'){
          //generacion de consentimientos para la gestion del candidato en contratacion
          this._SteperService.generateDocuments({stateKey:'contratacion', candidateId:this.candidateId, document:'all'}).subscribe( (resp:any) => {
            // if(resp != 'Documentos generados con exito'){
            //   this.notificationService.alertWarning('Alerta!', resp)
            // }
          },error =>{
            // this.notificationService.alertWarning('Alerta!', error.error.error)
          })
        }
        this.firstFormGroup.get('eps').setValue(this.currentEps.name);
        this.firstFormGroup.get('caja_de_pensiones').setValue(this.currentPens.name);
        this.firstFormGroup.get('banco').setValue(this.currentBank.name);
        this.firstFormGroup.get('kind_account').setValue(this.currentKindAccount.name);

        break;
      case 7:
        this.saveStepData(this.typeData[this.stepRequest-1],this.sixthFormGroup.getRawValue())
        break;
    }

  }

  saveStepData(steperName, data, element?){
    this._SteperService.postInfo(`candidate/json/${this.candidateId}/${steperName}`, data ).subscribe( (res) => {
      this.snack.open('Datos Guardados', 'ok', {
        duration: 2400
      });
    })

  }
  changeAge(event){
    if(event){
      let timeDiff = Math.abs(Date.now() - event.getTime());
      let age = Math.floor((timeDiff / (1000 * 3600 * 24))/365.25);
      this.age = age
    }

  }

  /**
   * @author Carlos Nieto
   * @createdate 2022-07-06
   * Metodo que valida que la fecha de cumpleaños no sea menor a 15 años
   */
   validateDate(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      var today = new Date()
      today.setFullYear( today.getFullYear() - 15 );
      let value = control.value == null ? null : control.value;
      if (value > today) {
        return { invalidDate: { value: control.value } };
      }
      return null;
    };
  }

  /**
   * @author Carlos Nieto
   * @createdate 2022-07-08
   * Metodo que valida que el campo de auto select sea igual a las sugerencias
   */
   autocompleteStringValidator(validOptions: Array<string>): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      if (validOptions.indexOf(control.value) !== -1) {
        return null  /* valid option selected */
      }
      return { 'invalidAutocompleteString': { value: control.value } }
    }
  }


  /**
       * Metodo que valida el captura el json de eps y pensiones
       * @author Carlos Nieto
       * @date 19-07-2022
       * @return json
       */
    getChieldValue(value,bool)
  {
  /*  if(bool)
    {
      this.currentEps = value;
    }else{

      this.currentPens = value;
    }*/
  }

  /**
       * Metodo que valida espacios en blanco al inicio y final del input
       * @author Carlos Nieto
       * @date 18-10-2022
       * @return json
       */
  trimValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      if(control.value != null)
      {
        if (control.value.startsWith(' ')) {
          return {
            trimErrorInit: { value: 'control tiene especios en blanco al inicio' }
          };
        }
        if (control.value.endsWith(' ')) {
          return {
            trimErrorLast: { value: 'control tiene espacios en blanco al final' }
          };
        }
      }

      return null;
    };
  }

    /** Retornar datos del formulario
   *@author Lina Hueso
  */
   async getOptions(key) {
    await this._SteperService.getOptions(key).subscribe(resp => {
      if (key == 'eps') {
        this.infoEps = resp;
      }
      if (key == 'caja_de_pensiones') {
        this.infoCajaPensiones = resp;
      }
      if (key == 'banco') {
        this.bankData = resp;
      }

    }
    );
  }
  getKindAccounts() {
    this.kindAccountService.getKindAccounts().subscribe(resp => {
      this.kindAccountList = resp;
    });
  }
  setCandidateId(event){
    this.candidateId = event;
  }

  goSignature($e){
    if($e.index == 1){
     this.router.navigateByUrl(`/cos/candidates/hiring`);
    }
   }

   matchValues( matchTo: string ): (AbstractControl) => ValidationErrors | null {
    return (control: AbstractControl): ValidationErrors | null => {
      return !!control.parent &&
        !!control.parent.value &&
        control.value === control.parent.controls[matchTo].value
        ? null
        : { isMatching: false };
    };
  }

}
