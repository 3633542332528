<div class="h-100 overflowx-none">
  <div class="row h-90 no-gutters">
    <div class="d-none d-lg-block col-lg-7 bg-color">
      <div class="trama">
        <img class="margin-img" src="../../../assets/images/g10.svg" alt="">
        <br>
        <img class="margin-text" src="../../../assets/images/cambio.svg" alt="">
        <br>
        <img class="margin-sub-text" src="../../../assets/images/experi.svg" alt="">
      </div>
    </div>
    <div class="bg-white justify-content-center align-items-center col-md-12 col-lg-5">
      <div class="row minh-90">
        <div class="col-lg-1"></div>
        <div class="mx-auto col-lg-10">
          <div class="card">
            <div class="margin-lg">
              <img src="../../../assets/images/Icon-Soul.svg" alt="">
            </div>
            <br>
            <br>
            <div class="title">
              <h1> ¡Bienvenido a SOUL!</h1>
              <p>Que tengas un excelente día</p>
            </div>
            <br>
            <br>
            <div style="min-height: 80%;" fxLayout="column" fxLayoutAlign="space-between none">
              <form class="row form" [formGroup]="createPasswordForm" (ngSubmit)="createPassword()" style="min-height: 60%;">
                <input id="username" style="display:none" type="text" name="fakeusernameremembered">
                <input id="password" style="display:none" type="password" name="fakepasswordremembered">
                <mat-form-field class="col-12"  appearance="fill">
                  <mat-label>Nueva contraseña</mat-label>
                  <input matInput appBlockCopyPaste (keydown.enter)="createPassword()" (keypress)="omitSpecialChar($event)" [type]="hide ? 'password' : 'text'" placeholder="Escribe tu contraseña" formControlName="password" required>
                  <mat-error *ngIf="error.password.errors"><span *ngIf="error.password.errors.required">¡Advertencia! Este campo es requerido</span></mat-error>
                  <mat-error *ngIf="error.password.errors"><span *ngIf="error.password.errors.minlength">¡Aún falta! Recibimos mínimo 5 caracteres</span></mat-error>
                  <mat-error *ngIf="error.password.errors"><span *ngIf="error.password.errors.maxlength">¡Cuidado! Solo puedes tener un máximo de 15 caracteres</span></mat-error>
                        <mat-error *ngIf="error.password.errors"><span *ngIf="error.password.errors.hasNumber">Su contraseña debe tener un número</span></mat-error>
                        <mat-error *ngIf="error.password.errors"><span *ngIf="error.password.errors.hasCapitalCase">Su contraseña debe tener una mayuscula</span></mat-error>
                        <mat-error *ngIf="error.password.errors"><span *ngIf="error.password.errors.hasSmallCase">Su contraseña debe tener una minuscula</span></mat-error>
                        <mat-error *ngIf="error.password.errors"><span *ngIf="error.password.errors.hasSpecialCharacters">Su contraseña debe tener un caracter especial</span></mat-error>
                  <a mat-icon-button matSuffix (click)="hide = !hide" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide">
                    <mat-icon>{{hide ? 'visibility' : 'visibility_off'}}</mat-icon>
                  </a>
                </mat-form-field>

                <mat-form-field class="col-12"  appearance="fill">
                  <mat-label>Confirme su contraseña</mat-label>
                  <input matInput appBlockCopyPaste (keydown.enter)="createPassword()" (keypress)="omitSpecialChar($event)" [type]="hide ? 'password' : 'text'" placeholder="Confirme su contraseña" formControlName="confirmPassword" required>
                        <mat-error *ngIf="error.confirmPassword.errors"><span *ngIf="error.confirmPassword.errors.required">¡Advertencia! Este campo es requerido</span></mat-error>
                        <mat-error *ngIf="error.confirmPassword.errors"><span *ngIf="error.confirmPassword.errors.minlength">¡Aún falta! Recibimos mínimo 5 caracteres</span></mat-error>
                        <mat-error *ngIf="error.confirmPassword.errors"><span *ngIf="error.confirmPassword.errors.maxlength">¡Cuidado! Solo puedes tener un máximo de 15 caracteres</span></mat-error>
                        <mat-error *ngIf="error.confirmPassword.errors"><span *ngIf="error.confirmPassword.errors.hasNumber">Su contraseña debe tener un número</span></mat-error>
                        <mat-error *ngIf="error.confirmPassword.errors"><span *ngIf="error.confirmPassword.errors.hasCapitalCase">Su contraseña debe tener una mayuscula</span></mat-error>
                        <mat-error *ngIf="error.confirmPassword.errors"><span *ngIf="error.confirmPassword.errors.hasSmallCase">Su contraseña debe tener una minuscula</span></mat-error>
                        <mat-error *ngIf="error.confirmPassword.errors"><span *ngIf="error.confirmPassword.errors.hasSpecialCharacters">Su contraseña debe tener un caracter especial</span></mat-error>
                  <a mat-icon-button matSuffix (click)="hide = !hide" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide">
                    <mat-icon>{{hide ? 'visibility' : 'visibility_off'}}</mat-icon>
                  </a>
                </mat-form-field>

                <br>
                <br>
<!--                <div class="col-12 forgotPass" >-->
<!--                  <a><b class="link">Generar o Recuperar Contraseña</b></a>-->
<!--                </div>-->
<!--                <div class="col-12 forgotPass" >-->
<!--                  <a routerLink="/auth/login"><b class="link">Volver a iniciar sesión</b></a>-->
<!--                </div>-->
                <br>
                <br>
                <div class="col-12 mt-3">
                  <button mat-raised-button type="submit" style="width: 100%;" color="primary" [disabled]='createPasswordForm.invalid'>Crear contraseña</button>
                </div>
                <div class="col-12 mt-3">
                  <a mat-raised-button routerLink="/auth/login" style="width: 100%;" class="blueButton">Iniciar sesión</a>
                </div>
              </form>

              <div class="color-purple" style="min-height: 20%;">
                <div fxLayout="row" fxLayoutAlign="center center" class="col footer mt-5">Develop by Montechelo &nbsp;<span style="transform: scale(1)" class="fi-sr-heart"></span> &nbsp; {{anio}}</div>
                <div fxLayout="row" fxLayoutAlign="center center" class="col footerv mt-1">Versión 2.0</div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-1"></div>
      </div>
    </div>
  </div>
</div>
