<div class="head">
    <h1>Autogestión de Candidato</h1>
    <mat-tab-group (selectedTabChange)="goSignature($event)">
        <mat-tab label="Información de Candidato">
            <mat-vertical-stepper [linear]="!isLinear" labelPosition="bottom" (selectionChange)="onStepChange($event)" #stepper>
                <mat-step [stepControl]="basicFormGroup" [editable]="isEditable">
                    <div>
                        <ng-template matStepLabel><div #basic>Datos Básicos</div></ng-template>
                    </div>
                    <candidate-basic-form [form]="basicFormGroup" (birthDate)="changeAge($event)" (candidateId)="setCandidateId($event)" ></candidate-basic-form>
                    <div fxLayout="row" fxLayoutAlign="end center" class="action_btns">
                        <button mat-raised-button color="primary" matStepperNext color="primary" align="end" [disabled]="!basicFormGroup.valid">Siguiente</button>
                    </div>
                </mat-step>
                <mat-step [stepControl]="firstFormGroup">
                    <ng-template matStepLabel><div #personal_d>Datos Personales</div></ng-template>
                    <candidate-first-step  [form]="firstFormGroup" [age]="age" (epsEmmit)="getChieldValue($event,true)" (pensionesEmmit)="getChieldValue($event,false)"></candidate-first-step>
                    <div fxLayout="row" fxLayoutAlign="end center" class="action_btns">
                        <button mat-raised-button color="primary" matStepperPrevious>Atrás</button>
                        <button mat-raised-button color="primary" matStepperNext>Siguiente</button>
                    </div>
                </mat-step>
                <mat-step [stepControl]="secondFormGroup" [editable]="isEditable">
                    <ng-template matStepLabel>Experiencia Laboral</ng-template>
                    <candidate-second-step *ngIf="stepRequest == 2" [form]="secondFormGroup" [workExperiencies]="jobs_references" [candidateId]="candidateId"></candidate-second-step>
                    <div fxLayout="row" fxLayoutAlign="end center" class="action_btns">
                        <button mat-raised-button color="primary" matStepperPrevious>Atrás</button>
                        <button mat-raised-button color="primary" matStepperNext>Siguiente</button>
                    </div>
                </mat-step>
                <mat-step [stepControl]="thirdFormGroup" [editable]="isEditable">
                    <ng-template matStepLabel>Estudios</ng-template>
                    <candidate-third-step *ngIf="stepRequest == 3" [form]="thirdFormGroup" [studies]="education" [candidateId]="candidateId"></candidate-third-step>
                    <div fxLayout="row" fxLayoutAlign="end center" class="action_btns">
                        <button mat-raised-button color="primary" matStepperPrevious>Atrás</button>
                        <button mat-raised-button color="primary" matStepperNext>Siguiente</button>
                    </div>
                </mat-step>
                <mat-step [stepControl]="fourthFormGroup" [editable]="isEditable">
                    <ng-template matStepLabel>Grupo Familiar</ng-template>
                    <candidate-fourth-step *ngIf="stepRequest == 4" [form]="fourthFormGroup" [familyGroup]="group_family" [candidateId]="candidateId"></candidate-fourth-step>
                    <div fxLayout="row" fxLayoutAlign="end center" class="action_btns">
                        <button mat-raised-button color="primary" matStepperPrevious>Atrás</button>
                        <button mat-raised-button color="primary" matStepperNext>Siguiente</button>
                    </div>
                </mat-step>
                <mat-step [stepControl]="fifthFormGroup" [editable]="isEditable">
                    <ng-template matStepLabel>Referencias Personales</ng-template>
                    <candidate-fifth-step *ngIf="stepRequest == 5" [form]="fifthFormGroup" [personalReferences]="personal_references" [candidateId]="candidateId"></candidate-fifth-step>
                    <div fxLayout="row" fxLayoutAlign="end center" class="action_btns">
                        <button mat-raised-button color="primary" matStepperPrevious>Atrás</button>
                        <button mat-raised-button color="primary" matStepperNext>Siguiente</button>
                    </div>
                </mat-step>
                <mat-step [stepControl]="sixthFormGroup" [editable]="isEditable">
                    <ng-template matStepLabel>Herramientas Tecnológicas</ng-template>
                    <candidate-sixth-step *ngIf="stepRequest == 6" [form]="sixthFormGroup" [candidateId]="candidateId"></candidate-sixth-step>
                    <div fxLayout="row" fxLayoutAlign="end center" class="action_btns">
                        <button mat-raised-button color="primary" matStepperPrevious>Atrás</button>
                        <button mat-raised-button color="primary" matStepperNext>Siguiente</button>
                    </div>
                </mat-step>
                <mat-step [stepControl]="seventhFormGroup" [editable]="isEditable">
                    <ng-template matStepLabel>Documentos</ng-template>
                    <candidate-seventh-step *ngIf="stepRequest == 7" [form]="seventhFormGroup" [id]="this.candidateId" [candidateId]="candidateId"></candidate-seventh-step>
                    <div fxLayout="row" fxLayoutAlign="end center" class="action_btns">
                        <button mat-raised-button color="primary" matStepperPrevious>Atrás</button>
                    </div>
                </mat-step>
                <!-- <mat-step>
                    <ng-template matStepLabel>Hecho</ng-template>
                    <p>Has Finalizado</p>
                    <div fxLayout="row" fxLayoutAlign="end center" class="action_btns">
                        <button mat-raised-button color="primary" matStepperPrevious>Atrás</button>
                        <button mat-raised-button color="primary" (click)="stepper.reset()">Reiniciar</button>
                    </div>
                </mat-step> -->
        
            </mat-vertical-stepper>    
        </mat-tab>
        <mat-tab label="Firmar acuerdos">
            
           <!--  <div class="section-action" style="margin-top:48px;">
                <table mat-table [dataSource]="documentsToUploadToOperation">
      
                  <ng-container matColumnDef="actions">
                    <th class="start" mat-header-cell *matHeaderCellDef> Acciones </th>
                    <td mat-cell *matCellDef="let row" style="text-align: left;">
                      <button mat-icon-button *ngIf="!row.uploaded || row.state == 'Rechazado'"
                        (click)="fileInput.click()"><span class="fi-rr-cloud-upload"
                          matTooltip="Cargar Documento"></span></button>
                      <button mat-icon-button *ngIf="(row.fileKey != 'encuesta') && !row.uploaded || row.state == 'Rechazado'"
                        (click)="crateSign( row.file_attachment_id,row.id)"><span class="fi-rr-pencil"
                          matTooltip="Firmar digitalmente"></span></button>
                      <input hidden (change)="onFileSelected($event.target.files, row.file_attachment_id,row.id)" #fileInput
                        type="file" id="file" accept="image/png, image/jpeg">
                      <button mat-icon-button *ngIf="row.uploaded" (click)="downdloadFile(row, true)"
                        matTooltip="Ver documento"><span class="fi-rr-eye"></span></button>
                      <button mat-icon-button *ngIf="row.uploaded" matTooltip="Descargar documento"
                        (click)="downdloadFile(row)"><span class="fi-rr-cloud-download"></span></button>
                    </td>
                  </ng-container>
      
      
                  <ng-container matColumnDef="file">
                    <th mat-header-cell *matHeaderCellDef> Tipo </th>
                    <td mat-cell *matCellDef="let element"> {{element.file}} </td>
                  </ng-container>
      
      
                  <ng-container matColumnDef="name">
                    <th mat-header-cell *matHeaderCellDef>Archivo cargado</th>
                    <td mat-cell *matCellDef="let element"> {{element.name}} </td>
                  </ng-container>
      
                  <ng-container matColumnDef="state">
                    <th class="end" mat-header-cell *matHeaderCellDef> Estado</th>
                    <td mat-cell *matCellDef="let element"> {{element.state}} </td>
                  </ng-container>
      
                  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                  <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                </table>
              </div>   -->  

        </mat-tab>
      </mat-tab-group>
      


</div>