import { Injectable } from '@angular/core';
import Swal from 'sweetalert2';

@Injectable({
  providedIn: 'root'
})
export class AlertsService {

  constructor() { }

  alertSuccess(title: string, text: string, options : any = {}){

    Swal.fire({
      title,
      html: text,
      icon: 'success',
      showConfirmButton: false,
      timer: 5000,
      ...options
    });

  }

  alertError(title: string, text: string, options : any = {}){

    Swal.fire({
      title,
      html: text,
      icon: 'error',
      confirmButtonText: 'Aceptar',
      ...options
    });

  }

  dangerError(title: string){
    const Toast = Swal.mixin({
      toast: true,
      position: 'top-start',
      showConfirmButton: false,
      timer: 10000,
      timerProgressBar: true,
      didOpen: (toast) => {
        toast.addEventListener('mouseleave', Swal.resumeTimer)
      }
    })
    
    Toast.fire({
      icon: 'error',
      title
    })
  }

  alertWarning(title: string, text: string){

   return Swal.fire({
      title,
      html: text,
      icon: 'warning',
      confirmButtonText: 'Aceptar',
      showCancelButton: true,
      cancelButtonText: 'Cancelar'
    });

  }

  alertWarningV2(title: string, text: string){

    return Swal.fire({
      title,
      html: text,
      icon: 'warning'
    });

  }

  alertInfo(title: string, text: string){

    Swal.fire({
      title,
      text,
      icon: 'info',
      confirmButtonText: 'Aceptar'
    });
  }

  alertConfirm(title: string){

  return Swal.fire({
      title,
      showDenyButton: true,
      showCancelButton: false,
      confirmButtonText: `Si`,
      denyButtonText: `No`,
    });
  }

  alertSuccessWithoutButton(title: string, text: string){

    Swal.fire({
      title,
      html: text,
      icon: 'success',
      showConfirmButton: false,
      timer: 4000
    });

  }

  alertSuccessButtonText(title: string, text: string, btnText: string) {

    Swal.fire({
      title,
      html: text,
      icon: 'success',
      confirmButtonText: btnText
    });

  }

}
