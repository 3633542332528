import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Routes, RouterModule } from '@angular/router';
import { PersonalInformationComponent } from './pages/personal-information/personal-information.component';
import { TestsComponent } from './pages/tests/tests.component';
import { ContractComponent } from './pages/contract/contract.component';


const routes: Routes = [
	{ path: '', redirectTo: 'personal-information', pathMatch: 'prefix'},
	{
		path: 'personal-information',
		component: PersonalInformationComponent
	},
	{
		path: 'tests',
		component: TestsComponent
	},{
		path: 'hiring',
		component: ContractComponent
	},
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
	exports: [RouterModule]
})
export class CandidateRoutingModule { }
