// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {

  production: true,

  api_auth:'https://apigateway.outsourcingcos.com/v1/ms/mios/auth/api',
  RRHH: 'https://apigateway.outsourcingcos.com/v1/ms/mios/rrhh/api/',
  NOMINA: 'https://apigateway.outsourcingcos.com/v1/ms/nomina/api/',
  JURIDICA: 'https://apigateway.outsourcingcos.com/v1/ms/juridico/api/',
  CIU: 'https://apigateway.outsourcingcos.com/v1/ms/ciu/api/',
  BPMS: 'https://apigateway.outsourcingcos.com/v1/ms/bpms/api/',
  CRM: 'https://apigateway.outsourcingcos.com/v1/ms/express/api/',
  PQRS: 'https://apigateway.outsourcingcos.com/v1/ms/pqrs/api/',
  CARTERA: 'https://apigateway.outsourcingcos.com/v1/ms/cartera/api/',
  REPORTS: 'https://apigateway.outsourcingcos.com/v1/ms/reportes/api/',
  MAIL: 'https://apigateway.outsourcingcos.com/v1/ms/email/api/',
  ENCUESTAS: 'https://apigateway.outsourcingcos.com/v1/ms/encuestas/api/'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.

