import { Component, OnInit, Input } from '@angular/core';
import { FormBuilder, FormGroup, RequiredValidator, Validators } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import { AuthServicesService } from 'src/app/auth/services/auth.service';
import { FormsSteperService } from 'src/app/candidate/services/rest/forms-steper.service';
import { NotificationService } from 'src/app/core/services/notification.service';
import { saveAs as importedSaveAs } from "file-saver";
import { FileValidator } from 'ngx-material-file-input';
import {CandidatesService} from "../../../../core/services/candidates.service";

@Component({
  selector: 'candidate-seventh-step',
  templateUrl: './seventh-step.component.html',
  styleUrls: ['./seventh-step.component.sass']
})
export class SeventhStepComponent implements OnInit {
  @Input() form:FormGroup;
  @Input() id;
  @Input() candidateId; 
  identity_document: any = [];
  personal_reference: any = [];
  academic_reference: any = [];
  job_reference: any = [];
  adres_certified: any = [];
  ruaf_certified: any = [];
  bank_certified: any = [];
  photo: any = [];

  attachments: any = [];
  attachmentId;
  documentsCandidate: any = [];

  documentToFilter = ['photo','identity_document','personal_reference','academic_reference','job_reference','adres_certified','ruaf_certified','bank_certified'];
  documentToOperationFilter = ['antecedentes_penales','eps','arl','afc'];
  generateDocumentToActionsFilter = ['contrato','codigo_qr','acuerdo_confidencialidad','acuerdo_imagen'];
  documentToActionsFilter = ['firma','encuesta'];

  candidateDocuments:any = [];
  candidateOtherDocuments:any = [];
  documentGeneratedByOperation: any = [];
  documentsToUploadToOperation:any = [];

  displayedColumnsDocuments = ['file','state','actions', 'templates'];
  dataSourceDocuments = new MatTableDataSource<any>([]);


  constructor(
    private alertService:NotificationService,
    private _formSteper:FormsSteperService,
    private authService:AuthServicesService,
    private candidatesService:CandidatesService,
  ) {
    // this.candidateId = this.authService.systemId('RRHH');
    if ( this.candidateId == null ) {
      this.candidateId = this.authService.systemId('NOMINA');
    }
  }

  ngOnInit(): void {
    this.getDocuments();
  }

  deleteFile(id){

    this.alertService.alertConfirm('¿Estás seguro?','Esta acción eliminará el documento seleccionado').then( e => {
      if(e.isConfirmed){
        this._formSteper.deleteFile(id).subscribe( resp => {
          this.alertService.alertSuccess('Documento eliminado con exito','');
          this.getDocuments();
        })
      }
    })

  }

  getDocuments() {
    this._formSteper.getAttachments(this.candidateId).subscribe( (resp: any) => {
        this.documentsCandidate = resp;
        let documents = resp.filter( (elem:any) => {
          return this.documentToFilter.indexOf(elem.fileKey) > -1;
        })
        this.dataSourceDocuments = new MatTableDataSource(documents);

    });
  }

  onFileSelected(files, documentTypeId,attachmentId, e, fileKey ) {
    let selectedFile = e.target.files[0];
    let typeFile = selectedFile.type;
    if(fileKey == 'photo'){
      if(typeFile == 'image/png' || typeFile == 'image/jpeg'){
        const idx = this.attachments.length;
        this.attachments = files[0];
        this.attachmentId = attachmentId;
        this.saveDocuments(this.attachmentId,documentTypeId);  
      }else{
        this.alertService.alertError('Alerta!', 'Solo se aceptan documentos jpeg y png');
      }
    }else{
      if(typeFile == 'application/pdf'){
        const idx = this.attachments.length;
        this.attachments = files[0];
        this.attachmentId = attachmentId;
        this.saveDocuments(this.attachmentId,documentTypeId);  
      }else{
        this.alertService.alertError('Alerta!', 'Solo se aceptan documentos .pdf');
      }
    }
    

  }

  saveDocuments(attachmentId,documentTypeId) {
    this._formSteper.uploadDocumentCandidate(this.candidateId, this.attachments, documentTypeId,attachmentId).subscribe((resp: any) => {
      this.alertService.alertSuccess('Cargado', 'Su documento se ha cargado correctamente');
      this.getDocuments();
    });

  }

  downdloadFile(fileId,fileName){

    this._formSteper.downdloadFile(fileId, this.id).subscribe( (file) =>{
      importedSaveAs(file, fileName);
    })
  }

  downloadTemplate() {
    this.candidatesService.getTemplateFileName().subscribe((fileName: any)=> {
      this.candidatesService.downloadTemplate().subscribe( (file) =>{
        importedSaveAs(file, fileName.basename);
      });
    });

  }
}
const OPTION_TO_FILTER = {
  document:1,
  actions:2,
  all:3
}
