import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthServicesService } from 'src/app/auth/services/auth.service';
import { environment } from 'src/environments/environment';


@Injectable({
  providedIn: 'root'
})
export class CandidateService {

    constructor( 
        private authServie:AuthServicesService,
        private http:HttpClient 
        ) 
    {}
    private serve = this.authServie.systemIdentify('RRHH');


    getCandidateProgress(id){
        return this.http.get<any>(`${this.serve}progress/${id}`);
    }

    getCandididatesByDocuments(documents){
        let data = {documents:documents }
        return this.http.post(`${API_RRHH}candidates/searchbydocuments`,data);
    }

}
const API_RRHH = environment.RRHH;
