<br>
<form fxLayout="row wrap"  fxLayout.xs="column"fxLayoutGap="35px" [formGroup]="form">
   <mat-form-field appearance="fill" fxFlex=30>
        <mat-label>Tiene Computador</mat-label>
        <mat-select matNativeControl formControlName="computer"
        (ngModelChange)="checkDependence($event, 'Si', 'havePc', pcChildren)">
            <mat-option value="Si">Si</mat-option>
            <mat-option value="No">No</mat-option>
        </mat-select>
    </mat-form-field>

    <mat-form-field appearance="fill" fxFlex=30 *ngIf="havePc">
        <mat-label>Tipo de Computador</mat-label>
        <mat-select matNativeControl formControlName="computer_type">
            <mat-option value="Móvil">Móvil</mat-option>
            <mat-option value="Fijo">Fijo</mat-option>
        </mat-select>
    </mat-form-field>

    <mat-form-field appearance="fill" fxFlex=30 *ngIf="havePc">
        <mat-label>Memoria Ram</mat-label>
        <mat-select matNativeControl formControlName="ram">
            <mat-option value="1Gb">1Gb</mat-option>
            <mat-option value="2Gb">2Gb</mat-option>
            <mat-option value="4Gb">4Gb</mat-option>
            <mat-option value="8Gb">8Gb</mat-option>
            <mat-option value="16Gb">16Gb</mat-option>
        </mat-select>
    </mat-form-field>

    <mat-form-field appearance="fill" fxFlex=30 *ngIf="havePc">
        <mat-label>Procesador</mat-label>
        <input matInput formControlName="processor">
    </mat-form-field>
    <hr fxFlex=100>
    <mat-form-field appearance="fill" fxFlex=30>
        <mat-label>Internet</mat-label>
        <mat-select matNativeControl formControlName="internet"
        (ngModelChange)="checkDependence($event, 'Si', 'haveRed', redChildren )">
            <mat-option value="Si">Si</mat-option>
            <mat-option value="No">No</mat-option>
        </mat-select>
    </mat-form-field>

    <mat-form-field appearance="fill" fxFlex=30 *ngIf="haveRed">
        <mat-label>Capacidad de Internet</mat-label>
        <input matInput formControlName="internet_capacity">
    </mat-form-field>

</form>
