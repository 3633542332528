<div fxLayout="column">
    <br>
    <p>Los documentos solicitados <b>a excepción de la foto</b>, deben ser formato pdf.</p>
    <br>
    <div fxFlex=100 >
        <table mat-table [dataSource]="dataSourceDocuments">

            <ng-container matColumnDef="file">
                <th mat-header-cell *matHeaderCellDef class="start"> Documento </th>
                <td mat-cell *matCellDef="let element"> {{element.file}} </td>
            </ng-container>

            <ng-container matColumnDef="state">
                <th mat-header-cell *matHeaderCellDef> Estado </th>
                <td mat-cell *matCellDef="let element" class="col-3">
                    <span class="col-3" *ngIf="element.state == 'Rechazado'" >{{element.state}}, {{element?.dataExtraJson?.subtyping}}: <br> {{element?.dataExtraJson?.comment}} </span>
                    <span *ngIf="element.state != 'Rechazado'" > {{element.state}}</span>

                </td>
            </ng-container>


            <!-- actions Column -->
            <ng-container matColumnDef="actions">
                <th mat-header-cell *matHeaderCellDef class="end"> Acciones </th>
                <td mat-cell *matCellDef="let row" >
                    <div class="text-center">
                        <button class="ml-1" *ngIf="!row.source && row.state != 'Aprobado' ||  row.name == 'No Aplica'"  mat-stroked-button color="primary" (click)="fileInput.click()"><i class="fi-rr-cloud-upload" matTooltip="Cargar Documento"></i> Cargar</button>
                        <input hidden (change)="onFileSelected($event.target.files, row.file_attachment_id,row.id, $event, row.fileKey )" #fileInput type="file" id="file" [accept]="row.fileKey =='photo'? 'image/png, image/jpeg' : 'application/pdf'">
                        <button class="ml-1" *ngIf="row.source && row.state == 'Aprobado' " color="primary" mat-stroked-button matTooltip="Descargar Documento" (click)="downdloadFile(row.id, row.name)">
                          <i class=" fi-sr-cloud-download"></i> Descargar
                        </button>
                        <button class="ml-1" *ngIf="row.multiple && row.state != 'Aprobado'" mat-stroked-button color="accent" [matTooltip]="'Agregar '+row.file" (click)="fileInputOther.click()">
                            <i class="fi-sr-file-add"></i> Cargar Otro
                        </button>
                        <input hidden (change)="onFileSelected($event.target.files, row.file_attachment_id,null, $event, row.fileKey)" #fileInputOther type="file" id="file" [accept]="row.fileKey =='photo'? 'image/png, image/jpeg' : 'application/pdf'">

                        <button class="ml-1" *ngIf="row.source " color="warn" [disabled]="row.state == 'Aprobado'? true : false" mat-stroked-button matTooltip="Borrar Documento" (click)="deleteFile(row.id)">
                          <i class=" fi-sr-trash"></i> Borrar
                        </button>
                    </div>


                </td>
            </ng-container>

          <ng-container matColumnDef="templates">
            <th class="end" mat-header-cell *matHeaderCellDef> Plantillas </th>
            <td mat-cell *matCellDef="let row">
              <button mat-raised-button color="primary"
                      *ngIf=" row.fileKey == 'personal_reference'  "
                      matTooltip="Descargar plantilla"
                      (click)="downloadTemplate()">
                <span>Download</span>
              </button>
            </td>
          </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumnsDocuments"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumnsDocuments;"></tr>
        </table>
        <br>
    </div>
</div>
