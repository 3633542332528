import { Injectable } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms';
import { QuestionBase } from 'src/app/model/QuestionBase'
import * as moment from 'moment';

@Injectable({
  providedIn: 'root'
})
export class QuestionServiceService {

  constructor() { }

  toFormGroup(questions: QuestionBase<any>[] ) {
    const group: any = {};

    questions.forEach(question => {

      if(question.type === 'date' && question.controlType === 'datepicker'){
        question.value = new Date(moment(question.value).format());
        const testDate = new Date(moment(question.value).format()).toString();
        if (testDate === 'Invalid Date') {
          question.value = null;
          console.log(question.value);
        }
      }

      group[question.key] = question.required ? new FormControl({value: question.value || '', disabled: question.disabled}, [Validators.required, Validators.minLength(question.minLength), Validators.maxLength(question.maxLength)])
                                              : new FormControl({value: question.value || '', disabled: question.disabled},[Validators.maxLength(question.maxLength), Validators.minLength(question.minLength)] );
      if (question.type == 'email') {
        group[question.key].setValidators([
          Validators.email,
          group[question.key].validator
        ]);
      }
      if (question.controlType == 'autocomplete' || question.controlType == 'autocomplete2' && question.type == 'options') {
        group[question.key].setValidators([
          RequireMatch,
          group[question.key].validator
        ]);
      }
    });
    return new FormGroup(group);
  }
}

export function RequireMatch(control: AbstractControl) {
  if (typeof control.value === 'string') {
    return { invalidAutocompleteObject: { value: control.value } };
  }
  return null;
}
