import { Component, OnInit, ViewChild, Inject } from '@angular/core';
import { SignaturePad } from 'angular2-signaturepad';
import { AuthServicesService } from 'src/app/auth/services/auth.service';
import { FormsSteperService } from 'src/app/candidate/services/rest/forms-steper.service';
import { NotificationService } from 'src/app/core/services/notification.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
@Component({
  selector: 'app-create-sign',
  templateUrl: './create-sign.component.html',
  styleUrls: ['./create-sign.component.sass'],

})
export class CreateSignComponent implements OnInit {
  @ViewChild(SignaturePad) public signaturePad: SignaturePad;
  signatureImg: string;
  draw:number = 0
  attachment:File;
  fileName:string
  imageSrc:any;
  candidateId
  found
  constructor(
    private dialogRef: MatDialogRef<CreateSignComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public _formSteper:FormsSteperService, public authService:AuthServicesService,
     private alertService:NotificationService) {
    this.candidateId = this.authService.systemId('RRHH');
   }
  signaturePadOptions: Object = {
    'minWidth': 1,
    'canvasWidth': 500,
    'canvasHeight': 300
  };
  ngOnInit(): void {
    this.found = this.data.firmaData
    this.candidateId = this.data.rrhhId

  }
  optionSing(option){
    this.draw=option
  }
  clear(){
    this.signaturePad.clear();
  }
  savePad() {
    switch (this.draw) {
      case 1:
        const base64Data = this.signaturePad.toDataURL();
        this.signatureImg = base64Data;
        const url = this.signatureImg;
        fetch(url)
          .then(res => res.blob())
          .then(blob => {
            const file = new File([blob], "firmaDigital",{ type: "image/png" })
            this.attachment = file
            this.saveAtachment(this.attachment)
          })
        break;
      case 2:
        this.saveAtachment(this.attachment)
        break;

    }




  }
  saveAtachment(sign){
     this._formSteper.uploadDocumentCandidate(this.candidateId, sign, this.found.file_attachment_id,this.found.id).subscribe((resp: any) => {
      this.alertService.alertSuccess('¡Firma creada!', 'Tu firma entrará en proceso de verificación. Una vez verificada, podrás verla en el documento.');
      this.dialogRef.close(true);
    });
  }
  onFileSelected(event){
    this.draw=2
    this.fileName = event.target.files[0].name;
    this.attachment = event.target.files[0];
    if (event.target.files && event.target.files[0]) {
      const file = event.target.files[0];

      const reader = new FileReader();
      reader.onload = e => this.imageSrc = reader.result;

      reader.readAsDataURL(file);
  }

  }
  cancel(){
    this.draw=0
    this.imageSrc= null
  }
}
