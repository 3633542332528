import { Injectable } from '@angular/core';
import {Observable, of} from "rxjs";
import {Nomenclature} from "../pages/address-form/address-form.component";
// @ts-ignore
import * as data from '../../../assets/nomenclatures.json';

@Injectable({
  providedIn: 'root'
})
export class NomenclaturesService {


  constructor() { }

  getNomenclatures() : Observable<Nomenclature[]>{
    return of (data as any);
  }

}
