import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { ActivatedRoute } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class    KindAccountService {
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  };
  private server =  environment.RRHH;
  user;
  constructor(private http:HttpClient, 
    private activeRoute: ActivatedRoute, 
    ) {
   }


  getKindAccounts() {
    return this.http.get(`${this.server}kindAccounts/list`, this.httpOptions);
  }


}
