import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AuthServicesService } from 'src/app/auth/services/auth.service';
import { CreateSignComponent } from '../../components/sign/create-sign/create-sign.component';
import { FormsSteperService } from '../../services/rest/forms-steper.service';

@Component({
  selector: 'app-contract',
  templateUrl: './contract.component.html',
  styleUrls: ['./contract.component.sass']
})
export class ContractComponent implements OnInit {
  candidateId
  pdfSrc=null
   found
  accept = false
  attachments = ['contrato','acuerdo_confidencialidad','acuerdo_imagen','consentimiento'];
  sings = ['firma_contrato','firma_acuerdo_confidencialidad','firma_acuerdo_imagen','firma_consentimiento'];
  documentsToSign:any = [];
  singsToDo:any = [];
  foundSing:any = [];
  idx:any = [];
  firms:boolean = false;
  constructor(public dialog: MatDialog, public _formSteper:FormsSteperService, public authService:AuthServicesService) {
    this.candidateId = this.authService.systemId('RRHH');
  }

  ngOnInit() {
    this.getAttachmentsRrhh();
  }
   onTabChanged(event) {
    this.idx = event.index;
    
    this._formSteper.downdloadFile( this.documentsToSign[this.idx].id,  this.candidateId).subscribe(file=>{
      let fileURL = URL.createObjectURL(file);
      this.pdfSrc = fileURL
    })
    this.foundSing = this.singsToDo.find(element => element.fileKey == `firma_${this.documentsToSign[this.idx].fileKey}`)
    this.accept = false
  }

  createSign(){
    const registerDialog = this.dialog.open( CreateSignComponent, {
      data: {firmaData: this.foundSing, rrhhId:this.candidateId}
    });

    registerDialog.afterClosed().subscribe( (refreshRequests) => {
      if(refreshRequests){
        this.firms = true;
        this.ngOnInit();
      }
    });
  }

  async getAttachmentsRrhh() {
    await this._formSteper.getAttachments(this.candidateId).subscribe( (resp: any) => {
      this.documentsToSign = resp.filter( (elem:any) => {
        return this.attachments.indexOf(elem.fileKey) > -1;
      })
      this.singsToDo = resp.filter( (elem:any) => {
        return this.sings.indexOf(elem.fileKey) > -1;
      })
      if (this.firms != false) {
          this.onTabChanged({
          index: this.idx
        });
      }
    });
  }
}
