import { HttpParams } from '@angular/common/http';
import { Component, OnInit , Input,  Output, EventEmitter, AfterViewInit} from '@angular/core';
import { FormBuilder, FormGroup,  AbstractControl, ValidatorFn, FormArray, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import { AuthServicesService } from 'src/app/auth/services/auth.service';
import { FormsSteperService } from 'src/app/candidate/services/rest/forms-steper.service';
import { map, startWith, filter } from 'rxjs/operators';
import { KindAccountService } from 'src/app/candidate/services/rest/kindAccount.service';
import {MatDialog} from "@angular/material/dialog";
import {AddressFormComponent} from "../../../pages/address-form/address-form.component";
//import { KindAccountService } from '../../services/rest/kindAccount.service';
@Component({
  selector: 'candidate-first-step',
  templateUrl: './first-step.component.html',
  styleUrls: ['./first-step.component.sass']
})
export class FirstStepComponent implements OnInit{
  @Input() form:FormGroup;
  @Input() age;
  @Output() newItemEvent = new EventEmitter<string>();
  @Output() epsEmmit = new EventEmitter<JSON>();
  @Output() pensionesEmmit = new EventEmitter<JSON>();
  @Output() bancoEmmit = new EventEmitter<JSON>();


  filteredCityOpt: Observable<any[]>;
  filteredCountyOpt: Observable<any[]>;
  filteredLocalityOpt: Observable<any[]>;
  filteredDeparmentOpt: Observable<any[]>;
  filteredNeighborhoodOpt:Observable<any[]>;
  now = new Date().toISOString().split('T')[0];
  militarySituation : boolean = false
  haveCar : boolean = false
  candidateId;
  carChildren=['vehicle_type', 'licence_number', 'licence_type']
  militaryChildren=['notebook_number', 'notebook_class', 'notebook_number']
  children : FormArray
  countries:any[]
  city:any[]
  locality:any[]
  neighborhood:any[]
  validationType;
  data;
  filterd;

  infoEps: any;
  infoCajaPensiones: any;
  kindAccountList;
  bankData;
  currentEps;
  currentPens;
  currentBank;
  //Otro género y nombre identitario
  show_other_field:boolean
  show_identity_name_field:boolean


  constructor(
    private _fb: FormBuilder,
    private authService:AuthServicesService,
    private _SteperService:FormsSteperService,
    private kindAccountService :KindAccountService,
    public dialog: MatDialog,
  ) {
    this.candidateId = this.authService.systemId('RRHH');

    if ( this.candidateId == null ) {
      this.candidateId = this.authService.systemId('NOMINA');
    }
   }

   ngAfterViewInit(): void{
    setTimeout(() => {
     this.filteredCityOpt.subscribe(res=>{this.filterd = res.map(x => { return x.name; })});

      this.validationType = {
        'city_of_residence': [this.cityOfResidenceValidator()],
    }
      //this.form.get('city_of_residence').setValidators(this.validationType['city_of_residence']);
        }, 2000);
  }
  ngOnInit(): void {

    this.children = this.form.get('children') as FormArray;
    const entries = {};
    entries['userId'] = this.candidateId;
    entries['data'] = 'personal_data';
    entries['flag'] = this.authService.systemKey();
    const params = new HttpParams( { fromObject: entries } );
     this._SteperService.getInfo(`candidate/json/data/${this.candidateId}`, params).subscribe( (res) => {
      console.log(res)
       if((Object.keys(res).length != 0)){
         res.bank_account = res.bank_account.replace(/[^a-zA-Z0-9 ]/g, '');
         this.data = res;
         this.form.patchValue(res);
        if(res.city_document_id)this.form.controls.city_document_id.disable()
        if(res.expedition_date_id)this.form.controls.expedition_date_id.disable()
        if(res.nationality)this.form.controls.nationality.disable()
        if(res.blood_type)this.form.controls.blood_type.disable()
        if(res.rh)this.form.controls.rh.disable()

        if(res?.caja_de_pensiones?.name || res?.eps?.name || res?.banco?.name)
       {
        this.form.patchValue({
          eps: res?.eps?.name,
          caja_de_pensiones: res?.caja_de_pensiones?.name,
          banco: res?.banco?.name,
          kind_account: res?.kind_account?.name
        });
       }


        this.getSites('countries', 'filteredCountyOpt', 'place_of_birth', null, res.place_of_birth )
        this.getSites('department', 'filteredDeparmentOpt', 'deparment_of_residence', 1, res.deparment_of_residence)
        if(res.city_of_residence_id && res.location_id && res.neighborhood_id)
        {
          this.getSites('city', 'filteredCityOpt', 'city_of_residence', res.city_of_residence_id, res.city_of_residence)
          this.getSites('locality', 'filteredLocalityOpt', 'location', res.location_id, res.location)
          setTimeout(() => {
            this.form.get('neighborhood').setValue(res.neighborhood);
          }, 1500)
        }

       }else{
        this.getSites('countries', 'filteredCountyOpt', 'place_of_birth', null)
        this.getSites('department', 'filteredDeparmentOpt', 'deparment_of_residence', 1 )
       }
       if(!res.age || res.age =="" || res.age == null){
        this.form.get('age').setValue(this.age)
      }
     this.genderFields(res.gender)
    });

    this.getOptions('eps');
    this.getOptions('caja_de_pensiones');
    this.getOptions('banco');
    this.getKindAccounts();
  }
  getFilteredData(filtered, question, spot){
    this[filtered] = this.form.get( question).valueChanges
    .pipe(
      startWith(''),
      map(value => typeof value === 'string' ? value : value.name),
      map(name => name ? this._filter(name, spot) : this[spot].slice())
    );

  }
  private _filter(name: string, spot): any[] {
    const filterValue = name.toLowerCase();
    return this[spot].filter(option => option.name.toLowerCase().indexOf(filterValue) === 0);
  }
  getSites(where, filtered, question, id?, data?){
    let direction
    direction = id? `${ where}/${id}`: where
    this._SteperService.getSpots(direction).subscribe(res=>{
      this[where] = res
      if(data && data !=='') this.form.get(question).setValue(data)
      this.getFilteredData(filtered, question, where)

    })
  }
  displayFn(currentQuestion, nextSpot, currentSpot, name): any {
    let filtered
    switch (nextSpot) {
      case 'locality':
        filtered= 'filteredLocalityOpt'
        break;
      case 'city':
        filtered= 'filteredCityOpt'
        break;
      case 'neighborhood':
        filtered= 'filteredNeighborhoodOpt'
        break;
      case 'department':
        filtered= 'filteredDeparmentOpt'
        break;
    }
    if (!name) return '';
    if(this[currentSpot]){
      let index = this[currentSpot].findIndex(state => state.name === name);
      if (nextSpot && nextSpot !=='null')this.getSites(nextSpot,filtered, currentQuestion, this[currentSpot][index].id)
      return this[currentSpot][index].name;
    }

  }
  checkDependence(event, conditional, element: string, children:string[]){
    if(event == conditional){
      this[element]= true
    }
    else{
      this[element]= false
      children.forEach(element => {
        this.form.get(element).setValue('')
      });
    }
  }
  getChildrensInputs(){
    this.children.controls =[]
    if(typeof this.form.get('children_number').value == 'number' && this.form.get('children_number').value <20){
      for (let index = 0; index < this.form.get('children_number').value; index++) {
        this.children.push(this._fb.group({
            name: this.data?.children[index]?.name != '' || null ? this.data?.children[index]?.name : '',
            age: this.data?.children[index]?.age != '' || null ? this.data?.children[index]?.age : '',
          }))
      }
    }
  }
  changeGender(event){
    switch (event) {
      case 'Femenino':
        this.form.get('military_situation').setValue('No aplica')
        break;
      case 'Masculino':
        if(this.form.get('military_situation').value == "No aplica"){
          this.form.get('military_situation').setValue('')
        }
        break;
    }

  }

  cityOfResidenceValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      // below findIndex will check if control.value is equal to one of our options or not
      if (this.filterd.indexOf(control.value) !== -1) {
        return null  /* valid option selected */
      }
      return { 'invalidAutocompleteString': { value: control.value } }
    };
  }

  /** Retornar datos del formulario
   *@author Lina Hueso
  */
  async getOptions(key) {
    await this._SteperService.getOptions(key).subscribe(resp => {
      if (key == 'eps') {
        this.infoEps = resp;
      }
      if (key == 'caja_de_pensiones') {
        this.infoCajaPensiones = resp;
      }
      if (key == 'banco') {
        this.bankData = resp;
      }
    }
    );
  }

  /**
  * Metodo el cual lista los tipos de cuentas bancarias
  * @author Carlos Nieto
  * @return json
  */
  getKindAccounts() {
    this.kindAccountService.getKindAccounts().subscribe(resp => {
      this.kindAccountList = resp;
    });
  }

  /**
     * Metodo que valida el campo de eps para neviar el json completo
     * @author Carlos Nieto
     * @date 19-07-2022
     * @return json
     */
  epsChange(value)
   {
     /*this.currentEps = this.infoEps.find(element => element.name = value);
     this.epsEmmit.emit(this.currentEps);*/
   }

   /**
      * Metodo que valida el campo de pensiones para neviar el json completo
      * @author Carlos Nieto
      * @date 19-07-2022
      * @return json
      */
    pensionesChange(value)
   {
    /* this.currentPens = this.infoCajaPensiones.find(element => element.name = value);
     this.pensionesEmmit.emit(this.currentPens);*/
   }

     /**
     * Metodo que valida el campo de pensiones para neviar el json completo
     * @author Carlos Nieto
     * @date 19-07-2022
     * @return json
     */
      bancoChange(value)
      {
          this.currentBank = this.bankData;
          this.bancoEmmit.emit(this.currentBank);
      }

      /**
       * validacion de campos numericos
       */
       keyPress(event: KeyboardEvent) {
        const pattern = /[0-9]/;
        const inputChar = String.fromCharCode(event.charCode);
        if (!pattern.test(inputChar)) {
            // invalid character, prevent input
            event.preventDefault();
        }
    }

  showAddressForm() {
    const dialogRef = this.dialog.open(AddressFormComponent, {
      width: '100%',
      data: {address: this.form.controls.adress.value ?? null},
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result != null) {
        this.form.controls.adress.setValue(result);
      }
    });
  }

   /**
   * Método que define la visualización de los campos para ingresar un genero personalizado y un nombre identitario
   * @author Juan Carlos Alonso
   * @date 09-09-2024
   */
   genderFields(value: string): void {
    this.show_identity_name_field = (value === 'Otro' || value === 'Transgénero' || value === 'No binario') ? true : false

    if(value === 'Otro'){
      this.show_other_field = true
      this.form.controls.other_gender.setValidators([Validators.required])
    }else{
      this.show_other_field = false
      this.form.controls.other_gender.setValue('')
      this.form.controls.other_gender.setValidators([])
    }
    this.form.controls.other_gender.updateValueAndValidity()
  }
}
