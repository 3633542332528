<br>
<form fxLayout="row wrap" fxLayout.xs="column" fxLayoutGap="35px" [formGroup]="form">

    <mat-form-field appearance="fill" fxFlex=30>
        <mat-label>Nombre Empresa</mat-label>
        <input matInput formControlName="company_name"required>
        <mat-error *ngIf="form.hasError('required', 'company_name')">
            <span>¡Advertencia! Este es requerido </span>
        </mat-error>
        <mat-error *ngIf="form.hasError('maxlength', 'company_name')">
            <span>¡Advertencia! Este recibe maximo 20 caracteres </span>
        </mat-error>
    </mat-form-field>

    <mat-form-field appearance="fill" fxFlex=30>
        <mat-label>Teléfono Empresa</mat-label>
        <input type="number" matInput formControlName="company_phone">
    </mat-form-field>

    <mat-form-field appearance="fill" fxFlex=30>
        <mat-label>Cargo Desempeñado</mat-label>
        <input matInput formControlName="position_hired" required>
        <mat-error *ngIf="form.hasError('required', 'position_hired')">
            <span>¡Advertencia! Este es requerido </span>
        </mat-error>
        <mat-error *ngIf="form.hasError('maxlength', 'position_hired')">
            <span>¡Advertencia! Este recibe maximo 20 caracteres </span>
        </mat-error>
    </mat-form-field>

    <mat-form-field appearance="fill" fxFlex=30>
        <mat-label>Ciudad</mat-label>
        <input matInput formControlName="company_city">
        <mat-error *ngIf="form.hasError('maxlength', 'company_city')">
            <span>¡Advertencia! Este recibe maximo 20 caracteres </span>
        </mat-error>
    </mat-form-field>

    <mat-form-field appearance="fill" fxFlex=30>
        <mat-label>Nombre del Jefe</mat-label>
        <input matInput formControlName="boss_name">
        <mat-error *ngIf="form.hasError('maxlength', 'boss_name')">
            <span>¡Advertencia! Este recibe maximo 40 caracteres </span>
        </mat-error>
    </mat-form-field>

    <mat-form-field appearance="fill" fxFlex=30>
        <mat-label>Cargo del Jefe</mat-label>
        <input matInput formControlName="boss_position">
        <mat-error *ngIf="form.hasError('maxlength', 'boss_position')">
            <span>¡Advertencia! Este recibe maximo 40 caracteres </span>
        </mat-error>
    </mat-form-field>

    <mat-form-field appearance="fill" fxFlex=30>
        <mat-label>Teléfono movil del Jefe</mat-label>
        <input type="number" matInput formControlName="boss_mobile_phone">
    </mat-form-field>

    <mat-form-field appearance="fill" fxFlex=30>
        <mat-label>Teléfono del Jefe</mat-label>
        <input type="number" matInput formControlName="boss_phone">
    </mat-form-field>

    <mat-form-field appearance="fill" fxFlex=30>
        <mat-label>Trabajo Actual</mat-label>
        <mat-select matNativeControl formControlName="current_job" required>
            <mat-option value="Si">Si</mat-option>
            <mat-option value="No">No</mat-option>
        </mat-select>
        <mat-error *ngIf="form.hasError('required', 'current_job')">
            <span>¡Advertencia! Este es requerido </span>
        </mat-error>
        <mat-error *ngIf="form.hasError('maxlength', 'current_job')">
            <span>¡Advertencia! Este recibe maximo 20 caracteres </span>
        </mat-error>
    </mat-form-field>

    <mat-form-field appearance="fill" fxFlex=30>
        <mat-label>Fecha de inicio</mat-label>
        <input matInput [matDatepicker]="picker2" formControlName="start_date" [max]="now">
        <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
        <mat-datepicker #picker2></mat-datepicker>
    </mat-form-field>

    <mat-form-field appearance="fill" fxFlex=30>
        <mat-label>Fecha de finalización</mat-label>
        <input matInput [matDatepicker]="picker3" formControlName="end_date" [max]="now">
        <mat-datepicker-toggle matSuffix [for]="picker3"></mat-datepicker-toggle>
        <mat-datepicker #picker3></mat-datepicker>
    </mat-form-field>

    <mat-form-field appearance="fill" fxFlex=30>
        <mat-label>Salario Devengado</mat-label>
        <mat-select matNativeControl formControlName="earned_salary">
            <mat-option value="1 a 2 Salarios mínimos mensuales">1 a 2 Salarios mínimos mensuales</mat-option>
            <mat-option value="2 a 3 Salarios mínimos mensuales">2 a 3 Salarios mínimos mensuales</mat-option>
            <mat-option value="3 a 4 Salarios mínimos mensuales">3 a 4 Salarios mínimos mensuales</mat-option>
            <mat-option value="4 a 5 Salarios mínimos mensuales">4 a 5 Salarios mínimos mensuales</mat-option>
            <mat-option value="5 a 6 Salarios mínimos mensuales">5 a 6 Salarios mínimos mensuales</mat-option>
            <mat-option value="6 a 7 Salarios mínimos mensuales">6 a 7 Salarios mínimos mensuales</mat-option>
            <mat-option value="7 a 8 Salarios mínimos mensuales">7 a 8 Salarios mínimos mensuales</mat-option>
            <mat-option value="8 a 9 Salarios mínimos mensuales">8 a 9 Salarios mínimos mensuales</mat-option>
            <mat-option value="9 a 10 Salarios mínimos mensuales">9 a 10 Salarios mínimos mensuales</mat-option>
            <mat-option value="10 a 11 Salarios mínimos mensuales">10 a 11 Salarios mínimos mensuales</mat-option>
            <mat-option value="11 a 12 Salarios mínimos mensuales">11 a 12 Salarios mínimos mensuales</mat-option>
            <mat-option value="12 a 13 Salarios mínimos mensuales">12 a 13 Salarios mínimos mensuales</mat-option>
            <mat-option value="13 a 14 Salarios mínimos mensuales">13 a 14 Salarios mínimos mensuales</mat-option>
            <mat-option value="14 o más Salarios mínimos mensuales">14 o más Salarios mínimos mensuales</mat-option>
        </mat-select>
    </mat-form-field>

    <mat-form-field appearance="fill" fxFlex=30>
        <mat-label>Motivo del retiro</mat-label>
        <mat-select matNativeControl formControlName="retirement_reason">
            <mat-option value="Terminación del contrato">Terminación del contrato</mat-option>
            <mat-option value="Renuncia voluntaria" selected>Renuncia voluntaria</mat-option>
            <mat-option value="Terminación del contrato con justa causa" selected>Terminación del contrato con justa causa</mat-option>
            <mat-option value="Terminación del contrato sin justa causa" selected>Terminación del contrato sin justa causa</mat-option>
            <mat-option value="Retiro no negativo" selected>Retiro no negativo</mat-option>
            <mat-option value="Otro" selected>Otro</mat-option>
        </mat-select>
    </mat-form-field>

    <mat-form-field appearance="fill" fxFlex=30>
        <mat-label>Funciones y logros</mat-label>
        <textarea matInput formControlName="functions_achievements"></textarea>
    </mat-form-field>

    <div fxFlex=30 fxLayoutAlign="center center">
        <button mat-stroked-button color="accent" (click)="_actions.add(dataSourceWorkExperiences,'jobs_references', workExperiencies, form, candidateId)" [disabled]="!form.valid">
        Agregar experiencia</button>
    </div>
    <hr fxFlex=100>
    <div fxFlex=100 class="table_data">
        <table mat-table [dataSource]="dataSourceWorkExperiences">

            <ng-container matColumnDef="company_name">
                <th mat-header-cell *matHeaderCellDef class="start"> Empresa </th>
                <td mat-cell *matCellDef="let element"> {{element.company_name}} </td>
            </ng-container>

            <ng-container matColumnDef="position_hired">
                <th mat-header-cell *matHeaderCellDef> Cargo </th>
                <td mat-cell *matCellDef="let element"> {{element.position_hired}} </td>
            </ng-container>

            <ng-container matColumnDef="boss_name">
                <th mat-header-cell *matHeaderCellDef> Jefe </th>
                <td mat-cell *matCellDef="let element"> {{element.boss_name}} </td>
            </ng-container>

            <ng-container matColumnDef="boss_mobile_phone">
                <th mat-header-cell *matHeaderCellDef> Celular </th>
                <td mat-cell *matCellDef="let element"> {{element.boss_mobile_phone}} </td>
            </ng-container>

            <ng-container matColumnDef="start_date">
                <th mat-header-cell *matHeaderCellDef> Fecha Inicio </th>
                <td mat-cell *matCellDef="let element"> {{element.start_date | date}} </td>
            </ng-container>

            <ng-container matColumnDef="end_date">
                <th mat-header-cell *matHeaderCellDef> Fecha Final </th>
                <td mat-cell *matCellDef="let element"> {{element.end_date | date}} </td>
            </ng-container>

            <ng-container matColumnDef="actions">
                <th mat-header-cell *matHeaderCellDef class="end"> Acciones </th>
                <td mat-cell *matCellDef="let element">
                    <button mat-icon-button (click)="_actions.edit(element, workExperiencies, form)"><span class="fi-rr-edit"></span></button>
                    <button mat-icon-button (click)="_actions.delete(dataSourceWorkExperiences, workExperiencies, element, 'jobs_references')"><span class="fi-rr-trash"></span></button>
                </td>

            </ng-container>

            <tr class="mat-row" *matNoDataRow>
                <td class="mat-cell" colspan="7">Aún no se agrega experiencia laboral</td>
            </tr>
            <tr mat-header-row *matHeaderRowDef="displayedColumnsWorkExperiencies"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumnsWorkExperiencies;"></tr>
        </table>
    </div>
</form>