
<br>
<form fxLayout="row wrap" fxLayout.xs="column" fxLayoutGap="35px" [formGroup]="form">
    <mat-form-field appearance="fill" fxFlex=30>
        <mat-label>Estudia Actualmente</mat-label>
        <mat-select matNativeControl formControlName="currently_studying">
            <mat-option value="Si">Si</mat-option>
            <mat-option value="No">No</mat-option>
        </mat-select>
    </mat-form-field>

    <mat-form-field appearance="fill" fxFlex=30>
        <mat-label>Nivel de Estudios</mat-label>
        <mat-select matNativeControl formControlName="education_level">
            <mat-option value="Primaria">Primaria</mat-option>
            <mat-option value="Bachillerato">Bachillerato</mat-option>
            <mat-option value="Técnico">Técnico</mat-option>
            <mat-option value="Tecnológico">Tecnológico</mat-option>
            <mat-option value="Universitario">Universitario</mat-option>
            <mat-option value="Especialización">Especialización</mat-option>
            <mat-option value="Magister">Magister</mat-option>
            <mat-option value="PHD">PHD</mat-option>
            <mat-option value="Doctorado">Doctorado</mat-option>
            <mat-option value="Diplomado">Diplomado</mat-option>
            <mat-option value="Sena">Sena</mat-option>
            <mat-option value="Otro">Otro</mat-option>
        </mat-select>
    </mat-form-field>

    <mat-form-field appearance="fill" fxFlex=30>
        <mat-label>Institución</mat-label>
        <input matInput formControlName="institution" required>
        <mat-error *ngIf="form.hasError('required', 'institution')">
            <span>¡Advertencia! Este campo es requerido</span>
        </mat-error>
    </mat-form-field>

    <mat-form-field appearance="fill" fxFlex=30>
        <mat-label>Título Obtenido</mat-label>
        <input matInput formControlName="obtained_title">
    </mat-form-field>

    <mat-form-field appearance="fill" fxFlex=30>
        <mat-label>Ciudad de Estudio</mat-label>
        <input matInput formControlName="study_city">
    </mat-form-field>

    <mat-form-field appearance="fill" fxFlex=30>
        <mat-label>Fecha de Finalización</mat-label>
        <input matInput [matDatepicker]="picker4" formControlName="finish_date" [max]="now">
        <mat-datepicker-toggle matSuffix [for]="picker4"></mat-datepicker-toggle>
        <mat-datepicker #picker4></mat-datepicker>
    </mat-form-field>

    <mat-form-field appearance="fill" fxFlex=30>
        <mat-label>Periodos Cursados</mat-label>
        <input matInput formControlName="periods_completed">
    </mat-form-field>

    <div fxFlex=30 fxFlex=30 fxLayoutAlign="center center">
        <button mat-stroked-button color="accent" (click)="_actions.add(dataSourceStudies, 'education',this.studies, form, candidateId)" [disabled]="!form.valid">Agregar estudio</button>
    </div>
    <hr fxFlex=100>
    <div fxFlex=100 class="table_data">
        <table mat-table [dataSource]="dataSourceStudies">
            <ng-container matColumnDef="education_level">
                <th mat-header-cell *matHeaderCellDef class="start"> Nivel </th>
                <td mat-cell *matCellDef="let element"> {{element.education_level}} </td>
            </ng-container>

            <ng-container matColumnDef="institution">
                <th mat-header-cell *matHeaderCellDef> Institución </th>
                <td mat-cell *matCellDef="let element"> {{element.institution}} </td>
            </ng-container>

            <ng-container matColumnDef="obtained_title">
                <th mat-header-cell *matHeaderCellDef> Título </th>
                <td mat-cell *matCellDef="let element"> {{element.obtained_title}} </td>
            </ng-container>

            <ng-container matColumnDef="study_city">
                <th mat-header-cell *matHeaderCellDef> Ciudad </th>
                <td mat-cell *matCellDef="let element"> {{element.study_city}} </td>
            </ng-container>

            <ng-container matColumnDef="finish_date">
                <th mat-header-cell *matHeaderCellDef> Fecha Fianlización </th>
                <td mat-cell *matCellDef="let element"> {{element.finish_date | date}} </td>
            </ng-container>

            <ng-container matColumnDef="periods_completed">
                <th mat-header-cell *matHeaderCellDef> Periodos Cursados </th>
                <td mat-cell *matCellDef="let element"> {{element.periods_completed}} </td>
            </ng-container>

            <ng-container matColumnDef="actions">
                <th mat-header-cell *matHeaderCellDef class="end"> Acciones </th>
                <td mat-cell *matCellDef="let element">
                    <button mat-icon-button (click)="_actions.edit(element, studies, form)"><span class="fi-rr-edit"></span></button>
                    <button mat-icon-button (click)="_actions.delete(dataSourceStudies, studies, element, 'education')" type="button">
                    <span class="fi-rr-trash"></span>
                    </button>
                </td>
            </ng-container>
            <tr class="mat-row" *matNoDataRow>
                <td class="mat-cell" colspan="7">Aún no se agregan estudios</td>
            </tr>
            <tr mat-header-row *matHeaderRowDef="displayedColumnsStudies"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumnsStudies;"></tr>
        </table>
    </div>

</form>