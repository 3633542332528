import { RequestPasswordTokenComponent } from './../request-password-token/request-password-token.component';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators, FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthServicesService } from '../services/auth.service';
import { MatDialog } from '@angular/material/dialog';
import { AuthService } from 'src/app/core/services/auth.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.sass']
})

export class LoginComponent implements OnInit {

  loginForm:FormGroup;
  hide: boolean = true;
  anio: number;
  publicIP: any;

  constructor(private fb: FormBuilder,private authService:AuthServicesService,private router:Router,private AuthService:AuthService,
    public dialog: MatDialog
    ) {
    if(this.authService.getUser()){
      this.router.navigateByUrl('/cos/candidates/personal-information');
    }
   }

  get error(): any { return this.loginForm.controls; }

  ngOnInit(): void {
    this.loginForm = this.fb.group({
      username:['',[Validators.required, Validators.maxLength(50), Validators.minLength(3)]],
      password:['',[Validators.required, Validators.maxLength(15), Validators.minLength(5)]]
   });
  }

  loginAuth(): any{
      if(this.loginForm.valid){
          this.authService.login(this.loginForm.value).subscribe( (resp:any) =>{
            console.log('reslogin', resp);
            this.authService.setStorageUser(resp);
            this.router.navigateByUrl('/cos');
          });
          this.AuthService.GetloginData(this.loginForm.value).subscribe(res=>{
            localStorage.setItem('userCiu',JSON.stringify(res.data));
          });
      }
  }

  omitSpecialChar(event): any{
    let k;
    k = event.charCode;
    return((k > 32 && k < 126));
   }

   /**
	 * @author Carlos Nieto
	 * @createdate 2022-02-24
	 * Metodo que abre el modal del componente de forgot-password
	 */
 forgotPassword(): any {
    const forgotPassword = this.dialog.open(RequestPasswordTokenComponent, {
      width: '40%',
      height: '88%',
      data: {
        type: 'cambiar',
        title: '¿Tienes problemas para iniciar sesión?',
      },
      panelClass: 'custom-dialog-container'
    });

    forgotPassword.afterClosed().subscribe(()=>{
      forgotPassword.close();
    });
  }
}
