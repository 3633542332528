import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthServicesService } from 'src/app/auth/services/auth.service';
import { environment } from 'src/environments/environment';
const { RRHH } = environment;

@Injectable({
  providedIn: 'root'
})
export class FormsSteperService {

  constructor( private authServie:AuthServicesService,private http:HttpClient ) { }

  getInfo(direction, params?){
    return this.http.get<any>(`${RRHH}${direction}`, {params});
  }
  postInfo(direction, data){
    return this.http.post<any>(`${RRHH}${direction}`, data);

  }
  deleteInfo(direction){

  }
  updateInfo(direction){

  }
  getSpots(direction){
    return this.http.get<any>(`${API_AUTH}/${direction}`);
  }
  uploadFiles(direction, entries: File, position) {
    entries['position'] = position;
    const formData = new FormData();
    formData.append('documents', entries);
    formData.append('position', position);
    return this.http.post(`${RRHH}${direction}`, formData);
  }
  deleteFiles(){

  }
  getAttachments(candidateId){
    const entries = {};
    //entries['userId'] = this.user.rrhh_id;
    const params = new HttpParams( { fromObject: entries } );
    return this.http.get(`${RRHH}candidate/attachments/${candidateId}`, {params});
  }
  uploadDocumentCandidate(candidateId, entries: File, documentTypeId,attachmentId): any {
    const formData = new FormData();
    formData.append('documents', entries);
    formData.append('id', attachmentId );
    formData.append('file_attachment_id', documentTypeId);
    return this.http.post(`${RRHH}candidate/document/upload/${candidateId}`, formData);
  }

  downdloadFile(fileId, id){
    return this.http.get(`${RRHH}candidate/download/attachment/${id}/${fileId}`, {responseType:'blob'});
  }

  deleteFile(fileId){
    return this.http.delete(`${RRHH}candidate/delete/attachment/${fileId}`);
  }

  /** Metodo que retorna los tipos de documentos
   * @author Lina Hueso
    */
  getIdTypes(){
    return this.http.get(`${RRHH}idtypes`);
  }

  /** Retornar informacion del formulario
   * @author Lina Hueso
    */
   getOptions(question){
    return this.http.get(`${RRHH}dropdown/options/${question}`);
  }

  /**
   * Genera los consentimientos del candidato en contratación
   * @param entries 
   * @returns 
   */
  generateDocuments(entries){
    const params = new HttpParams( { fromObject: entries } );
    return this.http.post(`${RRHH}candidate/generate/document`, params);

  }
}
const API_AUTH = environment.api_auth;
