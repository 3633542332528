import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {NomenclaturesService} from "../../services/nomenclatures.service";

export interface Nomenclature {
  name: string;
  code: string;
}

@Component({
  selector: 'app-address-form',
  templateUrl: './address-form.component.html',
  styleUrls: ['./address-form.component.sass']
})
export class AddressFormComponent implements OnInit {

  currentAddress : string;
  selectedAddress: string;
  public myForm: FormGroup;
  partOneOptions: Nomenclature[];
  hasNameSelected: number;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private nomenclatureService: NomenclaturesService,
    public dialogRef: MatDialogRef<AddressFormComponent>,
  ) {
    this.currentAddress = data.address;
  }

  ngOnInit(): void {
    this.buildForm();

    this.nomenclatureService.getNomenclatures().subscribe((resp:any)=> {
      this.partOneOptions = resp.default;
    });

    this.myForm.get('hasName').valueChanges.subscribe(resp => {
      this.hasNameSelected = resp;
      this.changeValidations(this.hasNameSelected);
    });
  }

  /**
   * Building address form
   * CL 52 A BIS SUR # 85 I BIS ESTE - 20
   * @author Gabriel Rodríguez
   */
  buildForm() {
    this.myForm = new FormGroup({
      hasName: new FormControl('', [Validators.required]), // Tiene nombre?
      partOne: new FormControl('', [Validators.required, Validators.pattern(/^[A-Z]+$/)]),  // NOM                       // CL
      partTwo: new FormControl('', []), // 26, EL DORADO
      partThree: new FormControl('', [Validators.pattern(/^[A-Z]+$/)]),      // A
      partFour: new FormControl(),     // BIS
      partFive: new FormControl( '', [Validators.pattern(/^[A-Z]+$/)]),     // SUR
      partSix: new FormControl('', [Validators.pattern('^[0-9]*$')]),       // 85
      partSeven: new FormControl( '', [Validators.pattern(/^[A-Z]+$/)]),    // I
      partEight: new FormControl(),    // BIS
      partNine: new FormControl('', [Validators.pattern(/^[A-Z]+$/)]),      // SUR
      partTen: new FormControl('', [Validators.required, Validators.pattern('^[0-9]*$')]), // - 20
      // COMPLEMENT
      partEleven: new FormControl(),
      partTwelve: new FormControl('', Validators.pattern('^[0-9]*$')),
      partThirteen: new FormControl(),
    });
  }

  onSubmit() {
    this.preview();
    this.dialogRef.close(this.selectedAddress);
  }

  get error(): any { return this.myForm.controls; }

  onCancel() {
    this.dialogRef.close();
  }

  changeValidations(hasName) {
    this.myForm.get('partTwo').clearValidators()
    if (hasName) {
      this.myForm.get('partTwo').setValidators([Validators.pattern(/^[A-Z\s]+$/), Validators.required]);
    } else {
      this.myForm.get('partTwo').setValidators([Validators.pattern('^[0-9]*$'), Validators.required]);
    }
  }

  preview() {

    let obj = this.myForm.value;

    const keys = [
      "partOne",
      "partTwo",
      "partThree",
      "partFour",
      "partFive",
      "partSix",
      "partSeven",
      "partEight",
      "partNine",
      "partTen",
      "partEleven",
      "partTwelve",
      "partThirteen"
    ];

    let result = "";
    for (const key of keys) {
      let value = obj[key];

      if (value !== null && value !== undefined && value !== false) {
        if(value == true) { result += ' ' + 'BIS'; } else { result += ' ' + value; }
      }
    }

    this.selectedAddress = result;
  }
}
