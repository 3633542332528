import { NgModule } from '@angular/core';
import { CommonModule, CurrencyPipe, DecimalPipe,  registerLocaleData } from '@angular/common';
import { FilesViewComponent } from './files-view/files-view.component';
import { MaterialModule } from '../core/material/material.module';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { BasicSnackbarComponent } from './basic-snackbar/basic-snackbar.component';

@NgModule({
  declarations: [
    FilesViewComponent,
    BasicSnackbarComponent
  ],
  imports: [
    CommonModule,
    MaterialModule,
    PdfViewerModule
  ],
  exports: [
    FilesViewComponent,
    BasicSnackbarComponent,
    CommonModule
  ],

  providers: [ CurrencyPipe, DecimalPipe],
})

export class SharedModule { }
