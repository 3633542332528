import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './auth/login/login.component';
import { AuthGuard } from './core/guards/auth.guard';
import { ResignationSurveyComponent } from './employee/pages/resignation-survey/resignation-survey.component';
import { ExternalSurveyComponent } from './employee/pages/external-survey/external-survey.component';


const routes: Routes = [
  { path: '', redirectTo: 'auth', pathMatch: 'prefix'},
  { path: 'resignation-survey', component: ResignationSurveyComponent },
  { path: 'external-survey', component: ExternalSurveyComponent },
  { path: 'auth', loadChildren: () => import('./auth/auth.module').then(m => m.AuthModule)},
  // { path: '**', pathMatch: 'full', redirectTo: '/auth/login' },
  { path: 'cos', loadChildren: () => import('./core/core.module').then(m => m.CoreModule),
  canActivate: [AuthGuard ], canActivateChild: [AuthGuard ]},
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {

}
