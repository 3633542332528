import { Injectable } from '@angular/core';
import Swal from 'sweetalert2';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  constructor() { }
  alertSuccess(title: string, text: string){

    Swal.fire({
      title,
      text,
      icon: 'success',
      confirmButtonText: 'Aceptar'
    });

  }

  alertError(title: string, text: string){

    Swal.fire({
      title,
      text,
      icon: 'error',
      confirmButtonText: 'Aceptar'
    });

  }

  alertWarning(title: string, text: string){

    Swal.fire({
      title,
      text,
      icon: 'warning',
      confirmButtonText: 'Aceptar'
    });

  }

  alertInfo(title: string, text: string){

    Swal.fire({
      title,
      text,
      icon: 'info',
      confirmButtonText: 'Aceptar'
    });
  }

  alertConfirm(title: string, text?: string){

  return Swal.fire({
      title,
      text,
      showDenyButton: true,
      showCancelButton: false,
      confirmButtonText: `Si`,
      denyButtonText: `No`,
    });
  }
}
