import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { FormArray } from '@angular/forms';
import { QuestionServiceService } from 'src/app/employee/services/question-service.service';
import { AlertsService } from 'src/app/shared/alerts/alerts.service';


@Component({
  selector: 'app-form',
  templateUrl: './form.component.html',
  styleUrls: ['./form.component.scss'],
})
export class FormComponent implements OnInit {

  forms = new FormArray([]);
  questions: any;

  @Input()
	public set formInfo(form: any) {

    const group = this.qcs.toFormGroup(form);
    this.forms.push(group);
    this.questions = form;
	}

  @Output() submitForm = new EventEmitter<any>();

  constructor(
    private alertsService: AlertsService,
    private qcs: QuestionServiceService
  ) {  }

  ngOnInit() {
    this.forms.reset();
  }

  /**
   * @author Miguel Restrepo
   * @createdate 2022-06-02
   * Método que guarda los datos del formulario y los envía al formulario general
   */
  onSubmit() {
    this.alertsService.alertConfirm('¿Desea guardar el registro?')
    .then(res => {
      if(res.isConfirmed) {
        this.submitForm.emit(this.forms.getRawValue());
        this.forms.reset();
      }
    })
		
	}
}
