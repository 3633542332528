import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthServicesService } from '../services/auth.service';

@Component({
  selector: 'app-logout',
  templateUrl: './logout.component.html',
  styleUrls: ['./logout.component.sass']
})
export class LogoutComponent implements OnInit {

  constructor(private router:Router,private auth:AuthServicesService) {
    this.auth.logout().subscribe( resp => {
      localStorage.removeItem('user');
      localStorage.removeItem('token');
      localStorage.removeItem('system');
      localStorage.removeItem('rrhhId');
      this.router.navigateByUrl('/auth/login');
    })
  }

  ngOnInit(): void {

  }

}
