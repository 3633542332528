
  <button style="float: right; margin:-15px; z-index: 10000;" mat-icon-button mat-dialog-close>
    <i class="fi-rr-cross"></i>
  </button>
 
<br>
  <mat-dialog-content>
    <div style=" margin: -20px;" *ngIf="viewer" >
      <pdf-viewer [src]="fileSrc" [render-text]="true" 
        style="display: block;">
      </pdf-viewer>
  </div>
  <div *ngIf="!viewer" style="text-align: center;">
    <img style="max-width: 76%;" [src]="this.fileSrc | userPhoto" alt=" No se encontró la imagen">
  </div>

  <div *ngIf="data.print" style="text-align: center; position: relative">
    <button mat-raised-button color="primary" style="width: 50%; position: absolute; bottom: 50px; right: 20px;" (click)="download()" class="download">Descargar
      <span class="fi-rr-download"></span>
    </button>
  </div>
  </mat-dialog-content>

