import { Component, OnInit } from '@angular/core';
import { AuthServicesService } from 'src/app/auth/services/auth.service';
import { NotificationService } from 'src/app/core/services/notification.service';
import { FormsSteperService } from '../../services/rest/forms-steper.service';
import { TestService } from '../../services/rest/test.service';
import { saveAs as importedSaveAs } from "file-saver";

@Component({
  selector: 'app-tests',
  templateUrl: './tests.component.html',
  styleUrls: ['./tests.component.sass']
})
export class TestsComponent implements OnInit {
  candidate;
  candidateId;
  flag= this.authService.systemKey();
  constructor(private _SteperService:FormsSteperService,private authService:AuthServicesService,
    private alertNotification:NotificationService,private testService:TestService) {
    this.candidateId = this.authService.systemId('RRHH');
    if ( this.candidateId == null ) {
      this.candidateId = this.authService.systemId('NOMINA');
    }
  }

  ngOnInit(): void {
    this._SteperService.getInfo(`candidate/data/autogestion/${this.candidateId}/${this.flag}`).subscribe( res => {
      this.candidate= res
    } )
  }



  /**
   *
   * @author Carlos Galindez
   */

   testFiles = [];

   openFile (file, open){
    let direction
    if (open){
      direction=file.test.key
    } else{
      direction =`${file.id}/url`
    }
    this.testService.downdloadFile( direction).subscribe(res=>{
      importedSaveAs(res, `Prueba_${file.test.key}`);
    })
  }

  sendTest(test){
    this.alertNotification.alertConfirm('¿Estas seguro que desea enviar su prueba?').then(e => {
      if(e.isConfirmed){
        this.testService.sendTest(test.id, test.resources).subscribe( e => {
          this.alertNotification.alertSuccess('¡Hecho!', 'Prueba enviada con exito');
        });
      }
    });
  }

  onFileSelectedTest(files,test) {
    test.resources.candidateAttachment = files[0];
    test.resources.candidateAttachmentName = files[0].name;
  }

  finishTests(){
    this.alertNotification.alertConfirm('¿Estas seguro que quieres finalizar tus pruebas?').then( e => {
      if(e.isConfirmed){
        this.testService.finishTest(this.candidate.id, this.candidate.requestId).subscribe( tesp => {
          this.alertNotification.alertSuccess('Gracias por completar tus pruebas', 'El líder de selección será notificado, pronto te estará contactando');
          this.ngOnInit();
        })
      }
    })
  }
}
