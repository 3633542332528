import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { JwtHelperService } from '@auth0/angular-jwt';

@Injectable({
  providedIn: 'root'
})
export class AuthServicesService {

  constructor(private http:HttpClient, public jwtHelper:JwtHelperService ) { }

  getUser(){
    if(localStorage.getItem('user')){
      return JSON.parse(localStorage.getItem('user'));
    }else{
      return null;
    }
  }
  getToken(){
    if(localStorage.getItem('user')){
      return JSON.parse(localStorage.getItem('token'));
    }else{
      return null;
    }
  }
  systemId(system){
    const apiSystems = JSON.parse(localStorage.getItem('system'));
    const found = apiSystems.find(element => element.system_key == system);
    if(found){
      return found.system_entity_id
    }else{
     return null
   }
  }
  verifyTokent(): boolean{
    // const token = localStorage.getItem('token')
    return !this.jwtHelper.isTokenExpired(this.getToken());
    // return true
 }

  setStorageUser(resp){
    localStorage.setItem('user',JSON.stringify(resp.user));
    localStorage.setItem('token',JSON.stringify(resp.token));
    localStorage.setItem('system',JSON.stringify(resp.system));
    //localStorage.setItem('rrhhId',JSON.stringify(resp.rrhhId));
  }

  login(entries){
   return this.http.post(`${API_AUTH}/user/login`,entries);
  }
  requestToken(params){
      return this.http.post(`${API_AUTH}/user/resetPassword`,params);
  }
  checkToken(token){
    return this.http.get(`${API_AUTH}/check/token/${token}`);
  }
  createPassword(token,params){
    return this.http.post(`${API_AUTH}/user/updatePassword/${token}`,params);
  }

  systemIdentify(system){
     const apiSystems = JSON.parse(localStorage.getItem('system'));
     const found = apiSystems.find(element => element.system_key == system);
     if(found){
      if(ENV[found.system_key]){
             return ENV[found.system_key]
          }else{
           // window.location.href = '/logout';
            throw new Error("Api System Invalid");
          }
     }else{
      return null
    }
  }
  logout(){
    return this.http.get(`${API_AUTH}/user/logout`);
  }

  decrypToken(){
    const token = JSON.parse(localStorage.getItem('token'));
    return this.jwtHelper.decodeToken(token);
  }
  systemKey(){
    const apiSystems = JSON.parse(localStorage.getItem('system'));
    const systemKey = apiSystems.find(element => element.system_key);
    return systemKey.system_key;

  }
}
const API_AUTH = environment.api_auth;
const ENV = environment;
