import { Component, OnInit } from '@angular/core';
import { AlertsService } from 'src/app/shared/alerts/alerts.service';
import { FormRequestService } from '../../services/form-request.service';

@Component({
  selector: 'app-resignation-survey',
  templateUrl: './resignation-survey.component.html',
  styleUrls: ['./resignation-survey.component.sass']
})
export class ResignationSurveyComponent implements OnInit {

  formInfo: any;
  formSolved: any
  constructor(public formRequestService: FormRequestService,
              public alertsService: AlertsService) { }

  ngOnInit(): void {
    this.getForm();
  }

   /**
   * @author Miguel Restrepo
   * @createdate 2022-06-02
   * Método que obtiene los datos del formulario
   */
  getForm(){
    this.formRequestService.getForm('SURVEY_FORM',{
      public_survey: 'public',
      token: 'ahdalqERweq671213@d'
    }).subscribe(res => {
      this.formInfo = res;
    })
  }

  /**
   * @author Miguel Restrepo
   * @createdate 2022-06-02
   * Recibe la respuesta del formulario y la envía
   */
  getFormAnswered(event) {
    this.formSolved = this.matchForms(event);
    this.formInfo.section[0].fields = this.formSolved;
    this.formRequestService.postForm({
      sections: JSON.stringify(this.formInfo.section),
      client_unique: JSON.stringify(this.formInfo.client_unique),
      form_id: JSON.stringify(this.formInfo.id)
    }).subscribe( res => {
      this.alertsService.alertSuccess('¡Bien!', 'La información se ha guardado correctamente')
    });
  }

  /**
   * @author Miguel Restrepo
   * @createdate 2022-06-02
   * Método que obtiene los datos del formulario y los convierte en JSON
   */
  matchForms(event) {
    let user = [];
    const formMatch = this.formInfo.section[0].fields;
    formMatch.forEach((elementInt) => {
      for (const key in event[0]) {
        if (key === elementInt.key) {
          elementInt.value = event[0][key];
        }
      }
      user.push(elementInt);
    });
    return user;
  }
}
