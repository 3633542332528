
<br>
<form fxLayout="row wrap" fxLayout.xs="column" fxLayoutGap="35px" [formGroup]="form">
    <mat-form-field appearance="fill" fxFlex=30>
        <mat-label>Tipo Documento</mat-label>
        <mat-select matNativeControl formControlName="id_document_type">
            <mat-option value="Cédula de ciudadanía">Cédula de ciudadanía</mat-option>
            <mat-option value="Cédula de Extranjería">Cédula de Extranjería</mat-option>
            <mat-option value="Pasaporte">Pasaporte</mat-option>
            <mat-option value="Permiso especial de permanencia">Permiso especial de permanencia</mat-option>
            <mat-option value="Tarjeta de Indentidad">Tarjeta de Indentidad</mat-option>
            <mat-option value="Permiso por proteccion temporal">Permiso por proteccion temporal</mat-option>
            <mat-option value="Permiso especial de trabajo">Permiso especial de trabajo</mat-option>
        </mat-select>
    </mat-form-field>

    <mat-form-field appearance="fill" fxFlex=30>
        <mat-label>Número de documento</mat-label>
        <input type="number" matInput formControlName="id_document_number" required>
        <mat-error *ngIf="form.hasError('pattern', 'id_document_number')">
            <span>¡Advertencia! Este campo solo recibe números</span>
        </mat-error>
        <mat-error *ngIf="form.hasError('required', 'id_document_number')">
            <span>¡Advertencia! Este campo es requerido</span>
        </mat-error>
        <mat-error *ngIf="form.hasError('trimErrorInit', 'id_document_number')">
            <span>¡Advertencia!Este campo no acepta espacios en blanco al inicio</span>
        </mat-error>
        <mat-error *ngIf="form.hasError('trimErrorLast', 'id_document_number')">
            <span>¡Advertencia! Este campo no acepta espacios en blanco al final</span>
        </mat-error>
    </mat-form-field>

    <mat-form-field appearance="fill" fxFlex=30>
        <mat-label>Ciudad de expedición documento</mat-label>
        <input matInput formControlName="city_document_id">
        <mat-error *ngIf="form.hasError('pattern', 'city_document_id')">
            <span>¡Advertencia! Este campo no recibe números ni caracteres especiales</span>
        </mat-error>
        <mat-error *ngIf="form.hasError('required', 'city_document_id')">
            <span>¡Advertencia! Este campo es requerido</span>
        </mat-error>
        <mat-error *ngIf="form.hasError('trimErrorInit', 'city_document_id')">
            <span>¡Advertencia!Este campo no acepta espacios en blanco al inicio</span>
        </mat-error>
        <mat-error *ngIf="form.hasError('trimErrorLast', 'city_document_id')">
            <span>¡Advertencia! Este campo no acepta espacios en blanco al final</span>
        </mat-error>
    </mat-form-field>

    <mat-form-field appearance="fill" fxFlex=30>
        <mat-label>Fecha Expedición</mat-label>
        <input matInput [matDatepicker]="picker5" formControlName="expedition_date_id" [max]="now">
        <mat-datepicker-toggle matSuffix [for]="picker5"></mat-datepicker-toggle>
        <mat-datepicker #picker5></mat-datepicker>
    </mat-form-field>

    <mat-form-field appearance="fill" fxFlex=30>
        <mat-label>Primer Nombre</mat-label>
        <input matInput formControlName="first_name" required>
        <mat-error *ngIf="form.hasError('pattern', 'first_name')">
            <span>¡Advertencia! Este campo no recibe números ni caracteres especiales</span>
        </mat-error>
        <mat-error *ngIf="form.hasError('required', 'first_name')">
            <span>¡Advertencia! Este campo es requerido</span>
        </mat-error>
        <mat-error *ngIf="form.hasError('trimErrorInit', 'first_name')">
            <span>¡Advertencia!Este campo no acepta espacios en blanco al inicio</span>
        </mat-error>
        <mat-error *ngIf="form.hasError('trimErrorLast', 'first_name')">
            <span>¡Advertencia! Este campo no acepta espacios en blanco al final</span>
        </mat-error>
    </mat-form-field>

    <mat-form-field appearance="fill" fxFlex=30>
        <mat-label>Segundo Nombre</mat-label>
        <input matInput formControlName="middle_name">
        <mat-error *ngIf="form.hasError('pattern', 'middle_name')">
            <span>¡Advertencia! Este campo no recibe números ni caracteres especiales</span>
        </mat-error>
        <mat-error *ngIf="form.hasError('trimErrorInit', 'middle_name')">
            <span>¡Advertencia!Este campo no acepta espacios en blanco al inicio</span>
        </mat-error>
        <mat-error *ngIf="form.hasError('trimErrorLast', 'middle_name')">
            <span>¡Advertencia! Este campo no acepta espacios en blanco al final</span>
        </mat-error>
    </mat-form-field>

    <mat-form-field appearance="fill" fxFlex=30>
        <mat-label>Primer Apellido</mat-label >
        <input matInput formControlName="last_name" required>
        <mat-error *ngIf="form.hasError('required', 'last_name')">
            <span>¡Advertencia! Este campo es requerido</span>
        </mat-error>
        <mat-error *ngIf="form.hasError('pattern', 'last_name')">
            <span>¡Advertencia! Este campo no recibe números ni caracteres especiales</span>
        </mat-error>
        <mat-error *ngIf="form.hasError('trimErrorInit', 'last_name')">
            <span>¡Advertencia!Este campo no acepta espacios en blanco al inicio</span>
        </mat-error>
        <mat-error *ngIf="form.hasError('trimErrorLast', 'last_name')">
            <span>¡Advertencia! Este campo no acepta espacios en blanco al final</span>
        </mat-error>
    </mat-form-field>

    <mat-form-field appearance="fill" fxFlex=30>
        <mat-label>Segundo Apellido</mat-label>
        <input matInput formControlName="second_last_name">
        <mat-error *ngIf="form.hasError('pattern', 'second_last_name')">
            <span>¡Advertencia! Este campo no recibe números ni caracteres especiales</span>
        </mat-error>
        <mat-error *ngIf="form.hasError('trimErrorInit', 'second_last_name')">
            <span>¡Advertencia!Este campo no acepta espacios en blanco al inicio</span>
        </mat-error>
        <mat-error *ngIf="form.hasError('trimErrorLast', 'second_last_name')">
            <span>¡Advertencia! Este campo no acepta espacios en blanco al final</span>
        </mat-error>
    </mat-form-field>

    <mat-form-field appearance="fill" fxFlex=30>
        <mat-label>Nivel de Estudios</mat-label>
        <mat-select matNativeControl formControlName="education_level">
            <mat-option value="Primaria">Primaria</mat-option>
            <mat-option value="Bachillerato">Bachillerato</mat-option>
            <mat-option value="Técnico">Técnico</mat-option>
            <mat-option value="Tecnológico">Tecnológico</mat-option>
            <mat-option value="Universitario">Universitario</mat-option>
            <mat-option value="Especialización">Especialización</mat-option>
            <mat-option value="Magister">Magister</mat-option>
            <mat-option value="PHD">PHD</mat-option>
            <mat-option value="Doctorado">Doctorado</mat-option>
            <mat-option value="Diplomado">Diplomado</mat-option>
            <mat-option value="Sena">Sena</mat-option>
            <mat-option value="Otro">Otro</mat-option>
        </mat-select>
    </mat-form-field>

    <mat-form-field appearance="fill" fxFlex=30>
        <mat-label>Parentesco</mat-label>
        <mat-select matNativeControl formControlName="relationship" required>
            <mat-option value="Madre">Madre</mat-option>
            <mat-option value="Padre">Padre</mat-option>
            <mat-option value="Hijo">Hijo</mat-option>
            <mat-option value="Hijastro">Hijastro</mat-option>
            <mat-option value="Conyuge">Conyuge</mat-option>
            <mat-option value="Padrastro">Padrastro</mat-option>
            <mat-option value="Compañero permanente">Compañero permanente</mat-option>
            <mat-option value="Tio">Tío</mat-option>
            <mat-option value="Sobrino">Sobrino</mat-option>
            <mat-option value="Abuelo">Abuelo</mat-option>
            <mat-option value="Hermano">Hermano</mat-option>
            <mat-option value="Nieto">Nieto</mat-option>
            <mat-option value="Cuñado">Cuñado</mat-option>
            <mat-option value="Suegro">Suegro</mat-option>
            <mat-option value="Primo">Primo</mat-option>
        </mat-select>
        <mat-error *ngIf="form.hasError('required', 'relationship')">
            <span>¡Advertencia! Este campo es requerido</span>
        </mat-error>
    </mat-form-field>

    <mat-form-field appearance="fill" fxFlex=30>
        <mat-label>Género</mat-label>
        <mat-select matNativeControl formControlName="gender">
            <mat-option value="Femenino">Femenino</mat-option>
            <mat-option value="Masculino">Masculino</mat-option>
        </mat-select>
    </mat-form-field>

    <mat-form-field appearance="fill" fxFlex=30>
        <mat-label>Fecha de Nacimiento</mat-label>
        <input matInput [matDatepicker]="picker6" formControlName="birthdate" [max]="now">
        <mat-datepicker-toggle matSuffix [for]="picker6"></mat-datepicker-toggle>
        <mat-datepicker #picker6></mat-datepicker>
    </mat-form-field>

    <mat-form-field appearance="fill" fxFlex=30>
        <mat-label>Profesión</mat-label>
        <input matInput formControlName="profession">
        <mat-error *ngIf="form.hasError('required', 'profession')">
            <span>¡Advertencia! Este campo es requerido</span>
        </mat-error>
        <mat-error *ngIf="form.hasError('pattern', 'profession')">
            <span>¡Advertencia! Este campo no recibe números ni caracteres especiales</span>
        </mat-error>
        <mat-error *ngIf="form.hasError('trimErrorInit', 'profession')">
            <span>¡Advertencia!Este campo no acepta espacios en blanco al inicio</span>
        </mat-error>
        <mat-error *ngIf="form.hasError('trimErrorLast', 'profession')">
            <span>¡Advertencia! Este campo no acepta espacios en blanco al final</span>
        </mat-error>
    </mat-form-field>

    <div fxFlex=30 fxLayoutAlign="center center">
        <button mat-stroked-button color="accent" (click)="_actions.add(dataSourceFamilyGoup,'group_family',familyGroup, form, candidateId)" [disabled]="!form.valid">
        Agregar familiar</button>
    </div>
    <hr fxFlex=100>
    <div fxFlex=100 class="table_data">
        <table mat-table [dataSource]="dataSourceFamilyGoup">

            <ng-container matColumnDef="first_name">
                <th mat-header-cell *matHeaderCellDef class="start"> Nombre </th>
                <td mat-cell *matCellDef="let element"> {{element.first_name}} </td>
            </ng-container>

            <ng-container matColumnDef="last_name">
                <th mat-header-cell *matHeaderCellDef> Apellido </th>
                <td mat-cell *matCellDef="let element"> {{element.last_name}} </td>
            </ng-container>

            <ng-container matColumnDef="id_document_number">
                <th mat-header-cell *matHeaderCellDef> Documento </th>
                <td mat-cell *matCellDef="let element"> {{element.id_document_number}} </td>
            </ng-container>

            <!-- city Column -->
            <ng-container matColumnDef="education_level">
                <th mat-header-cell *matHeaderCellDef> Educación </th>
                <td mat-cell *matCellDef="let element"> {{element.education_level}} </td>
            </ng-container>

            <!-- city Column -->
            <ng-container matColumnDef="relationship">
                <th mat-header-cell *matHeaderCellDef> Parentesco </th>
                <td mat-cell *matCellDef="let element"> {{element.relationship}} </td>
            </ng-container>

            <!-- periods Column -->
            <ng-container matColumnDef="profession">
                <th mat-header-cell *matHeaderCellDef> Profesión </th>
                <td mat-cell *matCellDef="let element"> {{element.profession}} </td>
            </ng-container>

            <!-- actions Column -->
            <ng-container matColumnDef="actions">
                <th mat-header-cell *matHeaderCellDef class="end"> Acciones </th>
                <td mat-cell *matCellDef="let element">
                    <button mat-icon-button (click)="_actions.edit(element, familyGroup, form)"><span class="fi-rr-edit"></span></button>
                    <button mat-icon-button (click)="_actions.delete(dataSourceFamilyGoup, familyGroup, element, 'group_family')">
                        <span class="fi-rr-trash"></span>
                    </button>
                </td>
            </ng-container>
            <tr class="mat-row" *matNoDataRow>
                <td class="mat-cell" colspan="7">Aún no se agregan familiares</td>
            </tr>
            <tr mat-header-row *matHeaderRowDef="displayedColumnsFamily"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumnsFamily;"></tr>
        </table>
    </div>

</form>
