import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoginComponent } from './login/login.component';
import { MaterialModule } from '../core/material/material.module';
import { RequestPasswordTokenComponent } from './request-password-token/request-password-token.component';
import { CreatePasswordComponent } from './create-password/create-password.component';
import { AuthRoutingModule } from './auth.routing';
import { LogoutComponent } from './logout/logout.component';


@NgModule({
  declarations: [LoginComponent, RequestPasswordTokenComponent, CreatePasswordComponent, LogoutComponent],
  imports: [
    CommonModule,
    MaterialModule,
    AuthRoutingModule
  ]

})
export class AuthModule { }
