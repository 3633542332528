<div class="head">
    <div fxLayout="row wrap" fxLayout.xs="column">
        <div fxLayout="column" fxFlex=80>
        <h1>Contratación</h1>
        <h4 matCardTitle>Aquí podrás revisar todos los documentos que necesitan tu firma</h4>
        <p matCardSubtitle>Verifica que toda la información de los documentos esté correcta. Una vez comprobada, puedes firmar</p>
        </div>
    </div>

    <mat-tab-group (selectedTabChange)="onTabChanged($event);">
      <mat-tab *ngFor="let document of documentsToSign"  [label]="document.file" [disabled]="!document.uploaded">
        <div fxLayout="column" *ngIf="pdfSrc" >
          <pdf-viewer [src]="pdfSrc"
            [render-text]='false'
            [original-size]="true"
            [fit-to-page]="true"
            class="view_document"
            ></pdf-viewer>
           <br>
          <p *ngIf="!pdfSrc"><strong>Documento no disponible</strong></p>
          <p *ngIf="foundSing?.state == 'Pendiente' && foundSing.uploaded == true"> <strong>En espera de verificación de la firma...</strong> </p>
          <p *ngIf="foundSing?.state == 'Aprobado'"> <strong>Firma aprobada</strong></p>
         <p *ngIf="foundSing?.state == 'Rechazado'"> <strong>Firma rechazada</strong> <strong *ngIf='foundSing?.dataExtraJson?.comment !=  "" '>: {{foundSing?.dataExtraJson?.comment}}</strong> </p>
           <mat-checkbox [(ngModel)]="accept" *ngIf="!foundSing?.uploaded  ||  foundSing.state == 'Rechazado'">
            <span style="white-space: normal;">He leído, entiendo y acepto los términos y condiciones del documento</span>
          </mat-checkbox>
          <div fxLayoutAlign="center center" fxFlex=20 *ngIf="pdfSrc && !foundSing?.uploaded ||  foundSing.state == 'Rechazado'">
            <button style="float: right; margin: 10px;" matSuffix mat-flat-button color="primary" (click)="createSign()" [disabled]="!accept">Firmar</button>
          </div>
        </div>
      </mat-tab>
      
    </mat-tab-group>
    </div>




