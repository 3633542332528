import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { Observable } from 'rxjs';

import { AuthServicesService } from 'src/app/auth/services/auth.service';


@Injectable()
export class InterceptorInterceptor implements HttpInterceptor {

  skipApiCall = [
    'auth/refresh'
  ];

  constructor(public authService: AuthServicesService) {}

  intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {

  const user = this.authService.getUser();

  if(user !== null) {

    const tokenizeReq = req.clone({
      setHeaders: {
        Authorization: `Bearer ${this.authService.getToken()}`
      }
    })
    return next.handle(tokenizeReq)

  }

  else {

    const tokenizeReq = req.clone({
      setHeaders: {
        Authorization: `Bearer `
      }
    })
    return next.handle(tokenizeReq)

    }

  }

}
