import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { QuestionBase } from 'src/app/model/QuestionBase';

@Component({
  selector: 'app-questions',
  templateUrl: './questions.component.html',
  styleUrls: ['./questions.component.sass']
})
export class QuestionsComponent implements OnInit {

  @Input() question: QuestionBase<any>;
  @Input() form: FormGroup;
  filteredOptions: Observable<any[]>;

  constructor() { }


  ngOnInit(): void {

   this.filteredOptions = this.form.get(this.question.key).valueChanges.pipe(
     startWith(''),
     map(value => typeof value === 'string' ? value : value.name),
     map(state => state ? this._filter(state) : this.question.options.slice(0, 5))
   );
 }


 private _filter(value: string): any[] {
   if (value) {
     const filterValue = value.toLowerCase();
     return this.question.options.filter(state => state.name.toLowerCase().includes(filterValue));
   }
 }

 displayFn(id): any {
   if (!id) {
     return '';
   }
   const index = this.question.options.findIndex(state => state.id === id);
   return this.question.options[index].name;
 }

 displayFn2(option): any {
   if (!option) {
     return '';
   }
   let index = this.question.options.findIndex(state => JSON.stringify(state) === JSON.stringify(option));
   return this.question.options[index].name;
 }

/**
   * @author Miguel Restrepo
   * @createdate 2022-06-02
   * Método que muestra los errores del formulario
   */
 getErrorMessage() {
     if (this.form.controls[this.question.key].hasError('required')) {
       return `${this.question.label} es requerido`;
     }
     if (this.form.get(this.question.key).hasError('invalidAutocompleteObject')) {
       return `No es una opción válida`;
     }
     if (this.form.get(this.question.key).hasError('maxlength')) {
       return `${this.question.label} supera el límite de ${this.question.maxLength} caracteres`;
     }
     if (this.form.get(this.question.key).hasError('minlength')) {
       return `${this.question.label} debe tener ${this.question.minLength} mínimo caracteres`;
     }
     if (this.form.get(this.question.key).hasError('email')) {
       return `No es un correo electrónico válido`;
     }
 }

//  ngOnDestroy(): void {
//    this.destroyService.destroyComponent();
//  }

}
