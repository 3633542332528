import { Component, OnInit, Input, ViewChild, OnChanges } from '@angular/core';
import { FormBuilder, FormGroup, RequiredValidator, Validators } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';;
import {MatSort} from '@angular/material/sort';
import { TablesActionsService } from 'src/app/candidate/services/util/tables-actions.service';
import { FormsSteperService } from 'src/app/candidate/services/rest/forms-steper.service';
import { AuthServicesService } from 'src/app/auth/services/auth.service';
import { HttpParams } from '@angular/common/http';

@Component({
  selector: 'candidate-second-step',
  templateUrl: './second-step.component.html',
  styleUrls: ['./second-step.component.sass']
})
export class SecondStepComponent implements OnInit{
  @Input() form:FormGroup;
  @Input() workExperiencies=[];
  @Input() candidateId;
  now = new Date().toISOString().split('T')[0];
  dataSourceWorkExperiences = new MatTableDataSource<any>(this.workExperiencies);
  displayedColumnsWorkExperiencies: string[] = ['company_name', 'position_hired', 'boss_name', 'boss_mobile_phone', 'start_date', 'end_date', 'actions'];
  idCandidate;
  constructor(public _actions:TablesActionsService,private _SteperService:FormsSteperService,private authService:AuthServicesService) {
    // this.candidateId = this.authService.systemId('RRHH');
    if ( this.candidateId == null ) {
      this.candidateId = this.authService.systemId('NOMINA');
    }
   }
  @ViewChild('sort') sort: MatSort;
  ngAfterViewInit() {
    this.dataSourceWorkExperiences.sort = this.sort;
  }


  ngOnInit(): void {
    this.getStepData()
  }

  getStepData(){
    const entries = {};
    entries['userId'] = this.candidateId;
    entries['data'] = 'jobs_references';
    // entries['flag'] = this.authService.systemKey();
    const params = new HttpParams( { fromObject: entries } );
     this._SteperService.getInfo(`candidate/json/data/${this.candidateId}`, params ).subscribe( (res) => {
      if((Object.keys(res).length != 0)){
        this.dataSourceWorkExperiences.data = res
        this.workExperiencies = res
      }
     } )
  }

}
