import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NotificationService } from 'src/app/core/services/notification.service';
import { AuthServicesService } from '../services/auth.service';

@Component({
  selector: 'app-request-password-token',
  templateUrl: './request-password-token.component.html',
  styleUrls: ['./request-password-token.component.sass']
})
export class RequestPasswordTokenComponent implements OnInit {

  requestTokenForm:FormGroup;
  anio = new Date().getFullYear();

  constructor(private fb:FormBuilder,private authService:AuthServicesService, private not:NotificationService) {
    this.requestTokenForm = this.fb.group({
      username:['',[Validators.required, Validators.maxLength(50), Validators.minLength(3)]],
    })
  }

  get error(): any { return this.requestTokenForm.controls; }
  ngOnInit(): void {
  }

  omitSpecialChar(event): any{
    let k;
    k = event.charCode;
    return((k > 32 && k < 126));
   }

   requestToken(){
      if(this.requestTokenForm.valid){
          this.authService.requestToken(this.requestTokenForm.value).subscribe( (resp) => {
              this.not.alertInfo('Solicitud Enviada','Te enviamos un correo electrónico para restablecer su contraseña');
              this.requestTokenForm.reset();
              this.requestTokenForm.untouched;
              this.requestTokenForm.pristine;
          })
      }
   }

}
