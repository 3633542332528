import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CoreModule } from '../core/core.module';
import { MaterialModule } from '../core/material/material.module';
import { LayoutModule } from '@angular/cdk/layout';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatButtonModule } from '@angular/material/button';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { CandidateRoutingModule } from './candidate-routing.module';
import { PersonalInformationComponent } from './pages/personal-information/personal-information.component';
import { TestsComponent } from './pages/tests/tests.component';
import { BasicFormComponent } from './components/steps-information/basic-form/basic-form.component';
import { SecondStepComponent } from './components/steps-information/second-step/second-step.component';
import { ThirdStepComponent } from './components/steps-information/third-step/third-step.component';
import { FirstStepComponent } from './components/steps-information/first-step/first-step.component';
import { FourthStepComponent } from './components/steps-information/fourth-step/fourth-step.component';
import { FifthStepComponent } from './components/steps-information/fifth-step/fifth-step.component';
import { SixthStepComponent } from './components/steps-information/sixth-step/sixth-step.component';
import { SeventhStepComponent } from './components/steps-information/seventh-step/seventh-step.component';
import { ContractComponent } from './pages/contract/contract.component';
import { CreateSignComponent } from './components/sign/create-sign/create-sign.component';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { SignaturePadModule } from 'angular2-signaturepad';
import { AddressFormComponent } from './pages/address-form/address-form.component';


@NgModule({
  declarations: [ PersonalInformationComponent, TestsComponent, BasicFormComponent, SecondStepComponent, ThirdStepComponent, FirstStepComponent, FourthStepComponent, FifthStepComponent, SixthStepComponent, SeventhStepComponent, ContractComponent, CreateSignComponent, AddressFormComponent],
  imports: [
    PdfViewerModule,
    CommonModule,
    SignaturePadModule,
    MaterialModule,
    CandidateRoutingModule
  ]
})
export class CandidateModule { }
