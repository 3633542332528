import { Component, OnInit, Input, Output, EventEmitter, AfterViewInit } from '@angular/core';
import {FormBuilder, FormGroup, ValidatorFn, AbstractControl, ValidationErrors} from '@angular/forms';
import { FormsSteperService } from 'src/app/candidate/services/rest/forms-steper.service';
import { CandidateService } from 'src/app/core/services/candidate.service';
@Component({
  selector: 'candidate-basic-form',
  templateUrl: './basic-form.component.html',
  styleUrls: ['./basic-form.component.sass']
})
export class BasicFormComponent implements OnInit, AfterViewInit {
  @Input() form: FormGroup;
  @Output() birthDate = new EventEmitter<number>();
  @Output() candidateId = new EventEmitter<number>();


  idTypes: any;
  keyType: string;

  civilStates = [
    {
      id: 1,
      name: 'Soltera(o)'
    },
    {
      id: 2,
      name: 'Casada(o)'
    },
    {
      id: 3,
      name: 'Unión libre'
    },
    {
      id: 4,
      name: 'Separada(o)'
    },
    {
      id: 5,
      name: 'Divorciada(o)'
    },
    {
      id: 6,
      name: 'Religiosa(o)'
    },
    {
      id: 7,
      name: 'No definida(o)'
    },
    {
      id: 8,
      name: 'Viuda(o)'
    },
    {
      id: 9,
      name: 'Indifernte'
    }
  ];
  now = new Date().getFullYear();
  minDate: Date;
  maxDate: Date;
  idDocumentType: number;

  constructor(private _fb: FormBuilder, private formsSteperService: FormsSteperService, private candidateService: CandidateService) { }

  ngAfterViewInit(): void{
    this.form.setValidators(this.idNumberMatchValidator);
    this.form.get('number_PA_verify').setValidators(this.equalVerifypa());


  }

  ngOnInit(): void {
    this.form.controls['id_number_verify'].valueChanges.subscribe((resForm) => {
      if(this.form.controls['id_number'].value == resForm){
        this.candidateService.getCandididatesByDocuments([resForm]).subscribe((resp:any)=>{
          if(resp.length == 1){
            let respService = resp[0];
            if(respService.candidateId){
              this.candidateId.emit(respService.candidateId)
            }
          }
        });
      }
    });
    setTimeout(res =>{this.getTypes()},1000);
    this.getTypes();
    this.minDate = new Date(this.now - 90, 0, 1);
    this.maxDate = new Date(this.now - 10, 11, 31);
    this.form.controls['id_type_id'].valueChanges.subscribe(resp => {
      this.idDocumentType = resp
    });
  }
  ageCalculator(event) {
    this.birthDate.emit(event.value)
  }

  /**
     * Metodo lista dinamicamente los tipos de documentos
     * @author Carlos Nieto
     * @return json
     */
  getTypes() {
    this.formsSteperService.getIdTypes().subscribe(resp => {
      this.idTypes = resp;

      if(this.form.controls['id_type_id'].value)
      {
        let json = this.idTypes.find(element => element.id == this.form.controls['id_type_id'].value);
        this.keyType = json.key;
      }
    }
    );
  }

  /**
     * Metodo setea el nuevo valos del key type segun seleccion del usuario
     * @author Carlos Nieto
     * @return string
     */
  selectedIdtype(value) {
    this.keyType = value;
  }

  /**
   * @author Carlos Nieto
   * @createdate 2022-07-12
   * Metodo que valida si dos campos son exactamente iguales
   */
   equalVerify(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      if (this.form.get('id_number').value == control.value) {
        return null  /* valid option selected */
      }
      return { 'invalidField': { value: control.value } }
    }
  }

  /**
   * @author Carlos Nieto
   * @createdate 2022-07-12
   * Metodo que valida si dos campos son exactamente iguales
   */
   equalVerifypa(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      if (this.form.get('number_PA').value == control.value) {
        return null  /* valid option selected */
      }
      return { 'invalidFieldpa': { value: control.value } }
    }
  }

  private idNumberMatchValidator(control: AbstractControl): ValidationErrors | null  {
    const idNumber = control.get('id_number').value;
    const idNumberConfirm = control.get('id_number_verify').value;

    if (idNumber !== idNumberConfirm) {
      return { idNumberMismatch: true };
    }

    return null;
  }
}
