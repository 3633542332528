
<form (ngSubmit)="onSubmit()">
    <mat-grid-list cols="4" rowHeight="100px" [gutterSize]="'20px'">
    <div *ngFor="let question of questions">
        
            <mat-grid-tile [colspan]="question.cols">
                <app-questions style="width: 100%" [question]="question" [form]="forms.controls[0]" ></app-questions>
            </mat-grid-tile>
    </div>
    </mat-grid-list>

  <div fxLayout="row" fxLayoutAlign="end center" class="action-btns">
      <button mat-flat-button color="primary" [disabled]="!forms.valid" type="submit">Enviar</button>
  </div>
</form>
