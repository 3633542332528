import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { FormBuilder, FormGroup, RequiredValidator, Validators } from '@angular/forms';
import { formatCurrency } from '@angular/common';
import { AuthServicesService } from 'src/app/auth/services/auth.service';
import { FormsSteperService } from '../rest/forms-steper.service';
import { NotificationService } from 'src/app/core/services/notification.service';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root'
})
export class TablesActionsService {
  candidateId
  constructor(private authService:AuthServicesService,private _SteperService:FormsSteperService,private snack:MatSnackBar) {
    this.candidateId = this.authService.systemId('RRHH');
  }


  add(dataSource, steperName, array: any[], from: FormGroup, candidateId?) {
    let confirm = false
    for (const key in from.getRawValue()) {
      if(from.getRawValue()[key] != null && from.getRawValue()[key]!= ""){
        confirm = true
      }
    }
    if(confirm){
      let index = array.findIndex(element => element.id == from.get('id').value);
      if(index >=0){
        array[index] = from.getRawValue()
      }else{
        if(from.get('id')){
          from.get('id').setValue(new Date().getTime())
        }
        array.push(from.getRawValue())
      }
      this.postFormArray(steperName, array, candidateId)
      dataSource.data = array
      from.reset()
      from.markAsPristine();
      from.markAsUntouched();
    }
  }
  delete(dataSource, array: any[], data, stepName){

    let index = array.findIndex(element => element === data);
    array.splice(index, 1)
    this.postFormArray(stepName, array)
    dataSource.data = array
  }
  edit(data, array: any[], from: FormGroup){
    const found = array.find(element => element == data);
    from.setValue(found)
  }
  postFormArray( step, data, candidateId?){
    if(candidateId){
      this._SteperService.postInfo(`candidate/json/${candidateId}/${step}`, data ).subscribe( (res) => {
        this.snack.open('Datos Guardados', 'ok', {
          duration: 2400
        });
      })
    }else{
      this._SteperService.postInfo(`candidate/json/${this.candidateId}/${step}`, data ).subscribe( (res) => {
        this.snack.open('Datos Guardados', 'ok', {
          duration: 2400
        });
      })
    }
  }
}
