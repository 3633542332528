import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-all-surveys',
  templateUrl: './all-surveys.component.html',
  styleUrls: ['./all-surveys.component.sass']
})
export class AllSurveysComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
