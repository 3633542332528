<br>
<form fxLayout="row wrap" fxLayout.xs="column" fxLayoutGap="35px" [formGroup]="form">

    <mat-accordion  fxFlex=100>       
        <mat-expansion-panel  [expanded]="true">
          <mat-expansion-panel-header>
            <mat-panel-title>
              <h4 class="pt-3">Informacion Personal</h4>   
              <br>                            
            </mat-panel-title>                  
          </mat-expansion-panel-header>
          <div fxLayout="row wrap"  fxLayoutGap="20px" >

                <mat-form-field appearance="fill" fxFlex=30>
                    <mat-label>Edad</mat-label>
                    <input matInput formControlName="age" required>
                    <mat-error *ngIf="form.hasError('pattern', 'age')">
                        <span>¡Advertencia! Este campo recibe solo números</span>
                    </mat-error>
                    <mat-error *ngIf="form.hasError('maxlength', 'age')">
                        <span>¡Advertencia! Este campo recibe máximo 2 números</span>
                    </mat-error>
                    <mat-error *ngIf="form.hasError('required', 'age')">
                        <span>¡Advertencia! Este campo es requerido</span>
                    </mat-error>
                    <mat-error *ngIf="form.hasError('trimErrorInit', 'age')">
                        <span>¡Advertencia!Este campo no acepta espacios en blanco al inicio</span>
                    </mat-error>
                    <mat-error *ngIf="form.hasError('trimErrorLast', 'age')">
                        <span>¡Advertencia! Este campo no acepta espacios en blanco al final</span>
                    </mat-error>
                </mat-form-field>

                <mat-form-field appearance="fill" fxFlex=30>
                    <mat-label>Ciudad de expedición documento</mat-label>
                    <input matInput formControlName="city_document_id" required>
                    <mat-error *ngIf="form.hasError('pattern', 'city_document_id')">
                        <span>¡Advertencia! Este campo no recibe números ni caracteres especiales</span>
                    </mat-error>
                    <mat-error *ngIf="form.hasError('required', 'city_document_id')">
                        <span>¡Advertencia! Este campo es requerido</span>
                    </mat-error>
                    <mat-error *ngIf="form.hasError('trimErrorInit', 'city_document_id')">
                        <span>¡Advertencia!Este campo no acepta espacios en blanco al inicio</span>
                    </mat-error>
                    <mat-error *ngIf="form.hasError('trimErrorLast', 'city_document_id')">
                        <span>¡Advertencia! Este campo no acepta espacios en blanco al final</span>
                    </mat-error>
                </mat-form-field>

                <mat-form-field appearance="fill" fxFlex=30>
                    <mat-label>Fecha Expedición</mat-label>
                    <input matInput [matDatepicker]="picker1" formControlName="expedition_date_id" [max]="now" required>
                    <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                    <mat-datepicker #picker1></mat-datepicker>
                </mat-form-field>

                <mat-form-field appearance="fill" fxFlex=30>
                    <mat-label>Lugar de Nacimiento</mat-label>
                    <input matInput [matAutocomplete]="auto1" formControlName="place_of_birth" autocomplete="off" required>
                    <mat-autocomplete #auto1="matAutocomplete"
                        [displayWith]="displayFn.bind(this, 'place_of_birth','null', 'countries')">
                        <mat-option *ngFor="let option of filteredCountyOpt | async" [value]="option.name">
                            <span>{{option.name}}</span>
                        </mat-option>
                    </mat-autocomplete>
                    <mat-error *ngIf="form.hasError('pattern', 'place_of_birth')">
                        <span>¡Advertencia! Este campo no recibe números ni caracteres especiales</span>
                    </mat-error>
                    <mat-error *ngIf="form.hasError('required', 'place_of_birth')">
                        <span>¡Advertencia! Este campo es requerido</span>
                    </mat-error>
                    <mat-error *ngIf="form.hasError('trimErrorInit', 'place_of_birth')">
                        <span>¡Advertencia!Este campo no acepta espacios en blanco al inicio</span>
                    </mat-error>
                    <mat-error *ngIf="form.hasError('trimErrorLast', 'place_of_birth')">
                        <span>¡Advertencia! Este campo no acepta espacios en blanco al final</span>
                    </mat-error>
                </mat-form-field>
                
                <mat-form-field appearance="fill" fxFlex=30>
                    <mat-label>Nacionalidad</mat-label>
                    <input matInput formControlName="nationality" required>
                    <mat-error *ngIf="form.hasError('pattern', 'nationality')">
                        <span>¡Advertencia! Este campo no recibe números ni caracteres especiales</span>
                    </mat-error>
                    <mat-error *ngIf="form.hasError('required', 'nationality')">
                        <span>¡Advertencia! Este campo es requerido</span>
                    </mat-error>
                    <mat-error *ngIf="form.hasError('trimErrorInit', 'nationality')">
                        <span>¡Advertencia!Este campo no acepta espacios en blanco al inicio</span>
                    </mat-error>
                    <mat-error *ngIf="form.hasError('trimErrorLast', 'nationality')">
                        <span>¡Advertencia! Este campo no acepta espacios en blanco al final</span>
                    </mat-error>
                </mat-form-field>

                <mat-form-field appearance="fill" fxFlex=30>
                    <mat-label>¿Cómo quieres que te llamen?</mat-label>
                    <input matInput formControlName="alias" required>
                    <mat-error *ngIf="form.hasError('pattern', 'alias')">
                        <span>¡Advertencia! Este campo no recibe caracteres especiales</span>
                    </mat-error>
                    <mat-error *ngIf="form.hasError('required', 'alias')">
                        <span>¡Advertencia! Este campo es requerido</span>
                    </mat-error>
                    <mat-error *ngIf="form.hasError('trimErrorInit', 'alias')">
                        <span>¡Advertencia!Este campo no acepta espacios en blanco al inicio</span>
                    </mat-error>
                    <mat-error *ngIf="form.hasError('trimErrorLast', 'alias')">
                        <span>¡Advertencia! Este campo no acepta espacios en blanco al final</span>
                    </mat-error>
                </mat-form-field>

                <mat-form-field appearance="fill" fxFlex=30>
                    <mat-label>Género</mat-label>
                    <mat-select matNativeControl formControlName="gender" (ngModelChange)="changeGender($event)" required>
                        <mat-option value="Femenino">Femenino</mat-option>
                        <mat-option value="Masculino">Masculino</mat-option>
                        <mat-option value="Otro">Otro</mat-option>
                    </mat-select>
                </mat-form-field>

                <mat-form-field appearance="fill" fxFlex=30>
                    <mat-label>Grupo Sanguíneo</mat-label>
                    <mat-select matNativeControl formControlName="blood_type" required>
                        <mat-option value="A">A</mat-option>
                        <mat-option value="B">B</mat-option>
                        <mat-option value="O">O</mat-option>
                        <mat-option value="AB">AB</mat-option>
                    </mat-select>
                </mat-form-field>
                
                <mat-form-field appearance="fill" fxFlex=30>
                    <mat-label>RH</mat-label>
                    <mat-select matNativeControl formControlName="rh" required>
                        <mat-option value="Positivo">Positivo</mat-option>
                        <mat-option value="Negativo">Negativo</mat-option>
                    </mat-select>
                </mat-form-field>

                <mat-form-field appearance="fill" fxFlex=30>
                    <mat-label>¿Pertenece a algún grupo social?</mat-label>
                    <mat-select formControlName="social_group">
                        <mat-option value= 1>Ninguno</mat-option>
                        <mat-option value= 2>Mujer cabeza de hogar</mat-option>
                        <mat-option value= 3>Hombre cabeza de hogar</mat-option>
                        <mat-option value= 2>Comunidad LGBTI+Q</mat-option>
                        <mat-option value= 1>Victima del conflicto armado</mat-option>
                        <mat-option value= 2>Afrodecendiente</mat-option>
                        <mat-option value= 2>Indigena</mat-option>
                    </mat-select>
                </mat-form-field>                
                
                <mat-form-field appearance="fill" fxFlex=30>
                    <mat-label>EPS</mat-label>
                    <mat-select formControlName="eps" required>
                        <mat-option *ngFor="let option of infoEps" [value]="option.name">
                            <span>{{option.name}}</span>
                        </mat-option>
                    </mat-select>
                </mat-form-field>

                <mat-form-field appearance="fill" fxFlex=30>
                    <mat-label>Fondo de pensiones</mat-label>
                    <mat-select formControlName="caja_de_pensiones" required>
                        <mat-option *ngFor="let option of infoCajaPensiones" [value]="option.name">
                            <span>{{option.name}}</span>
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            
                <mat-form-field appearance="fill" fxFlex=30>
                    <mat-label>Departamento de residencia</mat-label>
                    <input matInput [matAutocomplete]="auto5" placeholder="Seleccione un departamento"
                        formControlName="deparment_of_residence" autocomplete="off" required>
                    <mat-autocomplete #auto5="matAutocomplete"
                        [displayWith]="displayFn.bind(this, 'deparment_of_residence', 'city', 'department')">
                        <mat-option *ngFor="let option of filteredDeparmentOpt | async" [value]="option.name">
                            <span>{{option.name}}</span>
                        </mat-option>
                    </mat-autocomplete>
                    <mat-error *ngIf="form.hasError('invalidAutocompleteString','deparment_of_residence')">
                        <span>¡Advertencia! Este campo solo admite campos sugeridos</span>
                    </mat-error>
                    <mat-error *ngIf="form.hasError('pattern', 'deparment_of_residence')">
                        <span>¡Advertencia! Este campo no recibe números ni caracteres especiales</span>
                    </mat-error>
                    <mat-error *ngIf="form.hasError('required', 'deparment_of_residence')">
                        <span>¡Advertencia! Este campo es requerido</span>
                    </mat-error>
                    <mat-error *ngIf="form.hasError('trimErrorInit', 'deparment_of_residence')">
                        <span>¡Advertencia!Este campo no acepta espacios en blanco al inicio</span>
                    </mat-error>
                    <mat-error *ngIf="form.hasError('trimErrorLast', 'deparment_of_residence')">
                        <span>¡Advertencia! Este campo no acepta espacios en blanco al final</span>
                    </mat-error>
                </mat-form-field>
            
                <mat-form-field appearance="fill" fxFlex=30 >
                    <mat-label>Ciudad de Residencia</mat-label >
                    <input matInput [matAutocomplete]="auto2"  placeholder="Seleccione una ciudad"
                    formControlName="city_of_residence" autocomplete="off" required >
                    <mat-autocomplete #auto2="matAutocomplete"
                        [displayWith]="displayFn.bind(this, 'city_of_residence', 'locality', 'city')">
                        <mat-option *ngFor="let option of filteredCityOpt | async" [value]="option.name">
                            <span>{{option.name}}</span>
                        </mat-option>
                    </mat-autocomplete>
                    <mat-error *ngIf="form.hasError('invalidAutocompleteString','city_of_residence')">
                        <span>¡Advertencia! Este campo solo admite campos sugeridos</span>
                    </mat-error>
                    <mat-error *ngIf="form.hasError('pattern', 'city_of_residence')">
                        <span>¡Advertencia! Este campo no recibe números ni caracteres especiales</span>
                    </mat-error>
                    <mat-error *ngIf="form.hasError('required', 'city_of_residence')">
                        <span>¡Advertencia! Este campo es requerido</span>
                    </mat-error>
                    <mat-error *ngIf="form.hasError('trimErrorInit', 'city_of_residence')">
                        <span>¡Advertencia!Este campo no acepta espacios en blanco al inicio</span>
                    </mat-error>
                    <mat-error *ngIf="form.hasError('trimErrorLast', 'city_of_residence')">
                        <span>¡Advertencia! Este campo no acepta espacios en blanco al final</span>
                    </mat-error>
                </mat-form-field>
            
                <mat-form-field appearance="fill" fxFlex=30>
                    <mat-label>Localidad</mat-label>
                    <input matInput [matAutocomplete]="auto3" placeholder="Seleccione una localidad" formControlName="location"
                        autocomplete="off" required>
                    <mat-autocomplete #auto3="matAutocomplete"
                        [displayWith]="displayFn.bind(this, 'location', 'neighborhood', 'locality')">
                        <mat-option *ngFor="let option of filteredLocalityOpt | async" [value]="option.name">
                            <span>{{option.name}}</span>
                        </mat-option>
                    </mat-autocomplete>
                    <mat-error *ngIf="form.hasError('pattern', 'location')">
                        <span>¡Advertencia! Este campo no recibe números ni caracteres especiales</span>
                    </mat-error>
                    <mat-error *ngIf="form.hasError('required', 'location')">
                        <span>¡Advertencia! Este campo es requerido</span>
                    </mat-error>
                    <mat-error *ngIf="form.hasError('trimErrorInit', 'location')">
                        <span>¡Advertencia!Este campo no acepta espacios en blanco al inicio</span>
                    </mat-error>
                    <mat-error *ngIf="form.hasError('trimErrorLast', 'location')">
                        <span>¡Advertencia! Este campo no acepta espacios en blanco al final</span>
                    </mat-error>
                </mat-form-field>
                <mat-form-field appearance="fill" fxFlex=30>
                    <mat-label>Barrio</mat-label>
                    <input matInput [matAutocomplete]="auto4" placeholder="Seleccione una barrio" formControlName="neighborhood"
                        autocomplete="off" required>
                    <mat-autocomplete #auto4="matAutocomplete"
                        [displayWith]="displayFn.bind(this, 'neighborhood', 'locality', 'neighborhood')">
                        <mat-option *ngFor="let option of filteredNeighborhoodOpt | async" [value]="option.name">
                            <span>{{option.name}}</span>
                        </mat-option>
                    </mat-autocomplete>
                    <mat-error *ngIf="form.hasError('pattern', 'neighborhood')">
                        <span>¡Advertencia! Este campo no recibe números ni caracteres especiales</span>
                    </mat-error>
                    <mat-error *ngIf="form.hasError('required', 'neighborhood')">
                        <span>¡Advertencia! Este campo es requerido</span>
                    </mat-error>
                    <mat-error *ngIf="form.hasError('trimErrorInit', 'neighborhood')">
                        <span>¡Advertencia!Este campo no acepta espacios en blanco al inicio</span>
                    </mat-error>
                    <mat-error *ngIf="form.hasError('trimErrorLast', 'neighborhood')">
                        <span>¡Advertencia! Este campo no acepta espacios en blanco al final</span>
                    </mat-error>
                </mat-form-field>
                <mat-form-field appearance="fill" fxFlex=30>
                    <mat-label>Estrato</mat-label>
                    <mat-select formControlName="stratum" required>
                        <mat-option value=1>1</mat-option>
                        <mat-option value=2>2</mat-option>
                        <mat-option value=3>3</mat-option>
                        <mat-option value=4>4</mat-option>
                        <mat-option value=5>5</mat-option>
                        <mat-option value=6>6</mat-option>
                        <mat-option value=7>7</mat-option>
                    </mat-select>
                </mat-form-field>
                <mat-form-field appearance="fill" fxFlex=30>
                    <mat-label>Dirección</mat-label>
                    <input matInput formControlName="adress" required readonly (click)="showAddressForm()">
                </mat-form-field>
                <mat-form-field appearance="fill" fxFlex=46>
                    <mat-label>Tipo de Vivienda</mat-label>
                    <mat-select formControlName="housing_type" required>
                        <mat-option value=Familiar>Familiar</mat-option>
                        <mat-option value=Arriendo>Arriendo</mat-option>
                        <mat-option value=Propia>Propia</mat-option>
                    </mat-select>
                </mat-form-field> 
                
                <mat-form-field appearance="fill" fxFlex=46>
                    <mat-label>Aspiración Salarial</mat-label>
                    <mat-select matNativeControl formControlName="wage_aspiration" required>
                        <mat-option value="1 a 2 Salarios mínimos mensuales">1 a 2 Salarios mínimos mensuales</mat-option>
                        <mat-option value="2 a 3 Salarios mínimos mensuales">2 a 3 Salarios mínimos mensuales</mat-option>
                        <mat-option value="3 a 4 Salarios mínimos mensuales">3 a 4 Salarios mínimos mensuales</mat-option>
                        <mat-option value="4 a 5 Salarios mínimos mensuales">4 a 5 Salarios mínimos mensuales</mat-option>
                        <mat-option value="5 a 6 Salarios mínimos mensuales">5 a 6 Salarios mínimos mensuales</mat-option>
                        <mat-option value="6 a 7 Salarios mínimos mensuales">6 a 7 Salarios mínimos mensuales</mat-option>
                        <mat-option value="7 a 8 Salarios mínimos mensuales">7 a 8 Salarios mínimos mensuales</mat-option>
                        <mat-option value="8 a 9 Salarios mínimos mensuales">8 a 9 Salarios mínimos mensuales</mat-option>
                        <mat-option value="9 a 10 Salarios mínimos mensuales">9 a 10 Salarios mínimos mensuales</mat-option>
                        <mat-option value="10 a 11 Salarios mínimos mensuales">10 a 11 Salarios mínimos mensuales</mat-option>
                        <mat-option value="11 a 12 Salarios mínimos mensuales">11 a 12 Salarios mínimos mensuales</mat-option>
                        <mat-option value="12 a 13 Salarios mínimos mensuales">12 a 13 Salarios mínimos mensuales</mat-option>
                        <mat-option value="13 a 14 Salarios mínimos mensuales">13 a 14 Salarios mínimos mensuales</mat-option>
                        <mat-option value="14 o más Salarios mínimos mensuales">14 o más Salarios mínimos mensuales</mat-option>
                    </mat-select>
                </mat-form-field>  

                <mat-form-field appearance="fill" fxFlex=46>
                    <mat-label>Hobbies</mat-label>
                    <input matInput formControlName="hobbie"  required>
                </mat-form-field>              
            
                <mat-form-field appearance="fill" fxFlex=46>
                    <mat-label>En caso de emergencia contactar a</mat-label>
                    <input matInput formControlName="emergency_contact" required>
                    <mat-error *ngIf="form.hasError('pattern', 'emergency_contact')">
                        <span>¡Advertencia! Este campo no recibe números ni caracteres especiales</span>
                    </mat-error>
                    <mat-error *ngIf="form.hasError('required', 'emergency_contact')">
                        <span>¡Advertencia! Este campo es requerido</span>
                    </mat-error>
                    <mat-error *ngIf="form.hasError('trimErrorInit', 'emergency_contact')">
                        <span>¡Advertencia!Este campo no acepta espacios en blanco al inicio</span>
                    </mat-error>
                    <mat-error *ngIf="form.hasError('trimErrorLast', 'emergency_contact')">
                        <span>¡Advertencia! Este campo no acepta espacios en blanco al final</span>
                    </mat-error>
                </mat-form-field>
            
                <mat-form-field appearance="fill" fxFlex=30>
                    <mat-label>Número telefónico contacto de emergencia</mat-label>
                    <input matInput formControlName="emergency_number" type="number" required>
                    <mat-error *ngIf="form.hasError('pattern', 'emergency_number')">
                        <span>¡Advertencia! Este campo no recibe letras ni caracteres especiales</span>
                    </mat-error>
                    <mat-error *ngIf="form.hasError('required', 'emergency_number')">
                        <span>¡Advertencia! Este campo es requerido</span>
                    </mat-error>
                    <mat-error *ngIf="form.hasError('trimErrorInit', 'emergency_number')">
                        <span>¡Advertencia!Este campo no acepta espacios en blanco al inicio</span>
                    </mat-error>
                    <mat-error *ngIf="form.hasError('trimErrorLast', 'emergency_number')">
                        <span>¡Advertencia! Este campo no acepta espacios en blanco al final</span>
                    </mat-error>
                </mat-form-field>  
            
                <mat-form-field appearance="fill" fxFlex=30>
                    <mat-label>Talla Zapatos</mat-label>
                    <input matInput formControlName="shoe_size" type="number"  required>
                </mat-form-field>
            
                <mat-form-field appearance="fill" fxFlex=30>
                    <mat-label>Talla de Camisa</mat-label>
                    <input matInput formControlName="shirt_size"  required>
                </mat-form-field>
            
                <mat-form-field appearance="fill" fxFlex=30>
                    <mat-label>Talla de Pantalón</mat-label>
                    <input matInput formControlName="pants_size"  required>
                </mat-form-field>
            </div>
        </mat-expansion-panel>
    </mat-accordion>    

    <mat-accordion  fxFlex=100>       
        <mat-expansion-panel >
          <mat-expansion-panel-header>
            <mat-panel-title>
              <h4 class="pt-3">Informacion Bancaria</h4>              
            </mat-panel-title>            
          </mat-expansion-panel-header>
          <p class="w-100 p-1" style="font-size: 13px;">Si su número de cuenta tiene ceros al inicio éstos también deberán ser diligenciados</p>
          <div fxLayout="row wrap"  fxLayoutGap="20px" >

              <mat-form-field appearance="fill" fxFlex>
                  <mat-label>Banco</mat-label >
                  <mat-select formControlName="banco" >
                      <mat-option *ngFor="let option of bankData" [value]="option.name">
                          {{option.name}}
                      </mat-option>
                  </mat-select>
              </mat-form-field>
              <mat-form-field appearance="fill" fxFlex>
                  <mat-label>Tipo de cuenta</mat-label >
                  <mat-select formControlName="kind_account"  >
                      <mat-option *ngFor="let option of kindAccountList" [value]="option.name">
                          {{option.name}}
                      </mat-option>
                  </mat-select>
              </mat-form-field>
              <mat-form-field appearance="fill" fxFlex>
                  <mat-label>Número de cuenta</mat-label>
                  <input matInput formControlName="bank_account" [min]="0" (keypress)="keyPress($event)" onpaste="return false">
                  <mat-error *ngIf="form.hasError('pattern', 'bank_account')">
                      <span>¡Advertencia! Este campo recibe solo números</span>
                  </mat-error>
              </mat-form-field>
              <mat-form-field appearance="fill" fxFlex=22>
                  <mat-label>Confirmar numero de cuenta</mat-label>
                  <input matInput formControlName="bank_account_comfirm" [min]="0" (keypress)="keyPress($event)" onpaste="return false">
                  <mat-error *ngIf="form.controls.bank_account_comfirm.errors">Su cuenenta no es igual a la ingresada previamente</mat-error>
              </mat-form-field>
          </div>
        </mat-expansion-panel> 
    </mat-accordion>       

    <mat-accordion  fxFlex=100>       
        <mat-expansion-panel >
          <mat-expansion-panel-header>
            <mat-panel-title>
              <h4 class="pt-3">Informacion familiar</h4>              
            </mat-panel-title>            
          </mat-expansion-panel-header>
          <div fxLayout="row wrap"  fxLayoutGap="20px" >
                <mat-form-field appearance="fill" fxFlex=30>
                    <mat-label>Número de personas a cargo</mat-label>
                    <input matInput type="number" formControlName="number_people_charge" required>
                    <mat-error *ngIf="form.hasError('pattern', 'number_people_charge')">
                        <span>¡Advertencia! Este campo no recibe letras ni caracteres especiales</span>
                    </mat-error>
                    <mat-error *ngIf="form.hasError('required', 'number_people_charge')">
                        <span>¡Advertencia! Este campo es requerido</span>
                    </mat-error>
                    <mat-error *ngIf="form.hasError('trimErrorInit', 'number_people_charge')">
                        <span>¡Advertencia!Este campo no acepta espacios en blanco al inicio</span>
                    </mat-error>
                    <mat-error *ngIf="form.hasError('trimErrorLast', 'number_people_charge')">
                        <span>¡Advertencia! Este campo no acepta espacios en blanco al final</span>
                    </mat-error>
                </mat-form-field>

                <mat-form-field appearance="fill" fxFlex=30>
                    <mat-label>¿Cuántas personas viven en su residencia?</mat-label>
                    <input matInput type="number" formControlName="number_people_at_home" required>
                    <mat-error *ngIf="form.hasError('pattern', 'number_people_at_home')">
                        <span>¡Advertencia! Este campo no recibe letras ni caracteres especiales</span>
                    </mat-error>
                    <mat-error *ngIf="form.hasError('required', 'number_people_at_home')">
                        <span>¡Advertencia! Este campo es requerido</span>
                    </mat-error>
                    <mat-error *ngIf="form.hasError('trimErrorInit', 'number_people_at_home')">
                        <span>¡Advertencia!Este campo no acepta espacios en blanco al inicio</span>
                    </mat-error>
                    <mat-error *ngIf="form.hasError('trimErrorLast', 'number_people_at_home')">
                        <span>¡Advertencia! Este campo no acepta espacios en blanco al final</span>
                    </mat-error>
                </mat-form-field>

                <mat-form-field appearance="fill" fxFlex=30>
                    <mat-label>Número de Hijos</mat-label>
                    <input matInput type="number" formControlName="children_number" (ngModelChange)="getChildrensInputs()" required>
                    <mat-error *ngIf="form.hasError('pattern', 'children_number')">
                        <span>¡Advertencia! Este campo no recibe letras ni caracteres especiales</span>
                    </mat-error>
                    <mat-error *ngIf="form.hasError('required', 'children_number')">
                        <span>¡Advertencia! Este campo es requerido</span>
                    </mat-error>
                    <mat-error *ngIf="form.hasError('trimErrorInit', 'children_number')">
                        <span>¡Advertencia!Este campo no acepta espacios en blanco al inicio</span>
                    </mat-error>
                    <mat-error *ngIf="form.hasError('trimErrorLast', 'children_number')">
                        <span>¡Advertencia! Este campo no acepta espacios en blanco al final</span>
                    </mat-error>
                </mat-form-field>

                <div [formGroup]="children" fxFlex=100>
                    <div fxLayout="row wrap" *ngFor="let child of children.controls; let i = index" [formGroupName]="i">
                        <mat-form-field appearance="fill" fxFlex=62 style="margin-right: 20px;">
                            <mat-label>Nombre completo</mat-label>
                            <input matInput type="text" formControlName="name" placeholder="Nombre completo de su hijo">
                        </mat-form-field>
                        <mat-form-field appearance="fill" fxFlex=30>
                            <mat-label>Edad</mat-label>
                            <input matInput type="number" formControlName="age" placeholder="Edad de su hijo">
                        </mat-form-field>
                    </div>
                </div>
          </div>
        </mat-expansion-panel >
    </mat-accordion> 

    <mat-accordion  fxFlex=100>       
        <mat-expansion-panel >
          <mat-expansion-panel-header>
            <mat-panel-title>
              <h4 class="pt-3">Situacion militar</h4>              
            </mat-panel-title>            
          </mat-expansion-panel-header>
          <div fxLayout="row wrap"  fxLayoutGap="20px" >
            <mat-form-field appearance="fill" fxFlex=30>
                <mat-label>Situación Militar Definida</mat-label>
                <mat-select matNativeControl formControlName="military_situation"
                    (ngModelChange)="checkDependence($event, 'Si', 'militarySituation', militaryChildren)" required>
                    <mat-option value="Si">Si</mat-option>
                    <mat-option value="No">No</mat-option>
                    <mat-option value="No aplica">No aplica</mat-option>
                </mat-select>
            </mat-form-field>

            <mat-form-field appearance="fill" fxFlex=30 *ngIf="militarySituation || form.value.notebook_number != ''">
                <mat-label>Número de Libreta</mat-label>
                <input type="number" matInput formControlName="notebook_number" type="notebook_number">
                <mat-error *ngIf="form.hasError('pattern', 'notebook_number')">
                    <span>¡Advertencia! Este campo no recibe letras ni caracteres especiales</span>
                </mat-error>
                <mat-error *ngIf="form.hasError('trimErrorInit', 'notebook_number')">
                    <span>¡Advertencia!Este campo no acepta espacios en blanco al inicio</span>
                </mat-error>
                <mat-error *ngIf="form.hasError('trimErrorLast', 'notebook_number')">
                    <span>¡Advertencia! Este campo no acepta espacios en blanco al final</span>
                </mat-error>
            </mat-form-field>
        
            <mat-form-field appearance="fill" fxFlex=30 *ngIf="militarySituation || form.value.notebook_class != ''">
                <mat-label>Clase de Libreta</mat-label>
                <mat-select matNativeControl formControlName="notebook_class">
                    <mat-option value="Primera categoría">Primera categoría</mat-option>
                    <mat-option value="Segunda categoría">Segunda categoría</mat-option>
                </mat-select>
            </mat-form-field>
        
            <mat-form-field appearance="fill" fxFlex=30 *ngIf="militarySituation || form.value.military_district_number != ''">
                <mat-label>Número de Distrito Militar</mat-label>
                <input matInput formControlName="notebook_number" type="military_district_number">
            </mat-form-field>
          </div>   
        </mat-expansion-panel>
    </mat-accordion>  

    <mat-accordion  fxFlex=100>       
        <mat-expansion-panel >
          <mat-expansion-panel-header>
            <mat-panel-title>
              <h4 class="pt-3">Desplazamiento</h4>              
            </mat-panel-title>            
          </mat-expansion-panel-header>
          <div fxLayout="row wrap"  fxLayoutGap="20px" >
            <mat-form-field appearance="fill" fxFlex=30>
                <mat-label>Tiempo de Desplazamiento</mat-label>
                <mat-select matNativeControl formControlName="travel_time" required>
                    <mat-option value="1 a 30 minutos">1 a 30 minutos</mat-option>
                    <mat-option value="30 a 60 minutos">30 a 60 minutos</mat-option>
                    <mat-option value="60 a 90 minutos">60 a 90 minutos</mat-option>
                    <mat-option value="más de 120 minutos">más de 120 minutos</mat-option>
                </mat-select>
            </mat-form-field>
        
            <mat-form-field appearance="fill" fxFlex=30>
                <mat-label>Tiene Vehículo</mat-label>
                <mat-select matNativeControl formControlName="vehicle"
                    (ngModelChange)="checkDependence($event, 'Si', 'haveCar', carChildren)">
                    <mat-option value="Si">Si</mat-option>
                    <mat-option value="No">No</mat-option>
                </mat-select>
            </mat-form-field>
        
            <mat-form-field appearance="fill" fxFlex=30>
                <mat-label>Tipo de desplazamiento</mat-label>
                <mat-select matNativeControl formControlName="vehicle_type">
                    <mat-option value="Automovil">Automovil</mat-option>
                    <mat-option value="Motocicleta">Motocicleta</mat-option>
                    <mat-option value="Bicicleta">Bicicleta</mat-option>
                    <mat-option value="Transporte público">Transporte público</mat-option>
                </mat-select>
            </mat-form-field>
        
            <mat-form-field appearance="fill" fxFlex=30 *ngIf="haveCar || form.value.licence_number != ''">
                <mat-label># Licencia de conducción</mat-label>
                <input matInput type="text" formControlName="licence_number">
                <mat-error *ngIf="form.hasError('pattern', 'licence_number')">
                    <span>¡Advertencia! Este campo solo recibe números</span>
                </mat-error>
                <mat-error *ngIf="form.hasError('trimErrorInit', 'licence_number')">
                    <span>¡Advertencia!Este campo no acepta espacios en blanco al inicio</span>
                </mat-error>
                <mat-error *ngIf="form.hasError('trimErrorLast', 'licence_number')">
                    <span>¡Advertencia! Este campo no acepta espacios en blanco al final</span>
                </mat-error>
            </mat-form-field>
        
            <mat-form-field appearance="fill" fxFlex=30 *ngIf="haveCar || form.value.licence_type != ''">
                <mat-label>Categoría Licencia</mat-label>
                <mat-select matNativeControl formControlName="licence_type">
                    <mat-option value="A1">A1</mat-option>
                    <mat-option value="A2">A2</mat-option>
                    <mat-option value="B1">B1</mat-option>
                    <mat-option value="B2">B2</mat-option>
                    <mat-option value="B3">B3</mat-option>
                    <mat-option value="C1">C1</mat-option>
                    <mat-option value="C2">C2</mat-option>
                    <mat-option value="C3">C3</mat-option>
                </mat-select>
            </mat-form-field>

          </div>        

        </mat-expansion-panel>
    </mat-accordion>    
  
</form>