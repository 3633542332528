import { Injectable } from '@angular/core';
import { CanActivate, CanActivateChild, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthServicesService } from 'src/app/auth/services/auth.service';


@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate, CanActivateChild {
  constructor(private authService: AuthServicesService, private router: Router){

  }
  canActivate(){
    if (this.authService.verifyTokent()){
      return true;
    }else{
       this.router.navigate(['/auth/login']);
       return false;
    }
  }
  canActivateChild(){
    if (this.authService.verifyTokent()){
      return true;
    }else{
       this.router.navigate(['/auth/login']);
       return false;
    }
  }

}
