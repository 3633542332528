
<br>
<form fxLayout="row wrap" fxLayout.xs="column" fxLayoutGap="35px" [formGroup]="form">
    <mat-form-field appearance="fill" fxFlex=30>
        <mat-label>Primer Nombre</mat-label>
        <input matInput formControlName="first_name"  required>
        <mat-error *ngIf="form.hasError('required', 'first_name')">
            <span>¡Advertencia! Este campo es requerido</span>
        </mat-error>
        <mat-error *ngIf="form.hasError('pattern', 'first_name')">
            <span>¡Advertencia! Este campo no recibe números ni caracteres especiales</span>
        </mat-error>
        <mat-error *ngIf="form.hasError('trimErrorInit', 'first_name')">
            <span>¡Advertencia!Este campo no acepta espacios en blanco al inicio</span>
        </mat-error>
        <mat-error *ngIf="form.hasError('trimErrorLast', 'first_name')">
            <span>¡Advertencia! Este campo no acepta espacios en blanco al final</span>
        </mat-error>
    </mat-form-field>

    <mat-form-field appearance="fill" fxFlex=30>
        <mat-label>Segundo Nombre</mat-label>
        <input matInput formControlName="middle_name">
        <mat-error *ngIf="form.hasError('pattern', 'middle_name')">
            <span>¡Advertencia! Este campo no recibe números ni caracteres especiales</span>
        </mat-error>
        <mat-error *ngIf="form.hasError('trimErrorInit', 'middle_name')">
            <span>¡Advertencia!Este campo no acepta espacios en blanco al inicio</span>
        </mat-error>
        <mat-error *ngIf="form.hasError('trimErrorLast', 'middle_name')">
            <span>¡Advertencia! Este campo no acepta espacios en blanco al final</span>
        </mat-error>
    </mat-form-field>

    <mat-form-field appearance="fill" fxFlex=30>
        <mat-label>Primer Apellido</mat-label>
        <input matInput formControlName="last_name"  required>
        <mat-error *ngIf="form.hasError('required', 'last_name')">
            <span>¡Advertencia! Este campo es requerido</span>
        </mat-error>
        <mat-error *ngIf="form.hasError('pattern', 'last_name')">
            <span>¡Advertencia! Este campo no recibe números ni caracteres especiales</span>
        </mat-error>
        <mat-error *ngIf="form.hasError('trimErrorInit', 'last_name')">
            <span>¡Advertencia!Este campo no acepta espacios en blanco al inicio</span>
        </mat-error>
        <mat-error *ngIf="form.hasError('trimErrorLast', 'last_name')">
            <span>¡Advertencia! Este campo no acepta espacios en blanco al final</span>
        </mat-error>
    </mat-form-field>

    <mat-form-field appearance="fill" fxFlex=30>
        <mat-label>Segundo Apellido</mat-label>
        <input matInput formControlName="second_last_name">
        <mat-error *ngIf="form.hasError('pattern', 'second_last_name')">
            <span>¡Advertencia! Este campo no recibe números ni caracteres especiales</span>
        </mat-error>
        <mat-error *ngIf="form.hasError('trimErrorInit', 'second_last_name')">
            <span>¡Advertencia!Este campo no acepta espacios en blanco al inicio</span>
        </mat-error>
        <mat-error *ngIf="form.hasError('trimErrorLast', 'second_last_name')">
            <span>¡Advertencia! Este campo no acepta espacios en blanco al final</span>
        </mat-error>
    </mat-form-field>

    <mat-form-field appearance="fill" fxFlex=30>
        <mat-label>Parentesco</mat-label>
        <mat-select matNativeControl formControlName="relationship"  required>
            <mat-option value="Madre">Madre</mat-option>
            <mat-option value="Padre">Padre</mat-option>
            <mat-option value="Hijo">Hijo</mat-option>
            <mat-option value="Hijastro">Hijastro</mat-option>
            <mat-option value="Conyuge">Conyuge</mat-option>
            <mat-option value="Padrastro">Padrastro</mat-option>
            <mat-option value="Compañero permanente">Compañero permanente</mat-option>
            <mat-option value="Tío">Tío</mat-option>
            <mat-option value="Sobrino">Sobrino</mat-option>
            <mat-option value="Abuelo">Abuelo</mat-option>
            <mat-option value="Hermano">Hermano</mat-option>
            <mat-option value="Nieto">Nieto</mat-option>
            <mat-option value="Cuñado">Cuñado</mat-option>
            <mat-option value="Suegro">Suegro</mat-option>
            <mat-option value="Primo">Primo</mat-option>
        </mat-select>
        <mat-error *ngIf="form.hasError('required', 'relationship')">
            <span>¡Advertencia! Este campo es requerido</span>
        </mat-error>
    </mat-form-field>

    <mat-form-field appearance="fill" fxFlex=30>
        <mat-label>Celular</mat-label>
        <input type="number" matInput formControlName="mobile_phone"  required>
        <mat-error *ngIf="form.hasError('required', 'mobile_phone')">
            <span>¡Advertencia! Este campo es requerido</span>
        </mat-error>
        <mat-error *ngIf="form.hasError('pattern', 'mobile_phone')">
            <span>¡Advertencia! Este campo no recibe números ni caracteres especiales</span>
        </mat-error>
        <mat-error *ngIf="form.hasError('trimErrorInit', 'mobile_phone')">
            <span>¡Advertencia!Este campo no acepta espacios en blanco al inicio</span>
        </mat-error>
        <mat-error *ngIf="form.hasError('trimErrorLast', 'mobile_phone')">
            <span>¡Advertencia! Este campo no acepta espacios en blanco al final</span>
        </mat-error>
    </mat-form-field>

    <mat-form-field appearance="fill" fxFlex=30>
        <mat-label>Teléfono (Opcional)</mat-label>
        <input type="number" matInput formControlName="phone">
    </mat-form-field>

    <mat-form-field appearance="fill" fxFlex=30>
        <mat-label>Ciudad</mat-label>
        <input matInput formControlName="city"  required>
        <mat-error *ngIf="form.hasError('required', 'city')">
            <span>¡Advertencia! Este campo es requerido</span>
        </mat-error>
        <mat-error *ngIf="form.hasError('pattern', 'city')">
            <span>¡Advertencia! Este campo no recibe números ni caracteres especiales</span>
        </mat-error>
        <mat-error *ngIf="form.hasError('trimErrorInit', 'city')">
            <span>¡Advertencia!Este campo no acepta espacios en blanco al inicio</span>
        </mat-error>
        <mat-error *ngIf="form.hasError('trimErrorLast', 'city')">
            <span>¡Advertencia! Este campo no acepta espacios en blanco al final</span>
        </mat-error>
    </mat-form-field>

    <mat-form-field appearance="fill" fxFlex=30>
        <mat-label>Ocupación</mat-label>
        <mat-select matNativeControl formControlName="occupation"  required>
            <mat-option value="Empleado">Empleado</mat-option>
            <mat-option value="Desempleado">Desempleado</mat-option>
        </mat-select>
        <mat-error *ngIf="form.hasError('required', 'occupation')">
            <span>¡Advertencia! Este campo es requerido</span>
        </mat-error>
        <mat-error *ngIf="form.hasError('pattern', 'occupation')">
            <span>¡Advertencia! Este campo no recibe números ni caracteres especiales</span>
        </mat-error>
        <mat-error *ngIf="form.hasError('trimErrorInit', 'occupation')">
            <span>¡Advertencia!Este campo no acepta espacios en blanco al inicio</span>
        </mat-error>
        <mat-error *ngIf="form.hasError('trimErrorLast', 'occupation')">
            <span>¡Advertencia! Este campo no acepta espacios en blanco al final</span>
        </mat-error>
    </mat-form-field>

    <div fxFlex=30 fxLayoutAlign="center center">
        <button mat-stroked-button color="accent" (click)="_actions.add(dataSourceReferences,'personal_references',personalReferences, form, candidateId)" [disabled]="!form.valid">Agregar referencia</button>
    </div>

    <hr fxFlex=100>
    <div fxFlex=100 class="table_data">
        <table mat-table [dataSource]="dataSourceReferences">

            <ng-container matColumnDef="first_name">
                <th mat-header-cell *matHeaderCellDef class="start"> Nombre </th>
                <td mat-cell *matCellDef="let element"> {{element.first_name}} </td>
            </ng-container>

            <ng-container matColumnDef="last_name">
                <th mat-header-cell *matHeaderCellDef> Apellido </th>
                <td mat-cell *matCellDef="let element"> {{element.last_name}} </td>
            </ng-container>

            <ng-container matColumnDef="relationship">
                <th mat-header-cell *matHeaderCellDef> Parentesco </th>
                <td mat-cell *matCellDef="let element"> {{element.relationship}} </td>
            </ng-container>

            <!-- city Column -->
            <ng-container matColumnDef="mobile_phone">
                <th mat-header-cell *matHeaderCellDef> Celular </th>
                <td mat-cell *matCellDef="let element"> {{element.mobile_phone}} </td>
            </ng-container>

            <!-- city Column -->
            <ng-container matColumnDef="city">
                <th mat-header-cell *matHeaderCellDef> Ciudad </th>
                <td mat-cell *matCellDef="let element"> {{element.city}} </td>
            </ng-container>

            <!-- periods Column -->
            <ng-container matColumnDef="occupation">
                <th mat-header-cell *matHeaderCellDef> Ocupación </th>
                <td mat-cell *matCellDef="let element"> {{element.occupation}} </td>
            </ng-container>

            <!-- actions Column -->
            <ng-container matColumnDef="actions">
                <th mat-header-cell *matHeaderCellDef class="end"> Acciones </th>
                <td mat-cell *matCellDef="let element">
                    <button mat-icon-button (click)="_actions.edit(element, personalReferences, form)"><span class="fi-rr-edit"></span></button>
                    <button mat-icon-button (click)="_actions.delete(dataSourceReferences, personalReferences, element, 'personal_references')">
                        <span class="fi-rr-trash"></span>
                    </button>
                </td>
            </ng-container>
            <tr class="mat-row" *matNoDataRow>
                <td class="mat-cell" colspan="7">Aún no se agregan referecial laborales</td>
            </tr>
            <tr mat-header-row *matHeaderRowDef="displayedPersonalReference"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedPersonalReference;"></tr>
        </table>
    </div>


</form>