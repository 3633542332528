<br>
<form fxLayout="row wrap"  fxLayout.xs="column" fxLayoutGap="35px" [formGroup]="form">
    <mat-form-field appearance="fill" fxFlex=30 fxFlex.sm=44>
        <mat-label>Tipo de documento</mat-label>
        <mat-select formControlName="id_type_id"  required >
            <mat-option *ngFor="let idType of idTypes" [value]="idType.id" (click)="selectedIdtype(idType.key)">
                {{idType.name}}
            </mat-option>
        </mat-select>
    </mat-form-field>

    <mat-form-field appearance="fill"  fxFlex="30" fxFlex.sm=44>
        <mat-label *ngIf="keyType != 'PET' && keyType != 'PPT' && keyType != 'PEP' && keyType != 'CE' && keyType != 'PTT'" >Número de documento</mat-label>
        <mat-label *ngIf="keyType == 'PET' || keyType == 'PPT' || keyType == 'PEP' || keyType == 'CE' || keyType == 'PTT'" >{{keyType}}</mat-label>
        <input matInput  formControlName="id_number">
        <mat-error *ngIf="form.hasError('required', 'id_number')">
            <span>¡Advertencia! Este campo es requerido</span>
        </mat-error>
        <mat-error *ngIf="form.hasError('minlength', 'id_number')">
            <span>¡Advertencia! Este campo recibe mínimo 4 caracteres</span>
        </mat-error>
        <mat-error *ngIf="form.hasError('maxlength', 'id_number')">
            <span>¡Advertencia! Este campo recibe máximo 20 caracteres</span>
        </mat-error>
        <mat-error *ngIf="form.hasError('pattern', 'id_number')">
            <span>¡Advertencia! Este campo recibe solo números</span>
        </mat-error>
    </mat-form-field>
    <mat-form-field appearance="fill"  fxFlex="30" fxFlex.sm=44>
        <mat-label *ngIf="form.errors?.idNumberMismatch != true && keyType != 'PET' && keyType != 'PPT' && keyType != 'PEP' && keyType != 'CE' && keyType != 'PTT'" >Verificar número de documento</mat-label>
        <mat-label *ngIf="form.errors?.idNumberMismatch != true && keyType == 'PET' || keyType == 'PPT' || keyType == 'PEP' || keyType == 'CE' || keyType == 'PTT'" >Verificar {{keyType}}</mat-label>
        <mat-label *ngIf="form.errors?.idNumberMismatch == true" class="error-message" >Verificar número de documento</mat-label>
        <input matInput  formControlName="id_number_verify">
        <mat-error *ngIf="form.hasError('required', 'id_number_verify')">
            <span>¡Advertencia! Este campo es requerido</span>
        </mat-error>
        <mat-error *ngIf="form.hasError('minlength', 'id_number_verify')">
            <span>¡Advertencia! Este campo recibe mínimo 4 caracteres</span>
        </mat-error>
        <mat-error *ngIf="form.hasError('maxlength', 'id_number_verify')">
            <span>¡Advertencia! Este campo recibe máximo 20 caracteres</span>
        </mat-error>
        <mat-error *ngIf="form.hasError('pattern', 'id_number_verify')">
            <span>¡Advertencia! Este campo recibe solo números</span>
        </mat-error>
    </mat-form-field>

    <mat-form-field appearance="fill"  fxFlex="30" fxFlex.sm=44 *ngIf="keyType == 'PET' || keyType == 'PPT' || keyType == 'PEP' || keyType == 'CE' || keyType == 'PTT'">
        <mat-label>Número de pasaporte</mat-label>
        <input matInput  formControlName="number_PA">
        <mat-error *ngIf="form.hasError('minlength', 'number_PA')">
            <span>¡Advertencia! Este campo recibe mínimo 4 caracteres</span>
        </mat-error>
        <mat-error *ngIf="form.hasError('maxlength', 'number_PA')">
            <span>¡Advertencia! Este campo recibe máximo 20 caracteres</span>
        </mat-error>
        <mat-error *ngIf="form.hasError('required', 'number_PA')">
            <span>¡Advertencia! Este campo es requerido</span>
        </mat-error>
    </mat-form-field>

    <mat-form-field appearance="fill"  fxFlex="30" fxFlex.sm=44 *ngIf="keyType == 'PET' || keyType == 'PPT' || keyType == 'PEP' || keyType == 'CE' || keyType == 'PTT'">
        <mat-label>Verificar número de pasaporte</mat-label>
        <input matInput  formControlName="number_PA_verify">
        <mat-error *ngIf="form.hasError('minlength', 'number_PA_verify')">
            <span>¡Advertencia! Este campo recibe mínimo 4 caracteres</span>
        </mat-error>
        <mat-error *ngIf="form.hasError('maxlength', 'number_PA_verify')">
            <span>¡Advertencia! Este campo recibe máximo 20 caracteres</span>
        </mat-error>
        <mat-error *ngIf="form.hasError('required', 'number_PA_verify')">
            <span>¡Advertencia! Este campo es requerido</span>
        </mat-error>
        <mat-error *ngIf="form.hasError('invalidFieldpa', 'number_PA_verify')">
            <span>¡Advertencia! los campos no coinciden</span>
        </mat-error>
    </mat-form-field>

    <mat-form-field appearance="fill"  fxFlex="30" fxFlex.sm=44>
        <mat-label>Primer Nombre</mat-label>
        <input matInput formControlName="first_name"  required>
        <mat-error *ngIf="form.hasError('required', 'first_name')">
            <span>¡Advertencia! Este campo es requerido</span>
        </mat-error>
        <mat-error *ngIf="form.hasError('pattern', 'first_name')">
            <span>¡Advertencia! Este campo no acepta caracteres especiales ni números</span>
        </mat-error>
        <mat-error *ngIf="form.hasError('trimErrorInit', 'first_name')">
            <span>¡Advertencia!Este campo no acepta espacios en blanco al inicio</span>
        </mat-error>
        <mat-error *ngIf="form.hasError('trimErrorLast', 'first_name')">
            <span>¡Advertencia! Este campo no acepta espacios en blanco al final</span>
        </mat-error>
    </mat-form-field>

    <mat-form-field appearance="fill" fxFlex="30" fxFlex.sm=44>
        <mat-label>Segundo Nombre</mat-label>
        <input matInput formControlName="middle_name">
        <mat-error *ngIf="form.hasError('required', 'middle_name')">
            <span>¡Advertencia! Este campo es requerido</span>
        </mat-error>
        <mat-error *ngIf="form.hasError('pattern', 'middle_name')">
          <span>¡Advertencia! Este campo no acepta caracteres especiales ni números</span>
        </mat-error>
        <mat-error *ngIf="form.hasError('trimErrorInit', 'middle_name')">
            <span>¡Advertencia!Este campo no acepta espacios en blanco al inicio</span>
        </mat-error>
        <mat-error *ngIf="form.hasError('trimErrorLast', 'middle_name')">
            <span>¡Advertencia! Este campo no acepta espacios en blanco al final</span>
        </mat-error>
    </mat-form-field>

    <mat-form-field appearance="fill" fxFlex="30" fxFlex.sm=44>
        <mat-label>Primer Apellido</mat-label>
        <input matInput formControlName="last_name"  required>
        <mat-error *ngIf="form.hasError('required', 'last_name')">
            <span>¡Advertencia! Este campo es requerido</span>
        </mat-error>
        <mat-error *ngIf="form.hasError('pattern', 'last_name')">
          <span>¡Advertencia! Este campo no acepta caracteres especiales ni números</span>
        </mat-error>
        <mat-error *ngIf="form.hasError('trimErrorInit', 'last_name')">
            <span>¡Advertencia!Este campo no acepta espacios en blanco al inicio</span>
        </mat-error>
        <mat-error *ngIf="form.hasError('trimErrorLast', 'last_name')">
            <span>¡Advertencia! Este campo no acepta espacios en blanco al final</span>
        </mat-error>
    </mat-form-field>

    <mat-form-field appearance="fill" fxFlex="30" fxFlex.sm=44>
        <mat-label>Segundo Apellido</mat-label>
        <input matInput formControlName="second_last_name">
        <mat-error *ngIf="form.hasError('required', 'second_last_name')">
            <span>¡Advertencia! Este campo es requerido</span>
        </mat-error>
        <mat-error *ngIf="form.hasError('pattern', 'second_last_name')">
          <span>¡Advertencia! Este campo no acepta caracteres especiales ni números</span>
        </mat-error>
        <mat-error *ngIf="form.hasError('trimErrorInit', 'second_last_name')">
            <span>¡Advertencia!Este campo no acepta espacios en blanco al inicio</span>
        </mat-error>
        <mat-error *ngIf="form.hasError('trimErrorLast', 'second_last_name')">
            <span>¡Advertencia! Este campo no acepta espacios en blanco al final</span>
        </mat-error>
    </mat-form-field>

    <mat-form-field appearance="fill" fxFlex="30"  fxFlex.sm=44>
        <mat-label>Fecha Nacimiento</mat-label>
        <input matInput [matDatepicker]="picker" formControlName="birth_date"
        (dateChange)="ageCalculator($event)" [max]="maxDate" [min]="minDate"  required>
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
        <mat-error *ngIf="form.hasError('invalidDate', 'birth_date')">
            <span>¡Advertencia! Este campo recibe no una fecha inferior a 15 años</span>
        </mat-error>
    </mat-form-field>

    <mat-form-field appearance="fill" fxFlex="30" fxFlex.sm=44>
        <mat-label>Estado Civil</mat-label>
        <mat-select formControlName="civil_state_id">
          <mat-option *ngFor="let civilState of civilStates" [value]="civilState.id">
            {{civilState.name}}
          </mat-option>
        </mat-select>
    </mat-form-field>

    <mat-form-field appearance="fill" fxFlex="30" fxFlex.sm=44>
        <mat-label>Email</mat-label>
        <input type="email" matInput formControlName="email">
        <mat-error *ngIf="form.hasError('required', 'email')">
            <span>¡Advertencia! Este campo es requerido</span>
        </mat-error>
        <mat-error *ngIf="form.hasError('pattern', 'email')">
            <span>¡Advertencia! Email no valido.</span>
        </mat-error>
        <mat-error *ngIf="form.hasError('trimErrorInit', 'email')">
            <span>¡Advertencia!Este campo no acepta espacios en blanco al inicio</span>
        </mat-error>
        <mat-error *ngIf="form.hasError('trimErrorLast', 'email')">
            <span>¡Advertencia! Este campo no acepta espacios en blanco al final</span>
        </mat-error>
    </mat-form-field>

    <mat-form-field appearance="fill" fxFlex="30" fxFlex.sm=44>
        <mat-label>Email(Opcional)</mat-label>
        <input type="email" matInput formControlName="email2">
        <mat-error *ngIf="form.hasError('pattern', 'email2')">
            <span>¡Advertencia! Email no valido.</span>
        </mat-error>
    </mat-form-field>

    <mat-form-field appearance="fill" fxFlex="30" fxFlex.sm=44>
        <mat-label>Celular</mat-label>
        <input matInput formControlName="mobile_phone"  required>
        <mat-error *ngIf="form.hasError('pattern', 'mobile_phone')">
            <span>¡Advertencia! Este campo recibe solo números</span>
        </mat-error>
        <mat-error *ngIf="form.hasError('required', 'mobile_phone')">
            <span>¡Advertencia! Este campo es requerido</span>
        </mat-error>
        <mat-error *ngIf="form.hasError('trimErrorInit', 'mobile_phone')">
            <span>¡Advertencia!Este campo no acepta espacios en blanco al inicio</span>
        </mat-error>
        <mat-error *ngIf="form.hasError('trimErrorLast', 'mobile_phone')">
            <span>¡Advertencia! Este campo no acepta espacios en blanco al final</span>
        </mat-error>
    </mat-form-field>

    <mat-form-field appearance="fill" fxFlex="30" fxFlex.sm=44>
        <mat-label>Teléfono (Opcional)</mat-label>
        <input matInput formControlName="phone">
        <mat-error *ngIf="form.hasError('pattern', 'phone')">
            <span>¡Advertencia! Este campo recibe solo números</span>
        </mat-error>
        <mat-error *ngIf="form.hasError('trimErrorInit', 'phone')">
            <span>¡Advertencia!Este campo no acepta espacios en blanco al inicio</span>
        </mat-error>
        <mat-error *ngIf="form.hasError('trimErrorLast', 'phone')">
            <span>¡Advertencia! Este campo no acepta espacios en blanco al final</span>
        </mat-error>
    </mat-form-field>
</form>
