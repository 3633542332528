import { Component, OnInit , Input, ViewChild, OnChanges} from '@angular/core';
import { FormBuilder, FormGroup, RequiredValidator, Validators } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import {MatSort} from '@angular/material/sort';
import { TablesActionsService } from 'src/app/candidate/services/util/tables-actions.service';
import { HttpParams } from '@angular/common/http';
import { FormsSteperService } from 'src/app/candidate/services/rest/forms-steper.service';
import { AuthServicesService } from 'src/app/auth/services/auth.service';

@Component({
  selector: 'candidate-fifth-step',
  templateUrl: './fifth-step.component.html',
  styleUrls: ['./fifth-step.component.sass']
})
export class FifthStepComponent implements OnInit {
  @Input() form:FormGroup;
  @Input() personalReferences=[]
  @Input() candidateId;

  now = new Date().toISOString().split('T')[0];
  dataSourceReferences= new MatTableDataSource<any>(this.personalReferences);
  displayedPersonalReference: string[] = ['first_name', 'last_name', 'relationship', 'mobile_phone', 'city', 'occupation', 'actions'];

  constructor(public _actions: TablesActionsService,private _SteperService:FormsSteperService,private authService:AuthServicesService) {
    // this.candidateId = this.authService.systemId('RRHH');
    if ( this.candidateId == null ) {
      this.candidateId = this.authService.systemId('NOMINA');
    }

  }
  @ViewChild('sort') sort: MatSort;
  ngAfterViewInit() {
    this.dataSourceReferences.sort = this.sort;
  }

  ngOnInit(): void {
    this.getStepData();
  }

  getStepData(){
    const entries = {};
    entries['userId'] = this.candidateId;
    entries['data'] = 'personal_references';
    // entries['flag'] = this.authService.systemKey();
    const params = new HttpParams( { fromObject: entries } );
     this._SteperService.getInfo(`candidate/json/data/${this.candidateId}`, params ).subscribe( (res) => {
       if(Object.keys(res).length != 0){
          this.dataSourceReferences.data = res
          this.personalReferences = res;
       }

     } )

  }


}
