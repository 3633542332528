import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import Swal from 'sweetalert2';
import { LiderazgoService } from '../../services/liderazgo.service';
import { AuthServicesService } from 'src/app/auth/services/auth.service';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';


@Component({
  selector: 'app-base-cuestionaries',
  templateUrl: './base-cuestionaries.component.html',
  styleUrls: ['./base-cuestionaries.component.sass']
})
export class BaseCuestionariesComponent implements OnInit {

  form!: FormGroup;
  multipleCheck: any = [];
  nameBoss: any = [];
  nameAreaboss: any = [];
  rrhh_id: number = 0;
  listoption = [];
  validateEncueta: boolean = false;
  filteredOptionsBoss: Observable<string[]>
  filteredOptionsArea: Observable<string[]>

  cuestions: any = [{
    control: "nombre_jefe", type: "selectBoss", pregunta: "¿Cuál es el nombre de su jefe directo?",
    respuestas: [
      "Nombre 1",
      "Nombre 2",
      "nombre 3",
      "nombre 4",
      "nombre 5"
    ]
  },
  {
    control: "area_jefe", type: "selectArea", pregunta: "¿Cuál es el área de su jefe directo?",
    respuestas: [
      "Area 1",
      "Area 2",
      "Area 3",
      "Area 4",
      "Area 5"
    ]
  },
  {
    control: "estilo_liderazgo_jefe ", type: "checkbox", pregunta: "¿Cuál estilo de liderazgo cree que ejerce más su jefe?(puede escoger más de una opción)",
    respuestas: [
      "Autoritario (da instrucciones sin dar explicaciones)",
      "Democrático (siempre busca las mayorías cuando va tomar una decisión)",
      "Capacitador (se toma la tarea de enseñarle)",
      "Ejemplar (es un referente e inspirador para usted)",
      "Afiliativo (trabaja de la mano acompañándolo)"
    ]
  },
  { control: "tiene_capacidades_como_jefe", pregunta: "¿Considera que su jefe tiene las capacidades técnicas para asumir el rol el cuál le fue asignado?", type: "basic" },
  { control: "cumple_con_condiciones_humanas_como_jefe", pregunta: "¿Considera que su jefe cumple las condiciones aptas humanas para el cargo?", type: "basic" },
  { control: "su_jefe_es_respetuoso", pregunta: "Su jefe es respetuoso?", type: "basic" },
  { control: "calificacion_de_su_jefe", pregunta: "Si usted fuera el jefe de su jefe ¿cómo calificaría su desempeño de 1 a 10?", type: "escala", escala: 10 }

  ]

  constructor(private route: Router, private formBuilder: FormBuilder, private liderasgoServ: LiderazgoService, private authService: AuthServicesService) { }

  ngOnInit(): void {
    this.rrhh_id = this.authService.getUser().id
    this.validar()
    this.form = this.initForm();
    this.addCuestions();
    this.getBossName();
    this.getAreaName()


    this.filteredOptionsArea = this.form.controls['area_jefe'].valueChanges.pipe(
      startWith(''),
      map((value) => this._filter(value))
    );

  }
  //valida si el user ya contesto la encuesta
  validar() {
    this.liderasgoServ.validarUser(this.rrhh_id).subscribe({
      next: (val) => {        
        this.validateEncueta = val
        if (val == true) {
          Swal.fire({
            html: `Ya respondiste esta encuesta`,
            icon: 'error',
            title: 'Opss',
            confirmButtonText: 'Aceptar'
          }).then((result) => {
            if (result.isConfirmed) {
              this.route.navigate(['cos'])
            }
          })
        }
      }
    })


  }
  //Obtiene la lista de jefes
  getBossName() {
    this.liderasgoServ.getBossName().subscribe({
      next: (resp) => {
        this.nameBoss = resp
        this.filteredOptionsBoss = this.form.controls['nombre_jefe'].valueChanges.pipe(
          startWith(''),
          map((value) => this._filter(value))
        );

      },
      error: () => {
        this.nameBoss = []
      }
    })
  }

  //Obtiene la lista de Areas
  getAreaName() {
    this.liderasgoServ.getAreaBoss().subscribe({
      next: (resp) => {
        this.nameAreaboss = resp
        this.filteredOptionsArea = this.form.controls['area_jefe'].valueChanges.pipe(
          startWith(''),
          map((value) => this._filterA(value))
        );
      },
      error: () => {
        this.nameAreaboss = []
      }
    })
  }
  //envia lista de jefes o lista areas
  option(control) {
    return control === "nombre_jefe" ? this.nameBoss : this.nameAreaboss
  }

  //envia el form
  onSubmit() {
    this.form.value.estilo_liderazgo_jefe = this.multipleCheck;
    this.form.value.rrhh_id = this.rrhh_id;

    if (this.form.invalid) {
      return Object.values(this.form.controls).forEach((control) => {
        control.markAllAsTouched();
      })
    } else {
      this.liderasgoServ.sendForm(this.form.value).subscribe({
        next: (resp) => {
          if (resp ==false) {
            this.multipleCheck = [];
            this.validateEncueta = true
            Swal.fire({
              html: `Ya habias contestato esta encuesta`,
              icon: 'error',
              title: 'Oh oh',
              confirmButtonText: 'Aceptar'
            }).then((result) => {
              if (result.isConfirmed) {
                this.route.navigate(['cos'])
              }
            })
          }else{
            this.multipleCheck = [];
          this.validateEncueta = true
          Swal.fire({
            html: `Se han enviado tus respuestas`,
            icon: 'success',
            title: 'Muy Bien',
            confirmButtonText: 'Aceptar'
          }).then((result) => {
            if (result.isConfirmed) {
              this.route.navigate(['cos'])
            }
          })
            
          }

          
        },
        error: () => {
          Swal.fire({
            html: `Ha ocurriodo un error`,
            icon: 'error',
            title: 'Opss',
            confirmButtonText: 'Aceptar'
          })
        }
      })
    }
  }
  initForm() {
    return this.formBuilder.group({})
  }
  addCuestions() {
    this.cuestions.forEach(pregunta => {
      if (pregunta.type != "checkbox") {
        this.form.addControl(pregunta.control, new FormControl('', Validators.required))
      }
    });
  }

  numSequence(n: number): Array<number> {
    return Array(n);
  }
  onServicePresed(check, option) {
    if (check) {
      this.multipleCheck.push(option)
    } else {
      this.multipleCheck.splice(this.multipleCheck.indexOf(option), 1)
    }
  }

  private _filter(value: string): string[] {

    const filterValue = value.toString().toLowerCase();
    return this.nameBoss.filter(({ nombre }) => nombre.toLowerCase().includes(filterValue));
  }

  displayFnb(boss: any): string {
    if (!boss) return '';
    return this.nameBoss.find(cc => cc.id === boss).nombre;
  }


  private _filterA(value: string): string[] {

    const filterValue = value.toString().toLowerCase();
    return this.nameAreaboss.filter(({ nombre }) => nombre.toLowerCase().includes(filterValue));
  }

  displayFnA(area: any): string {
    if (!area) return '';
    return this.nameAreaboss.find(cc => cc.id === area).nombre;
  }



}
