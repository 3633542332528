import { Component, OnInit, Input, ViewChild, OnChanges } from '@angular/core';
import { FormBuilder, FormGroup, RequiredValidator, Validators } from '@angular/forms'
import { MatTableDataSource } from '@angular/material/table';
import {MatSort} from '@angular/material/sort';
import { TablesActionsService } from 'src/app/candidate/services/util/tables-actions.service';
import { HttpParams } from '@angular/common/http';
import { FormsSteperService } from 'src/app/candidate/services/rest/forms-steper.service';
import { AuthServicesService } from 'src/app/auth/services/auth.service';

@Component({
  selector: 'candidate-third-step',
  templateUrl: './third-step.component.html',
  styleUrls: ['./third-step.component.sass']
})
export class ThirdStepComponent implements OnInit {
  @Input() form:FormGroup;
  @Input() studies=[];
  @Input() candidateId;

  now = new Date().toISOString().split('T')[0];
  dataSourceStudies= new MatTableDataSource<any>(this.studies);
  displayedColumnsStudies: string[] = ['education_level', 'institution', 'obtained_title', 'study_city', 'finish_date', 'periods_completed', 'actions'];

  constructor(public _actions:TablesActionsService,private _SteperService:FormsSteperService,private authService:AuthServicesService) {
    // this.candidateId = this.authService.systemId('RRHH');
    if ( this.candidateId == null ) {
      this.candidateId = this.authService.systemId('NOMINA');
    }
  }
  @ViewChild('sort') sort: MatSort;
  ngAfterViewInit() {
    this.dataSourceStudies.sort = this.sort;
  }


  ngOnInit(): void {
    this.getStepData()
  }

  getStepData(){
    const entries = {};
    entries['userId'] = this.candidateId;
    entries['data'] = 'education';
    // entries['flag'] = this.authService.systemKey();
    const params = new HttpParams( { fromObject: entries } );
     this._SteperService.getInfo(`candidate/json/data/${this.candidateId}`, params ).subscribe( (res) => {
       if((Object.keys(res).length != 0)){
        this.studies = res
        this.dataSourceStudies.data = res
       }
     } )

  }

}
