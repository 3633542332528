import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CreatePasswordComponent } from './create-password/create-password.component';
import { LoginComponent } from './login/login.component';
import { LogoutComponent } from './logout/logout.component';
import { RequestPasswordTokenComponent } from './request-password-token/request-password-token.component';

const routes: Routes = [
	{ path: '',
		children: [
      { path: '', redirectTo: 'login', pathMatch: 'prefix'},
      {
				path: 'login',
				component: LoginComponent
			},
			{
				path: 'password/request',
				component: RequestPasswordTokenComponent
			},
			{
				path: 'password/create/:token',
				component: CreatePasswordComponent
			},
      {
        path: 'logout',
        component: LogoutComponent
      }
		]},
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AuthRoutingModule { }
