import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SurveysRoutingModule } from './surveys-routing.module';
import { AllSurveysComponent } from './pages/all-surveys/all-surveys.component';
import { BaseCuestionariesComponent } from './pages/base-cuestionaries/base-cuestionaries.component';
import { MaterialModule } from '../core/material/material.module';
import { ReactiveFormsModule, FormsModule} from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { RouterModule } from '@angular/router';


@NgModule({
  declarations: [AllSurveysComponent, BaseCuestionariesComponent],
  imports: [
    CommonModule,
    SurveysRoutingModule,
    MaterialModule,
    ReactiveFormsModule,
    FormsModule,
    HttpClientModule,
    RouterModule
  ]
})
export class SurveysModule { }
