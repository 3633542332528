import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { MatSnackBar, SimpleSnackBar, _SnackBarContainer } from '@angular/material/snack-bar';
import { BasicSnackbarComponent } from 'src/app/shared/basic-snackbar/basic-snackbar.component';
@Injectable()
export class ErrorInterceptor implements HttpInterceptor {

  constructor(private snack: MatSnackBar) {}

  /**
   * Metodo que intercepta los errores de los servicios globalmente
   * @param request
   * @param next
   */
  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(
      catchError((err: HttpErrorResponse) => {        
        if (err.status === 400 || err.status === 401 || err.status === 404 )  {
          setTimeout( e => {
            this.snack.openFromComponent(BasicSnackbarComponent, {
              data: {
                title : 'Error de procedimiento!',
                message : err.error.message ?? err.error.error,
              },
              duration: 5500,
              verticalPosition: 'top', 
              panelClass : ['red-snackbar']
            });
          }, 300);
        }else if( err.status == 500){
          setTimeout( e => {
            this.snack.openFromComponent(BasicSnackbarComponent, {
              data: {
                title : 'Error de servidor!',
                message : err.error.message ?? err.error.error,
              },
              duration: 3500,
              verticalPosition: 'top', 
              panelClass : ['red-snackbar']
            });
          }, 300);
        }else if( err.status == 403){
          setTimeout( e => {
            this.snack.openFromComponent(BasicSnackbarComponent, {
              data: {
                title : 'Alerta!',
                message : err.error.message ?? err.error.error,
              },
              duration: 5500,
              verticalPosition: 'top', 
              panelClass : ['yellow-snackbar']
            });
          }, 300);
        }
        return throwError( err );

      })
    );
  }
}
