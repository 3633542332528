import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AllSurveysComponent } from './pages/all-surveys/all-surveys.component';
import { BaseCuestionariesComponent } from './pages/base-cuestionaries/base-cuestionaries.component';

const routes: Routes = [
  {path: '',component: AllSurveysComponent}
  //{path: 'liderazgo',component: BaseCuestionariesComponent},
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class SurveysRoutingModule { }
