<button style="float: right; margin:-15px" mat-icon-button mat-dialog-close>
    <i class="fi-rr-cross"></i>
  </button>
  
<div *ngIf="draw == 0">
    <h4 matCardTitle>¿Cómo desea firmar?</h4>
    <button style="margin: 10px;" matSuffix mat-stroked-button color="primary" (click)="optionSing(1)">
        Firmar aquí &nbsp;<i class="fi-rr-pencil" ></i></button>
       <button style="margin: 10px;" matSuffix mat-stroked-button color="primary"  (click)="fileInput.click()">
        Adjuntar firma &nbsp;<i class="fi-rr-cloud-upload" ></i></button>
        <input hidden (change)="onFileSelected($event)" #fileInput type="file" id="file" accept="image/png">
</div>
<div *ngIf="draw != 0">
    <h4 matCardTitle>Firmar</h4>
    <!-- <button style="float: right; margin: 10px;" matSuffix mat-button (click)="optionSing(0)">
        <strong><i class="fi-rr-arrow-left" ></i> &nbsp; regresar </strong>
    </button> -->
</div>

<mat-dialog-content *ngIf="draw == 1">
    <p matCardTitle>Trace su firma dentro del siguiente recuadro.</p>
    <div  class="sign">
        <signature-pad  [options]="signaturePadOptions"></signature-pad>
    </div>
    
    
</mat-dialog-content>
<mat-dialog-content *ngIf="draw == 2">
    <p matCardTitle>Solo formato png</p>
    <img [src]="imageSrc" alt="No se encontró la imagen">
    <p matCardSubtitle>{{fileName}}</p>
</mat-dialog-content>
<mat-dialog-actions align="center" >
    <button *ngIf="draw == 1" style="margin: 10px;" matSuffix mat-stroked-button (click)="clear()">Borrar</button>
    <button *ngIf="draw == 2" style="margin: 10px;" matSuffix mat-stroked-button (click)="cancel()">Cancelar</button>
    <button style="margin: 10px;" matSuffix mat-flat-button color="primary" (click)="savePad()">Firmar</button>
</mat-dialog-actions>
