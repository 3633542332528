<div class="head">
    <div fxLayout="row wrap" fxLayout.xs="column"> 
        <div fxLayout="column" fxFlex=80>
        <h1>Autogestión de Candidato</h1>
        <h4 matCardTitle>Recuerda Finalizar tus pruebas</h4>
        <p matCardSubtitle>Una vez finalices tus pruebas se le notificará al lider de selección y <br> te estará contactando en las proximas horas</p>
        </div>
        <div fxLayoutAlign="center center" fxFlex=20>
        <button style="float: right; margin: 10px;" matSuffix mat-flat-button color="primary" (click)="finishTests()" [disabled]="candidate?.testsFinished">Finalizar Pruebas</button>
        </div>
    </div>
    <div class="test_all">
        <mat-accordion multi>

            <mat-expansion-panel *ngFor="let test of candidate?.testsVisible">
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        <b> {{ test.test.name }}</b>
                    </mat-panel-title>
                </mat-expansion-panel-header>
                <!-- Hay pruebas que tienen una description por default -->
                <p *ngIf="test?.test?.description">{{ test.test.description }}</p>
                <!-- Otras pruebas tendran dirferentes descriptiones, por tal motivo seran retornada desde los resources -->
                <p *ngIf="test?.resources?.description">{{ test?.resources?.description }}</p>
                <hr>
                <div fxLayout="column" class="forms">
                    <mat-form-field appearance="fill">
                        <mat-label>Comentarios</mat-label>
                        <textarea matInput rows="5" placeholder="Opcional" [(ngModel)]="test.resources.comments"></textarea>
                    </mat-form-field>
                       
                    <button mat-button color="primary" (click)="fileInput.click()" [disabled]="candidate.testsFinished"> Adjuntar Pruebas &nbsp;<i class="fi-rr-cloud-upload"></i> </button>
                    <input hidden (change)="onFileSelectedTest($event.target.files,test)" #fileInput type="file" id="file">
                    <span *ngIf="test?.resources?.candidateAttachment"> <b>Prueba Adjuntada &nbsp;</b>{{ test?.resources?.candidateAttachmentName }}</span>
                    <div fxLayout="row wrap" fxLayout.xs="column" fxLayoutAlign="center center" style="padding-top: 15px;">
                        
                        <button *ngIf="test?.test?.resources?.url" mat-button color="primary" (click)="openFile(test, true)"> Descargar Pruebas &nbsp; <i class="fi-rr-cloud-download"></i></button>
                        <button *ngIf="test?.resources?.url" mat-button color="primary" (click)="openFile(test, false)"> Descargar Pruebas &nbsp; <i class="fi-rr-cloud-download"></i></button>
                        <button mat-flat-button color="primary" (click)="sendTest(test)" [disabled]="candidate.testsFinished">Enviar Prueba</button>
 
    
                        
                    </div>
                    
                </div>
            
                
            </mat-expansion-panel>
        </mat-accordion>
    </div>
 

</div>
