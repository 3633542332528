import { HttpParams } from '@angular/common/http';
import { Component, OnInit, Input } from '@angular/core';
import { FormBuilder, FormGroup, RequiredValidator, Validators } from '@angular/forms'
import { MatTableDataSource } from '@angular/material/table';
import { AuthServicesService } from 'src/app/auth/services/auth.service';
import { FormsSteperService } from 'src/app/candidate/services/rest/forms-steper.service';



@Component({
  selector: 'candidate-sixth-step',
  templateUrl: './sixth-step.component.html',
  styleUrls: ['./sixth-step.component.sass']
})
export class SixthStepComponent implements OnInit {
  @Input() form:FormGroup;
  @Input() candidateId; 
  now = new Date().toISOString().split('T')[0];
  havePc:boolean = false
  haveRed:boolean = false

  pcChildren=['computer_type', 'ram', 'processor']
  redChildren=['internet_capacity']
  constructor(private authService:AuthServicesService, private _SteperService:FormsSteperService) {
    // this.candidateId = this.authService.systemId('RRHH');
    if ( this.candidateId == null ) {
      this.candidateId = this.authService.systemId('NOMINA');
    }
  }

  ngOnInit(): void {
    const entries = {};
    entries['userId'] = this.candidateId;
    entries['data'] = 'tech_tools';
    // entries['flag'] = this.authService.systemKey();
    const params = new HttpParams( { fromObject: entries } );
     this._SteperService.getInfo(`candidate/json/data/${this.candidateId}`, params ).subscribe( (res) => {
     this.form.patchValue(res)
     } )
  }
  checkDependence(event, conditional, element: string, children:string[]){
    if(event == conditional){
      this[element]= true
    }
    else{
      this[element]= false
      children.forEach(element => {
        this.form.get(element).setValue('')
      });
    }
  }


}
