import { Component, OnInit, Input , ViewChild, OnChanges} from '@angular/core';
import { FormBuilder, FormGroup, RequiredValidator, Validators } from '@angular/forms'
import { MatTableDataSource } from '@angular/material/table';
import {MatSort} from '@angular/material/sort';
import { TablesActionsService } from 'src/app/candidate/services/util/tables-actions.service';
import { AuthServicesService } from 'src/app/auth/services/auth.service';
import { FormsSteperService } from 'src/app/candidate/services/rest/forms-steper.service';
import { HttpParams } from '@angular/common/http';

@Component({
  selector: 'candidate-fourth-step',
  templateUrl: './fourth-step.component.html',
  styleUrls: ['./fourth-step.component.sass']
})
export class FourthStepComponent implements OnInit {
  @Input() form:FormGroup;
  @Input() familyGroup = [];
  @Input() candidateId;

  now = new Date().toISOString().split('T')[0];
  dataSourceFamilyGoup= new MatTableDataSource<any>(this.familyGroup);
  displayedColumnsFamily: string[] = ['first_name', 'last_name', 'id_document_number', 'education_level', 'relationship', 'profession', 'actions'];

  constructor(public _actions:TablesActionsService,private authService:AuthServicesService,private _SteperService:FormsSteperService) {
    // this.candidateId = this.authService.systemId('RRHH');
    if ( this.candidateId == null ) {
      this.candidateId = this.authService.systemId('NOMINA');
    }
  }
  @ViewChild('sort') sort: MatSort;
  ngAfterViewInit() {
    this.dataSourceFamilyGoup.sort = this.sort;
  }

  ngOnInit(): void {
    this.getStepData()
  }


  getStepData(){
    const entries = {};
    entries['userId'] = this.candidateId;
    entries['data'] = 'group_family';
    // entries['flag'] = this.authService.systemKey();
    const params = new HttpParams( { fromObject: entries } );
     this._SteperService.getInfo(`candidate/json/data/${this.candidateId}`, params ).subscribe( (res) => {
      if((Object.keys(res).length != 0)){
        this.familyGroup = res
        this.dataSourceFamilyGoup.data = res
      }
     } )

  }


}
