import { Injectable } from '@angular/core';

import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';


@Injectable({
  providedIn: 'root'
})
export class LiderazgoService {
  URLAPI:string = environment.ENCUESTAS
  constructor(private http:HttpClient ) { }

  getPreguntas(){}

  getBossName():Observable<any>{
  
    return this.http.get(`${this.URLAPI}getJefes`);
  }
  getAreaBoss():Observable<any>{
    return this.http.get(`${this.URLAPI}getAreas`);
  }
  validarUser(rrhh):Observable<any>{
    return this.http.get(`${this.URLAPI}validar-preguntas-encuenta-liderazgo?rrhh_id=${rrhh}`);
  }
  sendForm(obj){
    return this.http.post(`${this.URLAPI}guardar-preguntas-encuenta-liderazgo`,obj);
  }

  sendFormWom(obj){
    return this.http.post(`${this.URLAPI}guardar-preguntas-encuesta-wom`,obj);
  }
  getRespWom(obj){
    return this.http.post(`${this.URLAPI}get-respuestas-encuesta-wom`,obj);
  }


}
