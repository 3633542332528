<div class="head">
  <div class="resignation-survey">
    <div class="survey-title" fxLayout="row wrap" fxLayout.xs="column">
      <div *ngIf="formInfo" fxLayout="column" fxFlex="80">
        <h1 style="font-size: 38px !important;">{{formInfo.name_form}}</h1>
        <h4 style="font-size: 16px !important;">
          En este espacio podrás contarnos sobre el motivo de tu retiro,<br>
          no olvides, ¨El respeto y empatía son clave¨.
        </h4>
      </div>
  </div>
  <ng-container *ngIf="formInfo">
    <h4 style="font-weight: bold; font-size: 42px">Encuesta de retiro</h4>
    <mat-divider style="background: #a9a9a9"></mat-divider>
    <app-form [formInfo]='formInfo.section[0].fields' (submitForm)="getFormAnswered($event)"></app-form>
  </ng-container>
  </div>
</div>


