import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthServicesService } from 'src/app/auth/services/auth.service';

@Injectable({
  providedIn: 'root'
})
export class TestService {

  constructor(private authServie:AuthServicesService,private http:HttpClient) { }
  private server = this.authServie.systemIdentify('RRHH');

  finishTest(candidateId, requestId){
    return this.http.get(`${this.server}candidate/finished/test/${candidateId}/${requestId}`);
  }

  sendTest(candidateTestId, params){

    const formData = new FormData();
    formData.append('candidateAttachment', params.candidateAttachment);
    formData.append('comments', params.comments);
    formData.append('candidateAttachmentName', params.candidateAttachmentName);
    return this.http.post(`${this.server}candidate/test/${candidateTestId}`, formData);
  }
  downdloadFile(fileId){
    return this.http.get(`${this.server}candidate/download/test/${fileId}`, {responseType:'blob'});
  }

}
